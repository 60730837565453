import { z } from 'zod';
import { EHandlebars } from '../headers_footers/shared';
import { ETemplateStyles } from '../types';
import { Obituary, ObituaryFilingTypeNames } from '../types/obituary';

import { Layout } from '../types/layout';
import { wrapError, wrapSuccess } from '../types/responses';
import { PublicationFontResource, getFontFaceDefinition } from './fonts';
import { getInlineCSSStylesFromCrop } from './images';
import { OrderFilingType } from '../types/filingType';
import { ClassifiedFilingTypeNames } from '../types/classified';

// TODO(goodpaul): Move this to the db
const ONE_COL_DEATH_NOTICE: Layout = {
  id: 'one-col-death-notice',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  {{#if title }}
  <h1 style="{{{titleStyles}}}">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}
  {{#if logo }}<img src="{{{logo}}}" display: inline-block style="height: 0.5in; width: auto; " />
  {{/if}}</section>
  </div>`,
  columns: 1,
  photos: 0,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716673316156/template_a_normal.svg',
  photoAlignment: 'none',
  aspectRatio: 'none',
  photoWidth: 'none'
};

const TWO_COL_DEATH_NOTICE: Layout = {
  id: 'two-col-death-notice',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  {{#if title }}
  <h1 style="{{{titleStyles}}}">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}
  {{#if logo }}<img src="{{{logo}}}" style="height: 0.5in; width: auto display: inline-block; " />
  {{/if}}</section>
  </div>`,
  columns: 2,
  photos: 0,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716673363185/template_g_normal.svg',
  photoAlignment: 'none',
  aspectRatio: 'none',
  photoWidth: 'none'
};

export const DEFAULT_DEATH_NOTICE_LAYOUTS = [
  ONE_COL_DEATH_NOTICE,
  TWO_COL_DEATH_NOTICE
];

const ONE_COL_ONE_PHOTO_LAYOUT: Layout = {
  id: 'one-col-3-4-image-1-photo',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  {{#if headerImage }}
  <div>
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 3 / 4;" />
  </div>
  {{/if}}
  {{#if title }}
  <h1 style="{{{titleStyles}}}">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}
    {{#if logo }}
        <img src="{{{logo}}}" style="height: 1in; width: auto; " />
    {{/if}}</section>
  </div>`,
  columns: 1,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716478716163/template_sv_normal.svg',
  photoAlignment: 'center',
  aspectRatio: '3 / 4',
  photoWidth: '100%'
};

export const TEMPLATE_S_LAYOUT: Layout = {
  id: 'three-col-4-3-image-1-photo',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  {{#if headerImage }}
  <div>
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 4 / 3;" />
  </div>
  {{/if}}
  {{#if title }}
  <h1 style="{{{titleStyles}}}">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}{{#if logo }}<img src="{{{logo}}}" style="height: 1in; width: auto; " />{{/if}}</section>
  </div>`,
  columns: 3,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716474500733/template_s_normal.svg',
  photoAlignment: 'center',
  aspectRatio: '4 / 3',
  photoWidth: '100%'
};

const TEMPLATE_SV_LAYOUT: Layout = {
  id: 'three-col-3-4-image-1-photo',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  {{#if headerImage }}
  <div>
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 3 / 4;" />
  </div>
  {{/if}}
  {{#if title }}
  <h1 style="{{{titleStyles}}}">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">
    {{{content}}}
    {{#if logo }}<img src="{{{logo}}}" style="height: 1in; width: auto; " />
    {{/if}}</section>
  </div>`,
  columns: 3,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716478716163/template_sv_normal.svg',
  photoAlignment: 'center',
  aspectRatio: '3 / 4',
  photoWidth: '100%'
};

export const TEMPLATE_T_LAYOUT: Layout = {
  id: 'three-col-4-3-image-2-photo',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  {{#if headerImage }}
  <div style="display: flex;">
    <div style="flex:1;"><img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 4 / 3;" /></div>
    <div style="flex:1;"><img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; aspect-ratio: 4 / 3;" /></div>
  </div>
  {{/if}}
  {{#if title }}
  <h1 style="{{{titleStyles}}}">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}
    {{#if logo }}<img src="{{{logo}}}" style="height: 1in; width: auto; " />
    {{/if}}</section>
  </div>`,
  columns: 3,
  photos: 2,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716478847918/template_t_normal.svg',
  photoAlignment: 'center',
  aspectRatio: '4 / 3',
  photoWidth: '100%'
};

const TEMPLATE_TV_LAYOUT: Layout = {
  id: 'three-col-3-4-image-2-photo',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  {{#if headerImage }}
  <div style="display: flex;">
    <div style="flex:1;"><img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 3 / 4;" /></div>
    <div style="flex:1;"><img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; aspect-ratio: 3 / 4;" /></div>
  </div>
  {{/if}}
  {{#if title }}
  <h1 style="{{{titleStyles}}}">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}
    {{#if logo }}<img src="{{{logo}}}" style="height: 1in; width: auto; " />
    {{/if}}</section>
  </div>`,
  columns: 3,
  photos: 2,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716478958991/template_tv_normal.svg',
  photoAlignment: 'center',
  aspectRatio: '3 / 4',
  photoWidth: '100%'
};

const TEMPLATE_U_LAYOUT: Layout = {
  id: 'three-col-4-3-image-1-photo-variant-2-big-rhs',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 60%; aspect-ratio: 4 / 3; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}
      {{#if logo }}<img src="{{{logo}}}" style="height: 1in; width: auto; " />
      {{/if}}</span>
  </div>
</div>`,
  columns: 3,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716479060868/template_u_normal.svg',
  photoAlignment: 'right',
  aspectRatio: '4 / 3',
  photoWidth: '50%'
};

const TEMPLATE_UV_LAYOUT: Layout = {
  id: 'three-col-3-4-image-1-photo-variant-2-big-rhs',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 60%; aspect-ratio: 3 / 4; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
      {{{content}}}
        {{#if logo }}
          <img src="{{{logo}}}" style="height: 1in; width: auto; " />
        {{/if}}
    </span>
  </div>
</div>`,
  columns: 3,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716479145916/template_uv_normal.svg',
  photoAlignment: 'right',
  aspectRatio: '3 / 4',
  photoWidth: '50%'
};

const TEMPLATE_V_LAYOUT: Layout = {
  id: 'three-col-4-3-image-1-photo-variant-2-small-rhs',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 40%; aspect-ratio: 4 / 3; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}
      {{#if logo }}<img src="{{{logo}}}" style="height: 1in; width: auto; " />
      {{/if}}</span>
  </div>
</div>`,
  columns: 3,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716479425509/template_v_normal.svg',
  photoAlignment: 'right',
  aspectRatio: '4 / 3',
  photoWidth: '30%'
};

const TEMPLATE_VV_LAYOUT: Layout = {
  id: 'three-col-3-4-image-1-photo-variant-2-small-rhs',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 40%; aspect-ratio: 3 / 4; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}
        {{#if logo }}<img src="{{{logo}}}" style="height: 1in; width: auto; " />
        {{/if}}</span>
  </div>
</div>`,
  columns: 3,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716555885314/template_vv_normal__1_.svg',
  photoAlignment: 'right',
  aspectRatio: '3 / 4',
  photoWidth: '30%'
};

// FOUR COLS
const TEMPLATE_VC4NP_LAYOUT: Layout = {
  id: 'four-col-no-photo',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  {{#if title }}
  <h1 style="{{{titleStyles}}}">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}
      {{#if logo }}<img src="{{{logo}}}" style="height: 1in; width: auto; " />
      {{/if}}</section>
  </div>`,
  columns: 4,
  photos: 0,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716559894275/template_vc5np_normal.svg',
  photoAlignment: 'none',
  aspectRatio: 'none',
  photoWidth: 'none'
};

const TEMPLATE_VC41PV_LAYOUT: Layout = {
  id: 'four-col-3-4-image-1-photo-skinny-rhs',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 30%; aspect-ratio: 3 / 4; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}
      {{#if logo }}<img src="{{{logo}}}" style="height: 1in; width: auto; " />
      {{/if}}</span>
  </div>
</div>`,
  columns: 4,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716559512112/template_vc41pv_normal.svg',
  photoAlignment: 'right',
  aspectRatio: '3 / 4',
  photoWidth: '30%'
};

const TEMPLATE_VC41PH_LAYOUT: Layout = {
  id: 'four-col-4-3-image-1-photo-skinny-rhs',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 30%; aspect-ratio: 4 / 3; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}
      {{#if logo }}
          <img src="{{{logo}}}" style="height: 1in; width: auto; " />
      {{/if}}</span>
  </div>
</div>`,
  columns: 4,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716559576942/template_vc41ph_normal.svg',
  photoAlignment: 'right',
  aspectRatio: '4 / 3',
  photoWidth: '30%'
};

const TEMPLATE_VC42PV_LAYOUT: Layout = {
  id: 'four-col-3-4-image-2-photo',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 25%; aspect-ratio: 3 / 4; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    {{#if secondHeaderImage }}
    <img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; width: 25%; aspect-ratio: 3 / 4; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}
    {{#if logo }}
        <img src="{{{logo}}}" style="height: 1in; width: auto; " />
      {{/if}}</span>
  </div>
</div>`,
  columns: 4,
  photos: 2,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716559710646/template_vc42pv_normal.svg',
  photoAlignment: 'right',
  aspectRatio: '3 / 4',
  photoWidth: '25%'
};

const TEMPLATE_VC42PH_LAYOUT: Layout = {
  id: 'four-col-4-3-image-2-photo',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 25%; aspect-ratio: 4 / 3; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    {{#if secondHeaderImage }}
    <img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; width: 25%; aspect-ratio: 4 / 3; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}
      {{#if logo }}
          <img src="{{{logo}}}" style="height: 1in; width: auto; " />
      {{/if}}</span>
  </div>
</div>`,
  columns: 4,
  photos: 2,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716560593311/template_vc42ph_normal.svg',
  photoAlignment: 'right',
  aspectRatio: '4 / 3',
  photoWidth: '25%'
};

// FIVE COLS
const TEMPLATE_VC51PV_LAYOUT: Layout = {
  id: 'five-col-3-4-image-1-photo-skinny-rhs',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 20%; aspect-ratio: 3 / 4; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}
      {{#if logo }}<img src="{{{logo}}}" style="height: 1in; width: auto; " />
      {{/if}}</span>
  </div>
</div>`,
  columns: 5,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716559983071/template_vc51pv_normal.svg',
  photoAlignment: 'right',
  aspectRatio: '3 / 4',
  photoWidth: '20%'
};

const TEMPLATE_VC51PH_LAYOUT: Layout = {
  id: 'five-col-4-3-image-1-photo-skinny-rhs',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 20%; aspect-ratio: 4 / 3; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}
      {{#if logo }}<img src="{{{logo}}}" style="height: 1in; width: auto; " />
      {{/if}}</span>
  </div>
</div>`,
  columns: 5,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716560055432/template_vc51ph_normal.svg',
  photoAlignment: 'right',
  aspectRatio: '4 / 3',
  photoWidth: '20%'
};

const TEMPLATE_VC52PV_LAYOUT: Layout = {
  id: 'five-col-3-4-image-2-photo',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 20%; aspect-ratio: 3 / 4; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    {{#if secondHeaderImage }}
    <img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; width: 20%; aspect-ratio: 3 / 4; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}
      {{#if logo }}<img src="{{{logo}}}" style="height: 1in; width: auto; " />
      {{/if}}</span>
  </div>
</div>`,
  columns: 5,
  photos: 2,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716560101529/template_vc52pv_normal.svg',
  photoAlignment: 'right',
  aspectRatio: '3 / 4',
  photoWidth: '20%'
};

const TEMPLATE_VC52PH_LAYOUT: Layout = {
  id: 'five-col-4-3-image-2-photo',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 25%; aspect-ratio: 4 / 3; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    {{#if secondHeaderImage }}
    <img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; width: 25%; aspect-ratio: 4 / 3; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}
      {{#if logo }}<img src="{{{logo}}}" style="height: 1in; width: auto; " />
      {{/if}}</span>
  </div>
</div>`,
  columns: 5,
  photos: 2,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716560152267/template_vc52ph_normal.svg',
  photoAlignment: 'right',
  aspectRatio: '4 / 3',
  photoWidth: '25%'
};

const TEMPLATE_VC5NP_LAYOUT: Layout = {
  id: 'five-col-no-photo',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  {{#if title }}
  <h1 style="{{{titleStyles}}}">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}
    {{#if logo }}<img src="{{{logo}}}" style="height: 1in; width: auto; " />
    {{/if}}</section>
  </div>`,
  columns: 5,
  photos: 0,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716559894275/template_vc5np_normal.svg',
  photoAlignment: 'center',
  aspectRatio: 'none',
  photoWidth: 'none'
};

const TEMPLATE_2COL1PH_LAYOUT: Layout = {
  id: 'two-col-one-photo',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  {{#if headerImage }}
  <div>
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 4 / 3;" />
  </div>
  {{/if}}
  {{#if title }}
  <h1 style="{{{titleStyles}}}">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}
    {{#if logo }}<img src="{{{logo}}}" style="height: 1in; width: auto; " />
    {{/if}}</section>
  </div>`,
  columns: 2,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F367f.d4956-Template-S-Normal.svg',
  photoAlignment: 'center',
  aspectRatio: '4 / 3',
  photoWidth: 'none'
};

const TEMPLATE_2COL1PHV2_LAYOUT: Layout = {
  id: 'two-col-one-photo-3-4-image',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  {{#if headerImage }}
  <div>
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 3 / 4; width: 50%; float: right; " />
  </div>
  {{/if}}
  {{#if title }}
  <h1 style="{{{titleStyles}}}">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal; height: 100%; ">{{{content}}}
    {{#if logo }}<img src="{{{logo}}}" style="height: 1in; width: auto; " />
    {{/if}}</section>
  </div>`,
  columns: 2,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F7bd6.404dc-Template-H-Normal.svg',
  photoAlignment: 'right',
  aspectRatio: '3 / 4',
  photoWidth: '50%'
};

const TEMPLATE_2COL2PHV1_LAYOUT: Layout = {
  id: 'two-col-2-photo-3-4-image',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  {{#if headerImage }}
  <div style="display: flex;">
    <div style="flex:1;"><img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 3 / 4;" /></div>
    <div style="flex:1;"><img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; aspect-ratio: 3 / 4;" /></div>
  </div>
  {{/if}}
  {{#if title }}
  <h1 style="{{{titleStyles}}}">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}
    {{#if logo }}<img src="{{{logo}}}" style="height: 1in; width: auto; " />
    {{/if}}</section>
  </div>`,
  columns: 2,
  photos: 2,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2Fef82.19618-Template-J-Normal.svg',
  photoAlignment: 'center',
  aspectRatio: '3 / 4',
  photoWidth: '100%'
};

const TEMPLATE_2COL2PHV2_LAYOUT: Layout = {
  id: 'two-col-2-photo-4-3-image',
  handlebarTemplate: `<div style="width:{{{ adWidth }}}px; box-sizing:border-box; border:{{{ borderWidth }}}px solid black">
  {{#if headerImage }}
  <div style="display: flex;">
    <div style="flex:1;"><img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 4 / 3;" /></div>
    <div style="flex:1;"><img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; aspect-ratio: 4 / 3;" /></div>
  </div>
  {{/if}}
  {{#if title }}
  <h1 style="{{{titleStyles}}}">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}
    {{#if logo }}<img src="{{{logo}}}" style="height: 1in; width: auto; " />
    {{/if}}</section>
  </div>`,
  columns: 2,
  photos: 2,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F4eb6.02e6b-Template-K-Normal.svg',
  photoAlignment: 'center',
  aspectRatio: '4 / 3',
  photoWidth: '100%'
};

export const DEFAULT_CLASSIFIED_LAYOUTS = [
  TEMPLATE_2COL1PHV2_LAYOUT,
  TEMPLATE_2COL1PH_LAYOUT,
  TEMPLATE_2COL2PHV1_LAYOUT,
  TEMPLATE_2COL2PHV2_LAYOUT,
  TEMPLATE_S_LAYOUT,
  TEMPLATE_T_LAYOUT
];

export const DEFAULT_OBITUARY_LAYOUTS = [
  TEMPLATE_SV_LAYOUT,
  TEMPLATE_TV_LAYOUT,
  TEMPLATE_U_LAYOUT,
  TEMPLATE_UV_LAYOUT,
  TEMPLATE_V_LAYOUT,
  TEMPLATE_VV_LAYOUT,
  TEMPLATE_VC4NP_LAYOUT,
  TEMPLATE_VC41PV_LAYOUT,
  TEMPLATE_VC41PH_LAYOUT,
  TEMPLATE_VC42PV_LAYOUT,
  TEMPLATE_VC42PH_LAYOUT,
  TEMPLATE_VC51PV_LAYOUT,
  TEMPLATE_VC51PH_LAYOUT,
  TEMPLATE_VC52PV_LAYOUT,
  TEMPLATE_VC52PH_LAYOUT,
  TEMPLATE_VC5NP_LAYOUT
];

export const SELECTABLE_LAYOUTS: Array<Layout> = [
  ...DEFAULT_DEATH_NOTICE_LAYOUTS,
  ONE_COL_ONE_PHOTO_LAYOUT,
  ...DEFAULT_CLASSIFIED_LAYOUTS,
  ...DEFAULT_OBITUARY_LAYOUTS
];

const adobeInDesignJustificationToCSS = (justification: string | undefined) => {
  switch (justification) {
    case 'LeftAlign':
      return 'text-align:left';
    case 'CenterAlign':
      return 'text-align:center';
    case 'RightAlign':
      return 'text-align:right';
    case 'RightJustified':
      return ['text-align:justify', 'text-align-last:right'].join(';');
    case 'LeftJustified':
      return ['text-align:justify', 'text-align-last:left'].join(';');
    case 'CenterJustified':
      return ['text-align:justify', 'text-align-last:center'].join(';');
    case 'FullyJustified':
      return 'text-align:justify';
    default:
      return 'text-align:justify';
  }
};

const inDesignPtToPx = (pt: number) => {
  return (pt * DEFAULT_DPI_VALUES.PUPPETEER) / DEFAULT_DPI_VALUES.INDESIGN;
};

/**
 * Gets title styles based on the titleParagraphStyle replacing any missing values with content from the overall template
 */
const getTitleStyles = (templateStyle: ETemplateStyles) => {
  const { titleParagraphStyle } = templateStyle;
  return [
    `min-height:${titleParagraphStyle?.fontSize ?? templateStyle.fontSize}pt`,
    `margin-left:${titleParagraphStyle?.marginLeft ?? 0}pt`,
    `margin-right:${titleParagraphStyle?.marginRight ?? 0}pt`,
    `padding:0pt`,
    adobeInDesignJustificationToCSS(titleParagraphStyle?.justification),
    `font-family:${titleParagraphStyle?.font ?? templateStyle.font}`,
    `font-size:${titleParagraphStyle?.fontSize ?? templateStyle.fontSize}pt`,
    `line-height:${
      titleParagraphStyle?.leading ?? templateStyle.leading
        ? `${templateStyle.leading}pt`
        : '120%'
    }`,
    'overflow-wrap:break-word',
    titleParagraphStyle?.decoration
      ? `text-decoration:${titleParagraphStyle.decoration}`
      : '',
    titleParagraphStyle?.underline ? 'text-decoration:underline' : '',
    'word-spacing:0.1em',
    'letter-spacing:0',
    'font-smooth:never',
    'word-break:break-word',
    `font-weight:${titleParagraphStyle?.fontWeight ?? 'bold'}`
  ]
    .filter(Boolean)
    .join(';');
};

/**
 * Applies any non-inherited styles to each paragraph in the ad content. inherited styles are applied to the parent in getTextStyles
 * @param {string} adContent - The ad content to apply styles to
 * @param {DOMParser} DOMparser - The DOMParser to use to parse
 * @param {ETemplateStyles} - The styles to apply to the paragraphs. The supported styles are padding, firstLineIndent, and hyphenation
 * @returns The ad content with the styles applied
 */
const applyStylesToParagraphs = async (
  adContent: string | undefined,
  DOMparser: typeof DOMParser,
  { padding, firstLineIndent, hyphenation }: ETemplateStyles
) => {
  if (!adContent) {
    return adContent;
  }
  const doc = new DOMparser().parseFromString(adContent, 'text/html');
  const paragraphs = doc.querySelectorAll('p');
  paragraphs.forEach((p: NodeListOf<HTMLElement>[any], index) => {
    // eslint-disable-next-line no-param-reassign
    p.style.padding = `
      ${index === 0 ? 0 : inDesignPtToPx(padding?.top ?? 0)}px 
      ${inDesignPtToPx(padding?.right ?? 0)}px
      ${inDesignPtToPx(padding?.bottom ?? 0)}px
      ${inDesignPtToPx(padding?.left ?? 0)}px`;
    // eslint-disable-next-line no-param-reassign
    p.style.margin = '0px';
    // eslint-disable-next-line no-param-reassign
    p.style.textIndent = `${firstLineIndent ?? 0}px`;
    if (hyphenation) {
      // eslint-disable-next-line no-param-reassign
      p.style.hyphens = 'auto';
    }
  });

  return doc.body.innerHTML;
};

const getTextStyles = ({
  fontSize,
  font,
  leading,
  textInset
}: ETemplateStyles) => {
  let marginValue = '0';
  if (textInset) {
    const textInsetPx = inDesignPtToPx(textInset);
    marginValue = `${textInsetPx}`;
  }
  return [
    `min-height:${fontSize}pt`,
    `margin:${marginValue}px`,
    `padding:0pt`,
    'text-align:justify',
    `font-family:${font}`,
    `font-size:${fontSize}pt`,
    `line-height:${leading ? `${leading}pt` : '120%'}`,
    'overflow-wrap:break-word',
    'word-spacing:0.1em',
    'letter-spacing:0',
    'font-smooth:never',
    'word-break:break-word'
  ].join(';');
};

const OrderContentHandlebarDataSchema = z.object({
  headerImage: z.string().optional().nullable(),
  headerImageStyles: z.string().optional().nullable(),

  secondHeaderImage: z.string().optional().nullable(),
  secondImageStyles: z.string().optional().nullable(),

  titleStyles: z.string(),
  textStyles: z.string(),
  content: z.string().optional(),
  adWidth: z.number(),
  borderWidth: z.number().optional(),
  title: z.string().optional(),
  logo: z.string().optional().nullable()
});
type OrderContentHandlebarData = z.infer<
  typeof OrderContentHandlebarDataSchema
>;

/**
 * Dynamically determine the template that we should use for a particular obituary order.
 * We re-determine the handlebar value instead of using the value cached on the object because
 * it may have changed since the object was created.
 */
const getHandlebarStringForLayout = (layout: Layout) => {
  const relevantTemplate = findLayoutById(layout.id);
  if (!relevantTemplate) {
    return wrapError(new Error('No template found for layout'));
  }
  return wrapSuccess(relevantTemplate.handlebarTemplate);
};

export const DEFAULT_DPI_VALUES = {
  INDESIGN: 72,
  PUPPETEER: 96
};
/**
 * Determine the width of an ad based on the layout and template styles. Consider the following layout:
 * | column |gutter| column |gutter| column |gutter| column |gutter| column |gutter| column |
 * Our templates don't have the column width directly, so we need to calculate it. In order to figure out
 * the width of a single column, we need to subtract the total gutter width from the page width, then divide by the
 * number of columns to get the width of a single column.
 * Once we have the width of a single column, we can multiply it by the number of columns to get the total width of the ad
 * *without gutters*. The final step is to add the total gutter width to the column width to get the final size.
 * Before exporting, we take the final step of scaling by the default DPI of puppeteer (96) over the default DPI of
 * Indesign (72).
 */
export const getAdWidth = (layout: Layout, templateStyles: ETemplateStyles) => {
  const { columns } = layout;
  if (columns < 1) {
    return wrapError(new Error('Invalid number of columns'));
  }
  if (
    !templateStyles.pageWidth ||
    !templateStyles.columnGutter ||
    !templateStyles.columnCount
  ) {
    return wrapError(new Error('Invalid template styles'));
  }

  const totalGutterWidth =
    templateStyles.columnGutter * (templateStyles.columnCount - 1);

  const pageWidthWithoutGutter = templateStyles.pageWidth - totalGutterWidth;

  const columnWidth = pageWidthWithoutGutter / templateStyles.columnCount;

  const adWidthFromColumns = columnWidth * layout.columns;
  const adWidthFromGutter = templateStyles.columnGutter * (columns - 1);

  const adWidth = adWidthFromColumns + adWidthFromGutter;

  const scaledAdWidth =
    adWidth * (DEFAULT_DPI_VALUES.PUPPETEER / DEFAULT_DPI_VALUES.INDESIGN);
  return wrapSuccess(scaledAdWidth);
};

// TODO: Figure out what we want to do around placeholders in previews
const PLACEHOLDER_IMG = 'https://placehold.co/600x400?text=Preview';
export const layoutOrderContent = async (
  layout: Layout,
  adContent: Partial<Obituary>,
  templateStyles: ETemplateStyles,
  parser: typeof DOMParser
) => {
  const {
    response: handlebarString,
    error: handlebarError
  } = getHandlebarStringForLayout(layout);
  if (handlebarError) {
    return wrapError(handlebarError);
  }
  const handlebarTemplate = EHandlebars.compile<OrderContentHandlebarData>(
    handlebarString
  );
  const { response: adWidth, error: adWidthError } = getAdWidth(
    layout,
    templateStyles
  );
  if (adWidthError) {
    return wrapError(adWidthError);
  }
  const firstOrderImage = adContent.orderImages?.[0];
  const secondOrderImage = adContent.orderImages?.[1];
  const htmlContent = handlebarTemplate({
    title: adContent.title,
    content: await applyStylesToParagraphs(
      adContent.content,
      parser,
      templateStyles
    ),
    secondHeaderImage:
      secondOrderImage?.imageUrl ||
      adContent.secondHeaderImage ||
      PLACEHOLDER_IMG,
    headerImage:
      firstOrderImage?.imageUrl || adContent.headerImage || PLACEHOLDER_IMG,
    adWidth,
    borderWidth: templateStyles.borderWidth || 0,
    titleStyles: getTitleStyles(templateStyles),
    textStyles: getTextStyles(templateStyles),
    headerImageStyles: getInlineCSSStylesFromCrop(
      firstOrderImage?.crop || adContent.headerImageCrop,
      templateStyles.photoStyleProperties
    ),
    secondImageStyles: getInlineCSSStylesFromCrop(
      secondOrderImage?.crop || adContent.secondImageCrop,
      templateStyles.photoStyleProperties
    ),
    logo: adContent.funeralHomeLogo ?? null
  });
  return wrapSuccess({ htmlContent, adWidth });
};

export const createHtmlDocument = (
  htmlContent: string,
  fontResources: PublicationFontResource[]
): string => {
  const document = `
      <!DOCTYPE html>
      <html style="margin: 0; padding: 0;">
          <head>
              <style>
                  *{
                  box-sizing: border-box;
                  }
                  ${fontResources.map(getFontFaceDefinition).join('')}
                  html {
                      -webkit-print-color-adjust: exact;
                      font-size: 0;
                  }
              </style>
          </head>
          <!-- thin dashed border is added as an invisible border to workaround a bug in chrome where bottom border does not show--!>
          <body style="margin: 0; padding: 0; border-bottom: 2px dashed white;">
              <div id="listing" style="display: inline-block">
                  ${htmlContent}
              </div>
          </body>
      </html>`;

  return document;
};

export function getDefaultLayoutsByFilingType(
  filingType: OrderFilingType
): Layout[] {
  if (
    filingType.label === ObituaryFilingTypeNames.DeathNotice ||
    filingType.label === ClassifiedFilingTypeNames.CommunityEngagementLetters
  ) {
    return DEFAULT_DEATH_NOTICE_LAYOUTS;
  }

  if (filingType.label === ObituaryFilingTypeNames.Obituary) {
    return [
      TWO_COL_DEATH_NOTICE,
      ...DEFAULT_CLASSIFIED_LAYOUTS,
      ...DEFAULT_OBITUARY_LAYOUTS
    ];
  }

  return [...DEFAULT_DEATH_NOTICE_LAYOUTS, ...DEFAULT_CLASSIFIED_LAYOUTS];
}

export function findLayoutById(layoutId: string): Layout | undefined {
  return SELECTABLE_LAYOUTS.find(layout => layout.id === layoutId);
}
