import { useEffect, useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import MultiDateSelector from 'routes/ads/place/components/MultiDateSelector';
import { Alert } from 'lib/components/Alert';
import { InputAccessories } from 'lib/components/InputAccessories';
import { EOrganization, ESnapshotExists } from 'lib/types';
import moment from 'moment';
import { getDeadlineText } from 'routes/placeScroll/ConfirmScheduleForm';
import { NewspaperOrder } from 'lib/types/newspaperOrder';
import { PublishingSettingModel } from 'lib/model/objects/publishingSettingModel';
import { Product } from 'lib/enums';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { getDateForDateStringInTimezone } from 'lib/utils/dates';
import getNextPublishingDate from './getNextPublishingDate';
import { NewspaperOrderWithPublishingSettings } from './validation';

type SelectScheduleProps = {
  newspaperOrder: NewspaperOrderWithPublishingSettings['newspaperOrder'];
  newspaper: ESnapshotExists<EOrganization>;
  publishingSetting: PublishingSettingModel;
  onNewspaperOrderChange: (newspaperOrder: Partial<NewspaperOrder>) => void;
  product: Product;
  disableEdits?: boolean;
};

function SelectSchedule({
  newspaperOrder,
  newspaper,
  publishingSetting,
  onNewspaperOrderChange,
  product,
  disableEdits
}: SelectScheduleProps) {
  const { iana_timezone: newspaperTimezone } = newspaper.data();

  const { deadlines = [], deadlineOverrides = {} } =
    publishingSetting.modelData ?? {};

  const [selectedDates, setSelectedDates] = useState<string[]>(
    newspaperOrder.publishingDates
  );

  // This data is used as a temporary prop to leverage our deadline helper functions for notices
  const deadlineConfig = selectedDates.length
    ? getDeadlineText({
        publicationDate: getDateForDateStringInTimezone({
          dayString: selectedDates[0],
          timezone: newspaperTimezone
        }),
        deadlines,
        deadlineOverrides,
        newspaperTimezone,
        newspaper,
        isPublisher: false,
        placement: {}
      })
    : null;

  // update orderData when selectedDates change
  useEffect(() => {
    onNewspaperOrderChange({
      ...newspaperOrder,
      publishingDates: selectedDates
    });
  }, [selectedDates]);

  const handleAddMoreDatesClick = () => {
    const nextPublishingDate = getNextPublishingDate(
      newspaper,
      publishingSetting,
      selectedDates.length
        ? moment(selectedDates[selectedDates.length - 1]).toDate()
        : new Date()
    );
    const updatedSelectedDates = [...selectedDates, nextPublishingDate].sort();
    setSelectedDates(updatedSelectedDates);
  };

  const productTypeName = PRODUCT_TO_NAME[product].singular.toLowerCase();

  return (
    <>
      {deadlineConfig && (
        <div className="pb-4">
          <Alert
            id="newspaper-schedule-deadline"
            title={deadlineConfig.text}
            status={deadlineConfig.status}
            icon={<ExclamationCircleIcon className="h-5 w-5" />}
          />
        </div>
      )}
      <div>
        <InputAccessories
          id="schedule-ad"
          labelText={`When should your ${productTypeName} be published?`}
        >
          <div className={'grid grid-cols-2 gap-x-6 gap-y-3'}>
            <MultiDateSelector
              selectedDates={selectedDates}
              onSelectedDatesChange={setSelectedDates}
              newspaper={newspaper}
              publishingSetting={publishingSetting}
              onAddMoreDatesClick={handleAddMoreDatesClick}
              disabled={disableEdits}
            />
          </div>
        </InputAccessories>
      </div>
    </>
  );
}

export default SelectSchedule;
