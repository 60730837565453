import React from 'react';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';

export type LabeledSwitchProps = {
  label: string;
  description?: `${Capitalize<string>}.`;
  value: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
};

function LabeledSwitch({
  label,
  description,
  value,
  onChange,
  disabled
}: LabeledSwitchProps) {
  return (
    <Switch.Group>
      <div className="border border-column-grey-25 rounded-md pb-4 bg-white">
        <div className="pt-4 pl-4 flex items-center">
          <Switch
            checked={!!value}
            onChange={() => {
              onChange && onChange(!value);
            }}
            className={classNames(
              'relative inline-flex h-5 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
              {
                'bg-column-primary-500 hover:bg-primary-600':
                  value && !disabled,
                'bg-column-primary-200': value && disabled,
                'bg-column-gray-50': !value,
                'hover:bg-column-gray-200': !value && !disabled,
                'cursor-pointer': !disabled,
                'cursor-not-allowed': disabled
              }
            )}
            style={{
              width: '36px',
              height: '20px'
            }}
            disabled={disabled}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                value ? 'translate-x-4' : 'translate-x-0',
                'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
              )}
            />
          </Switch>
          <Switch.Label
            className={classNames(
              'pl-3 font-medium text-sm leading-6 inline-block',
              {
                'text-column-gray-500': !disabled,
                'text-column-gray-400': disabled
              }
            )}
          >
            {label}
          </Switch.Label>
        </div>
        {description && (
          <div className="pt-2 pl-16 pr-4 font-medium text-sm text-column-gray-400">
            {description}
          </div>
        )}
      </div>
    </Switch.Group>
  );
}

export default LabeledSwitch;
