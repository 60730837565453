import {
  ArrowDownTrayIcon,
  ChevronDownIcon,
  ReceiptPercentIcon
} from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Popover } from 'lib/components/Popover';
import { ColumnService } from 'lib/services/directory';
import { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import ToastActions from 'redux/toast';
import downloadProof from 'routes/ads/place/helpers/downloadProof';
import { logAndCaptureException } from 'utils';
import { OrderModel } from 'lib/model/objects/orderModel';
import MoreActionsItem from './MoreActionsItem';

type MoreActionsProps = {
  order: OrderModel;
  receiptUrl: string | undefined;
  invoiceIsLoading: boolean;
};

function MoreActions({
  order,
  receiptUrl,
  invoiceIsLoading
}: MoreActionsProps) {
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);
  const loading = downloadIsLoading || invoiceIsLoading;
  const dispatch = useAppDispatch();

  const handleDownloadPDF = async () => {
    try {
      setDownloadIsLoading(true);

      await downloadProof(order.id, order.modelData.activeVersion);
    } catch (error) {
      logAndCaptureException(
        ColumnService.OBITS,
        error,
        'Error downloading proofs',
        {
          orderId: order.id
        }
      );

      dispatch(
        ToastActions.toastError({
          headerText: 'Error',
          bodyText: 'Error downloading proofs. Please try again.'
        })
      );
    } finally {
      setDownloadIsLoading(false);
    }
  };

  return (
    <Popover
      id="more-options"
      activator={
        <ColumnButton
          type="button"
          loading={loading}
          buttonText="More actions"
          endIcon={<ChevronDownIcon className="w-4 h-4 text-column-gray-400" />}
        />
      }
      alignment="right"
    >
      <div className="p-2 space-y-2">
        <MoreActionsItem
          icon={<ArrowDownTrayIcon />}
          label="Download proof"
          onClick={handleDownloadPDF}
        />
        {receiptUrl && (
          <MoreActionsItem
            icon={<ReceiptPercentIcon />}
            label="View receipt"
            onClick={() => {
              window.open(receiptUrl, '_blank');
            }}
          />
        )}
      </div>
    </Popover>
  );
}

export default MoreActions;
