import { EOrganization, ESnapshotExists } from 'lib/types';
import {
  FilingType,
  isOrderFilingType,
  OrderFilingType
} from 'lib/types/filingType';
import { Product } from 'lib/enums';
import { getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { getFirebaseContext } from 'utils/firebase';
import { Alert } from 'lib/components/Alert';
import { FilingTypeModel } from 'lib/model/objects/filingTypeModel';
import { logAndCaptureCriticalError } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { useState } from 'react';
import { removeUndefinedFields } from 'lib/helpers';
import FilingTypeSettings from '../filingTypes';

type FilingTypeSettingsProps = {
  activeOrganization: OrganizationModel;
  productLine: Product;
};

type OrderFilingTypeSettingsProps = FilingTypeSettingsProps & {
  filingTypes: FilingTypeModel[];
};

function getTableFilingTypes(filingTypes: FilingTypeModel[]): FilingType[] {
  return filingTypes
    .map(filingType => filingType.modelData)
    .sort((a, b) => (a.label || '').localeCompare(b.label || ''));
}

function OrderFilingTypeSettings({
  activeOrganization,
  filingTypes,
  productLine
}: OrderFilingTypeSettingsProps) {
  const [tableFilingTypes, setTableFilingTypes] = useState(
    getTableFilingTypes(filingTypes)
  );
  const handleFilingTypeChange = async (filingType: FilingType) => {
    const index = filingTypes.findIndex(
      type => filingType.label === type.modelData.label
    );
    await filingTypes[index].ref.update(filingType);
    const newTableFilingTypes = getTableFilingTypes(filingTypes);
    setTableFilingTypes(newTableFilingTypes);

    /**
     * Synchronize filing type data across all publication mediums. This enables the "custom label"
     * and "custom description" to be applied globally across filing types
     */
    if (isOrderFilingType(filingType)) {
      const [
        fetchError,
        filingTypes
      ] = await activeOrganization.fetchFilingTypesForProductMedium({
        product: productLine
      });
      if (fetchError) return;
      const filingTypesInDifferentMediums = filingTypes.filter(
        otherMediumFilingType =>
          otherMediumFilingType.modelData.label === filingType.label
      );
      await Promise.all(
        filingTypesInDifferentMediums.map(async otherMediumFilingType => {
          const synchronizationUpdate: Partial<OrderFilingType> = {};
          if (filingType.customLabel) {
            synchronizationUpdate.customLabel = filingType.customLabel;
          }
          await otherMediumFilingType.ref.update(
            removeUndefinedFields({
              customLabel: filingType.customLabel,
              customDescription: filingType.customDescription
            })
          );
        })
      );
    }
  };

  return (
    <FilingTypeSettings<FilingType>
      activeOrganization={activeOrganization}
      onSave={handleFilingTypeChange}
      filingTypes={tableFilingTypes}
      productLine={productLine}
    />
  );
}

type ProductLineFilingTypeSettingsProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  productLine: Product;
  filingTypes: FilingTypeModel[];
};

function ProductLineFilingTypeSettings({
  activeOrganization,
  productLine,
  filingTypes
}: ProductLineFilingTypeSettingsProps) {
  const organizationModel = getModelFromSnapshot(
    OrganizationModel,
    getFirebaseContext(),
    activeOrganization
  );
  if (productLine === Product.Notice) {
    logAndCaptureCriticalError(
      ColumnService.OBITS,
      new Error('Invalid product line'),
      'Attempted to load a notice product from ProductLineFilingTypeSettings',
      {
        organizationId: activeOrganization.id,
        productLine
      }
    );
    return (
      <Alert
        id="invalid-product"
        description="Notices are not supported at this time."
      />
    );
  }

  return (
    <OrderFilingTypeSettings
      activeOrganization={organizationModel}
      productLine={productLine}
      filingTypes={filingTypes}
    />
  );
}

export default ProductLineFilingTypeSettings;
