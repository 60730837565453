import { isBulkPaymentV2EnabledOnOrganization } from 'lib/helpers';
import { EOrganization, ESnapshot, exists } from 'lib/types';
import { useEffect, useState } from 'react';
import { getFirebaseContext } from 'utils/firebase';

export const useBulkPaymentV2EnabledOnOrganization = (
  activeOrganization: ESnapshot<EOrganization>
) => {
  const [enabled, setEnabled] = useState(
    isBulkPaymentV2EnabledOnOrganization(activeOrganization, null)
  );

  useEffect(() => {
    const parentId = activeOrganization.data()?.parent?.id;
    if (!parentId) return;
    const unsubscribe = getFirebaseContext()
      .organizationsRef()
      .doc(parentId)
      .onSnapshot(async snapshot => {
        if (!exists(snapshot)) return;
        setEnabled(
          isBulkPaymentV2EnabledOnOrganization(activeOrganization, snapshot)
        );
      });

    return unsubscribe;
  }, [activeOrganization.data()?.parent?.id]);

  return enabled;
};
