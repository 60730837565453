import React, { useState } from 'react';
import { ColumnButton } from 'lib/components/ColumnButton';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { Modal } from 'lib/components/Modal';
import { NewspaperOrder } from 'lib/types/newspaperOrder';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { useFirestoreDocumentListener } from 'lib/frontend/hooks/useFirestoreDocumentListener';
import { OrderModel } from 'lib/model/objects/orderModel';
import LoadingState from 'components/LoadingState';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import Firebase from 'EnoticeFirebase';
import { ColumnService } from 'lib/services/directory';
import { capitalize } from 'lodash';
import { NewspaperOrdersFormData } from '../../../PlacementFlowStepSelector';

function OrderSelectCarousel({
  onSetSelectedNewspaperOrder,
  selectedNewspaperOrder,
  activePublisher,
  newspaperOrders
}: {
  onSetSelectedNewspaperOrder: (order: ESnapshotExists<NewspaperOrder>) => void;
  selectedNewspaperOrder: ESnapshotExists<NewspaperOrder>;
  newspaperOrders: ESnapshotExists<NewspaperOrder>[];
  activePublisher: ESnapshotExists<EOrganization>;
}) {
  const selectedOrderIndex = newspaperOrders.findIndex(order =>
    columnObjectsAreEqual(order, selectedNewspaperOrder)
  );

  return (
    <div className="flex items-center w-full lg:w-3/4 mx-auto">
      <ColumnButton
        type="button"
        buttonText={
          <ChevronLeftIcon className="w-6 h-6 column-gray-300 hover:column-primary-500" />
        }
        disabled={selectedOrderIndex === 0}
        onClick={() =>
          onSetSelectedNewspaperOrder(newspaperOrders[selectedOrderIndex - 1])
        }
      />
      <div className="flex-1 flex flex-col items-center gap-2">
        <div>
          {capitalize(selectedNewspaperOrder.data().publishingMedium)}{' '}
          publication in {activePublisher.data().name}
        </div>
        <div className="text-sm text-column-gray-400">
          Proof {selectedOrderIndex + 1} of {newspaperOrders.length}
        </div>
      </div>
      <ColumnButton
        type="button"
        buttonText={
          <ChevronRightIcon className="w-6 h-6 column-gray-300 hover:column-primary-500" />
        }
        disabled={selectedOrderIndex === newspaperOrders.length - 1}
        onClick={() =>
          onSetSelectedNewspaperOrder(newspaperOrders[selectedOrderIndex + 1])
        }
      />
    </div>
  );
}

type ProofPreviewProps = {
  newspaperOrdersFormData: NewspaperOrdersFormData;
  reloadingPreview: boolean;
  order: OrderModel;
  version: number;
};

/**
 * Proof preview component button that pops a modal with the preview of the ad.
 * This preview can then be downloaded as a PDF or printed directly from the modal.
 */
export default function ProofPreview({
  newspaperOrdersFormData,
  reloadingPreview,
  order,
  version
}: ProofPreviewProps) {
  const [selectedNewspaperOrder, setSelectedNewspaperOrder] = useState<
    ESnapshotExists<NewspaperOrder>
  >();

  const [showModal, setShowModal] = useState(false);

  const { value: newspaperOrders } = useAsyncEffect({
    fetchData: async () => {
      const newspaperOrders = await order.getNewspaperOrders({
        specifiedVersion: version
      });
      setSelectedNewspaperOrder(newspaperOrders[0]);
      return newspaperOrders;
    },
    dependencies: [
      order.id,
      newspaperOrdersFormData?.map(order => order.newspaper?.id).join(',')
    ]
  });

  const activePublisher = useFirestoreDocumentListener(
    selectedNewspaperOrder?.data()?.newspaper
  );
  const refreshedNewspaperOrder = useFirestoreDocumentListener(
    selectedNewspaperOrder?.ref
  );

  const selectedProofPath = refreshedNewspaperOrder?.data()?.pdfStoragePath;

  const { value: proofUrl, isLoading: isProofLoading } = useAsyncEffect({
    fetchData: async () => {
      if (!selectedProofPath) return;
      const proofUrl = await Firebase.storage()
        .ref(selectedProofPath)
        .getDownloadURL();
      return proofUrl as string;
    },
    dependencies: [selectedProofPath, showModal]
  });

  const enableViewingProofPreviewModal =
    !!activePublisher &&
    !!newspaperOrders &&
    !!selectedNewspaperOrder &&
    !!selectedProofPath;

  return (
    <div className="flex items-center justify-center">
      <ColumnButton
        type="button"
        buttonText="View proof"
        onClick={() => setShowModal(true)}
        disabled={!enableViewingProofPreviewModal || reloadingPreview}
        size="sm"
      />
      {showModal && enableViewingProofPreviewModal && (
        <Modal
          id="proof-preview"
          onClose={() => setShowModal(false)}
          title="Proof Preview"
          size="2xl"
        >
          {
            // Show the carousel if there are multiple proofs
            newspaperOrders?.length && newspaperOrders?.length > 1 && (
              <OrderSelectCarousel
                newspaperOrders={newspaperOrders}
                selectedNewspaperOrder={selectedNewspaperOrder}
                onSetSelectedNewspaperOrder={setSelectedNewspaperOrder}
                activePublisher={activePublisher}
              />
            )
          }
          <div
            className="flex justify-center overflow-scroll pt-4"
            style={{ height: '60vh' }}
          >
            {isProofLoading ? (
              <LoadingState context={{ service: ColumnService.OBITS }} />
            ) : (
              <iframe
                title={`Proof Preview for ${activePublisher.data().name}`}
                src={`${proofUrl}#navpanes=0&scrollbar=0`}
                frameBorder="0"
                width="100%"
              />
            )}
          </div>
        </Modal>
      )}
    </div>
  );
}
