import { NewspaperOrdersFormData } from 'routes/ads/place/PlacementFlowStepSelector';
import { Layout } from 'lib/types/layout';
import { logAndCaptureCriticalError } from 'utils';
import { ColumnService } from 'lib/services/directory';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getOrThrow } from 'lib/utils/refs';
import { getAdWidth } from 'lib/orders/layouts';
import { Ad } from 'lib/types/ad';
import { Product } from 'lib/enums';
import OrderImageEditor from '../Images/OrderImageEditor';
import { AdEditorData } from '../FormattedEditorWrapper';
import TitleEditor from './TitleEditor';
import ContentEditor, { MadlibEditorProps } from './ContentEditor';

type FormattedEditorProps<T extends Ad> = {
  adData: Partial<T>;
  onChange: (data: AdEditorData) => void;
  layout: Layout;
  newspaperOrdersFormData: NewspaperOrdersFormData;
  disableEditing: boolean;
  product: Product;
  madlibProps: MadlibEditorProps;
  forceFreeEdit: boolean;
};

function FormattedEditor<T extends Ad>({
  adData,
  onChange,
  layout,
  newspaperOrdersFormData,
  disableEditing,
  product,
  madlibProps,
  forceFreeEdit
}: FormattedEditorProps<T>) {
  const { value: adWidth, isLoading: isAdWidthLoading } = useAsyncEffect({
    fetchData: async () => {
      if (!newspaperOrdersFormData.length) return;
      const [firstNewspaperOrder] = newspaperOrdersFormData;
      const publisher = await getOrThrow(firstNewspaperOrder.newspaper);
      const template = await getOrThrow(publisher.data().adTemplate);
      const templateStyles = template.data().styles;
      if (!templateStyles) {
        logAndCaptureCriticalError(
          ColumnService.OBITS,
          new Error('No styles found for ad template'),
          'No styles found for ad template',
          {
            publisher: publisher.id,
            template: template.id
          }
        );
        return;
      }
      const { response: adWidth, error: adWidthError } = getAdWidth(
        layout,
        templateStyles
      );
      if (adWidthError) {
        logAndCaptureCriticalError(
          ColumnService.OBITS,
          adWidthError,
          'Error getting ad width'
        );
      }
      return adWidth;
    },
    dependencies: [
      layout.id,
      newspaperOrdersFormData.map(o => o.newspaper?.id).join(',')
    ]
  });
  if (isAdWidthLoading) return null;

  return (
    <div className="bg-white" style={{ width: `${adWidth}px` }}>
      <div className="border border-dashed">
        <OrderImageEditor
          adData={adData}
          onChange={onChange}
          layout={layout}
          disableEditing={disableEditing}
        />
        <h1 className="overflow-auto border-dashed border-b py-4 px-1">
          <TitleEditor
            adData={adData}
            disableEditing={disableEditing}
            product={product}
            onChange={onChange}
          />
        </h1>
        <div className="px-1">
          <ContentEditor
            onChange={onChange}
            adData={adData}
            disableEditing={disableEditing}
            product={product}
            madlibProps={madlibProps}
            forceFreeEdit={forceFreeEdit}
          />
        </div>
      </div>
    </div>
  );
}

export default FormattedEditor;
