import { useBulkPaymentV2EnabledOnOrganization } from 'components/hooks/useBulkPaymentV2EnabledOnOrganization';
import { BillingTermType } from 'lib/enums';
import { EOrganization, ESnapshot, EUser } from 'lib/types';
import React from 'react';
import { DetailsRow } from './DetailsRow';
import { CustomerDrawerToggleRow } from './CustomerDrawerToggleRow';

export type CustomerDrawerSettingsProps = {
  linerRateDescription?: string;
  displayRateDescription?: string;
  upfrontPayment: string;
  affidavitsBeforePayment?: boolean;
  activeOrganization: ESnapshot<EOrganization>;
  allowBulkInvoicing?: boolean;
  invoicedOutsideColumn?: boolean | null;
  hideDigitalAffidavits?: boolean;
  allowAffidavitEmail?: boolean;
  isCustomerOrganization?: boolean;
  billingTerm?: number;
  user?: EUser;
  isFuneralHome?: boolean;
  funeralHomeRates?: { obituaryRate: string; deathNoticeRate: string } | null;
};

export function CustomerDrawerSettingsTab({
  linerRateDescription,
  displayRateDescription,
  upfrontPayment,
  affidavitsBeforePayment,
  activeOrganization,
  allowBulkInvoicing,
  invoicedOutsideColumn,
  hideDigitalAffidavits,
  allowAffidavitEmail,
  isCustomerOrganization,
  billingTerm,
  user,
  isFuneralHome = false,
  funeralHomeRates = { obituaryRate: '', deathNoticeRate: '' }
}: CustomerDrawerSettingsProps) {
  // we only want to display the bulk invoice editing ability if the newspaper
  // has bulkPaymentEnabled_v2 on and the user affiliated with the customer is an individual,
  // or if the newspaper has bulkPaymentEnabled (regardless of user occupation)
  const isBulkPaymentV2EnabledOnOrganization = useBulkPaymentV2EnabledOnOrganization(
    activeOrganization
  );

  const userIsIndividual = !user?.allowedOrganizations?.length;
  const activeOrganizationHasBulkPaymentEnabled =
    isBulkPaymentV2EnabledOnOrganization && userIsIndividual;

  return (
    <div className="p-4 text-sm leading-6 font-medium w-full">
      {!isCustomerOrganization && (
        <div>
          <DetailsRow label="Liner Rate" value={linerRateDescription} />
          <DetailsRow label="Display Rate" value={displayRateDescription} />
        </div>
      )}
      {isFuneralHome && funeralHomeRates && (
        <div>
          <DetailsRow
            label="Obituary Rate"
            value={funeralHomeRates.obituaryRate ?? ''}
          />
          <DetailsRow
            label="Death Notice Rate"
            value={funeralHomeRates.deathNoticeRate ?? ''}
          />
        </div>
      )}
      {!allowBulkInvoicing && (
        <DetailsRow
          label="Upfront payment"
          value={isFuneralHome ? 'Always' : upfrontPayment}
        />
      )}

      {!isFuneralHome && (
        <CustomerDrawerToggleRow
          label="Allow affidavits before payment?"
          value={affidavitsBeforePayment}
        />
      )}
      {activeOrganizationHasBulkPaymentEnabled && (
        <CustomerDrawerToggleRow
          label="Allow bulk invoicing?"
          value={allowBulkInvoicing}
        />
      )}
      {activeOrganization.data()?.allowInvoiceOutsideColumn && (
        <CustomerDrawerToggleRow
          label="Allow invoice outside Column?"
          value={invoicedOutsideColumn}
        />
      )}
      {!isCustomerOrganization && hideDigitalAffidavits && (
        <CustomerDrawerToggleRow
          label="Allow emailed affidavits?"
          value={allowAffidavitEmail}
        />
      )}
      {userIsIndividual && (
        <DetailsRow
          label="Default invoice due date"
          value={
            BillingTermType.by_value(billingTerm)?.label ??
            BillingTermType.net_thirty.label
          }
        />
      )}
    </div>
  );
}
