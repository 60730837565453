import classNames from 'classnames';
import { CustomerTypeString } from 'routes/ads/filters/helpers';
import ChoiceButton from '../../components/ChoiceButton';

type FlowChoiceButtonProps = {
  title: CustomerTypeString;
  description: string;
  icon: React.ReactElement;
  onChosenFlowChange: (title: CustomerTypeString) => void;
  checked: boolean;
};

function FlowChoiceButton({
  title,
  icon,
  description,
  onChosenFlowChange,
  checked
}: FlowChoiceButtonProps) {
  const id = `${title}-option`;
  return (
    <ChoiceButton
      id={id}
      onClick={onChosenFlowChange}
      checked={checked}
      option={title}
    >
      <div className={classNames('p-6 flex flex-row text-column-gray-400')}>
        {icon}
        <div className="flex flex-col pl-4 text-column-gray-500">
          <div className="text-xl font-semibold">{title}</div>
          <div className="text-md">{description}</div>
        </div>
      </div>
    </ChoiceButton>
  );
}

export default FlowChoiceButton;
