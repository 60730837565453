import { Editor } from '@tinymce/tinymce-react';
import { Editor as MceEditor } from 'tinymce';
import { useRef, useState } from 'react';
import classNames from 'classnames';
import { useValidationChecks } from 'lib/components/TextField/hooks/useValidationChecks';

type MceToolbarOptions =
  | 'undo'
  | 'redo'
  | 'bold'
  | 'italic'
  | 'underline'
  | 'image'
  | 'formatselect';

type McePluginOptions = 'image' | 'code' | 'noneditable' | 'paste';

type MinimalMceProps = {
  toolbarItems: MceToolbarOptions[][];
  onChange: (o: string) => void;
  inline?: boolean;
  value: string;
  menubar?: boolean;
  plugins?: McePluginOptions[];
  contentPadding?: number;
  minHeight?: number;
  placeholder?: string;
  disabled?: boolean;
  id: string;
  required?: boolean;
};

function MinimalMce({
  toolbarItems,
  onChange,
  inline = false,
  menubar = false,
  value,
  plugins = ['paste'],
  contentPadding = 10,
  placeholder,
  minHeight = 20,
  disabled,
  id,
  required
}: MinimalMceProps) {
  const editorRef = useRef<MceEditor>(null);

  const handleChange = (value: string) => {
    if (!editorRef.current) throw Error();

    onChange(value);
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const [showErrors, setShowErrors] = useState(false);

  const { currentValidationMessage } = useValidationChecks({
    value,
    inputRef,
    validationMessages: {},
    setShowErrors
  });

  return (
    <div
      id={id}
      className={classNames('minimal-mce-editor', {
        'text-column-gray-300': disabled
      })}
    >
      <input
        ref={inputRef}
        hidden
        required={required}
        value={value}
        onChange={() => {}}
      />
      <Editor
        tinymceScriptSrc="/static/js/tinymce/tinymce.min.js"
        onInit={(evt, editor) => {
          (editorRef as any).current = editor;
        }}
        onEditorChange={handleChange}
        value={value}
        toolbar={toolbarItems.map(o => o.join(' ')).join(' | ')}
        inline={inline}
        init={{
          menubar,
          fixed_toolbar_container: '#custom-toolbar-container',
          paste_as_text: true,
          placeholder
        }}
        plugins={plugins}
        disabled={disabled}
      />
      {showErrors && currentValidationMessage && (
        <span className="text-xs text-column-red-600">
          {currentValidationMessage}
        </span>
      )}
      <style>{`
      #${id}.minimal-mce-editor > .mce-content-body {
        padding: ${contentPadding}px;
        min-height: ${minHeight}px;
      }

      /* This makes sure that placeholder formatting is working correctly */
      #${id}.minimal-mce-editor > .mce-content-body::before {
        margin-left: ${contentPadding}px;
        min-height: ${minHeight}px;
      }
      `}</style>
    </div>
  );
}

export default MinimalMce;
