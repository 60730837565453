export enum PublicationFontFamily {
  HELVETICA = 'Helvetica',
  CENTURY = 'Century',
  CENTURY_725_BT = 'Century725 BT',
  CHRONICLE = 'Chronicle',
  LUCIDA = 'Lucida',
  MYRIAD = 'Myriad',
  NIMROD_MT = 'NimrodMT',
  NIMROD_WGL = 'NimrodWGL',
  OLYMPIAN = 'Olympian',
  WHITNEY = 'Whitney',
  CHAPARRAL = 'Chaparral',
  ADAMANT = 'Adamant',
  MONTSERRAT = 'Montserrat',
  ANTIQUA = 'Antiqua',
  TIMES_NEW_ROMAN = 'TimesNewRoman',
  CASLON = 'Caslon',
  ELLINGTON = 'EllingtonMT',
  OPEN_SANS = 'OpenSans',
  COOPER_HEWITT = 'CooperHewitt',
  SWISS_721_BT = 'Swiss721BT',
  MINION_PRO = 'MinionPro',
  NEW_BASKERVILLE = 'NewBaskervilleITC',
  FUTURA_BOOK = 'FuturaBook',
  FUTURA_DEMI = 'FuturaDemi',
  UTOPIA = 'Utopia',
  HELVETICA_NEUE = 'HelveticaNeue',
  NEW_CENTURY_SCHOOLBOOK = 'NewCenturySchoolBook',
  BERTHOLD_BASKERVILLE = 'BertholdBaskerville',
  FUTURA_LT_PRO_XB = 'FuturaLtProXBold',
  SOURCE_SERIF_PRO = 'SourceSerifPro',
  BENTON_SANS_COND = 'BentonSansCondensed'
}

export interface PublicationFontResource {
  fontFamily: PublicationFontFamily;
  type: string;
  contentBase64: string;
  contentBoldBase64: string;
  contentItalicBase64: string;
  contentBoldItalicBase64: string;
}

export const getEmbeddedValue = (
  mediaType: string,
  base64String: string
): string => {
  const DATA = 'data:';
  const BASE64 = ';base64,';
  return [DATA, mediaType, BASE64, base64String].join('');
};

const getNormalFontFace = (
  publicationFontResource: PublicationFontResource
): string => {
  return `
  @font-face {
      font-family: "${publicationFontResource.fontFamily}";
      src: url(${getEmbeddedValue(
        publicationFontResource.type,
        publicationFontResource.contentBase64
      )});
  }`;
};

const getBoldFontFace = (
  publicationFontResource: PublicationFontResource
): string => {
  let result = '';
  if (publicationFontResource.contentBoldBase64) {
    result = `
      @font-face {
          font-family: "${publicationFontResource.fontFamily}";
          src: url(${getEmbeddedValue(
            publicationFontResource.type,
            publicationFontResource.contentBoldBase64
          )});
          font-weight: bold;
      }`;
  }
  return result;
};

const getItalicFontFace = (
  publicationFontResource: PublicationFontResource
): string => {
  let result = '';
  if (publicationFontResource.contentItalicBase64) {
    result = `
      @font-face {
          font-family: "${publicationFontResource.fontFamily}";
          src: url(${getEmbeddedValue(
            publicationFontResource.type,
            publicationFontResource.contentItalicBase64
          )});
          font-style: italic;
      }`;
  }
  return result;
};

const getBoldItalicFontFace = (
  publicationFontResource: PublicationFontResource
): string => {
  let result = '';
  if (publicationFontResource.contentBoldItalicBase64) {
    result = `
      @font-face {
          font-family: "${publicationFontResource.fontFamily}";
          src: url(${getEmbeddedValue(
            publicationFontResource.type,
            publicationFontResource.contentBoldItalicBase64
          )});
          font-weight: bold;
          font-style: italic;
      }`;
  }
  return result;
};

export const getFontFaceDefinition = (
  publicationFontResource: PublicationFontResource
): string => {
  const definition = `
      ${getNormalFontFace(publicationFontResource)}
      ${getBoldFontFace(publicationFontResource)}
      ${getItalicFontFace(publicationFontResource)}
      ${getBoldItalicFontFace(publicationFontResource)}
  `;

  return definition;
};
