import React, { useState } from 'react';
import { Layout } from 'lib/types/layout';
import { logAndCaptureCriticalError } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { OrderImage } from 'lib/orders/images';
import { Ad } from 'lib/types/ad';
import ImageEditorOrUploader from './ImageEditorOrUploader';

type OrderImageEditorData<T extends Ad> = Pick<Partial<T>, 'orderImages'>;
type OrderImageEditorProps<T extends Ad> = {
  adData: OrderImageEditorData<T>;
  onChange: (o: OrderImageEditorData<T>) => void;
  layout: Layout;
  disableEditing?: boolean;
};

export default function OrderImageEditor<T extends Ad>({
  adData,
  onChange,
  layout,
  disableEditing
}: OrderImageEditorProps<T>) {
  const [localOrderImages, setLocalOrderImages] = useState<
    (OrderImage | null)[]
  >(adData.orderImages || []);

  if (layout.photos === 0) {
    return null;
  }

  const insertImageAtIndex = (index: number, newOrderImage: OrderImage) => {
    const newOrderImages = [...localOrderImages];
    newOrderImages[index] = newOrderImage;
    onChange({ orderImages: newOrderImages.filter(Boolean) as OrderImage[] });
    setLocalOrderImages(newOrderImages);
  };

  const removeImageAtIndex = (index: number) => {
    const newOrderImages = [...localOrderImages];
    newOrderImages[index] = null;
    onChange({ orderImages: newOrderImages.filter(Boolean) as OrderImage[] });
    setLocalOrderImages(newOrderImages);
  };

  const FirstImageEditor = (
    <ImageEditorOrUploader
      orderImage={localOrderImages[0] || null}
      layout={layout}
      onDeleteOrderImage={() => removeImageAtIndex(0)}
      onUpdateOrderImage={newOrderImage => insertImageAtIndex(0, newOrderImage)}
      disabled={disableEditing}
    />
  );

  if (layout.photos === 1) {
    if (layout.photoAlignment === 'center') {
      return (
        <div
          className="flex items-center w-full"
          style={{ aspectRatio: layout.aspectRatio }}
        >
          {FirstImageEditor}
        </div>
      );
    }
    return (
      <div
        className="relative z-10 float-right bg-white border-dashed border-b border-l p-2"
        style={{ width: layout.photoWidth, aspectRatio: layout.aspectRatio }}
      >
        {FirstImageEditor}
      </div>
    );
  }
  if (layout.photos === 2) {
    const SecondImageEditor = (
      <ImageEditorOrUploader
        orderImage={localOrderImages[1] || null}
        layout={layout}
        onDeleteOrderImage={() => removeImageAtIndex(1)}
        onUpdateOrderImage={newOrderImage =>
          insertImageAtIndex(1, newOrderImage)
        }
        disabled={disableEditing}
      />
    );
    if (layout.photoAlignment === 'center') {
      return (
        <div className="flex gap-4">
          <div className="flex-1" style={{ aspectRatio: layout.aspectRatio }}>
            {FirstImageEditor}
          </div>
          <div className="flex-1" style={{ aspectRatio: layout.aspectRatio }}>
            {SecondImageEditor}
          </div>
        </div>
      );
    }
    return (
      <>
        <div
          className="relative z-10 float-right bg-white border-dashed border-b border-l p-2"
          style={{ width: layout.photoWidth, aspectRatio: layout.aspectRatio }}
        >
          {FirstImageEditor}
        </div>
        <div
          className="relative z-10 float-right bg-white border-dashed border-b border-l p-2"
          style={{ width: layout.photoWidth, aspectRatio: layout.aspectRatio }}
        >
          {SecondImageEditor}
        </div>
      </>
    );
  }
  logAndCaptureCriticalError(
    ColumnService.OBITS,
    new Error(`Unexpected layout.photos value: ${layout.photos}`),
    'Unexpected layout.photos value'
  );
  return null;
}
