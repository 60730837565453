import { useState } from 'react';
import { ResponseOrError } from '../../types/responses';
import { isResponseOrError } from '../../helpers';
import { AsyncEffectErrorConfig } from '../../frontend/hooks/useAsyncEffect';
import { getErrorReporter } from '../../utils/errors';
import { ColumnService } from '../../services/directory';

export function useSafeLoading({
  callback,
  errorConfig
}: {
  callback: (...args: any) => Promise<void> | Promise<ResponseOrError<void>>;
  errorConfig?: AsyncEffectErrorConfig;
}): [boolean, () => Promise<void>] {
  const [loading, setLoading] = useState(false);

  const callbackWrapper = async (...args: any) => {
    setLoading(true);
    try {
      const possibleResponseOrError = await callback(args);
      if (isResponseOrError(possibleResponseOrError)) {
        if (possibleResponseOrError.error) {
          throw possibleResponseOrError.error;
        }
      }
    } catch (err) {
      getErrorReporter().logAndCaptureError(
        errorConfig?.service || ColumnService.UNKNOWN,
        err,
        errorConfig?.message || 'Error in useSafeLoading',
        errorConfig?.tags
      );
    } finally {
      setLoading(false);
    }
  };

  return [loading, callbackWrapper];
}
