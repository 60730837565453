import htmlToText from 'html-to-text';
import moment from 'moment';
import { wrapError, ResponseOrError, wrapSuccess } from '../types/responses';
import {
  Event,
  SYNC_EVENT_TYPES,
  SyncEvent,
  SyncEventType,
  SyncTriggerEvent,
  NoticeAtDeadlineEvent,
  NoticeCancelledEvent,
  NOTICE_AT_DEADLINE,
  NOTICE_CANCELLED
} from '../types/events';
import { OrderByDirection } from '../types/firebase';
import { getNoticeTypeFromNoticeDataUnwrapped } from '../helpers';
import {
  Customer,
  CustomerOrganization,
  EFirebaseContext,
  ENotice,
  ENoticeDraft,
  EOrganization,
  ERef,
  ESnapshot,
  ESnapshotExists,
  exists
} from '../types';
import { isDisplay } from './display';
import { getCustomerAndCustomerOrganization } from './customer';
import { getErrorReporter } from '../utils/errors';
import { getIsAfterPublishingDeadlineSimplified } from '../utils/deadlines';
import { isInvoiceInitiatedOrPaid, isInvoiceVoidable } from '../utils/invoices';
import { safeAsync, safeGetOrThrow } from '../safeWrappers';

/**
 * Get whether the notice requires upfront payment without using async methods
 * Useful in placement flow before notice has been created
 */
export function syncGetRequiresUpfrontPayment(
  notice: ENotice | ENoticeDraft,
  newspaper: EOrganization,
  customer: Customer | undefined,
  customerOrganization: CustomerOrganization | undefined
): boolean {
  // Logic hierarchy for requireUpfrontPayment:
  // 1. Notice
  if (notice.requireUpfrontPayment != null) {
    return notice.requireUpfrontPayment;
  }

  // 2. Customer (user x for newspaper y)
  if (customer?.requireUpfrontPayment != null) {
    return customer?.requireUpfrontPayment;
  }

  // 3. Customer organization (advertiser org x for newspaper y)
  if (customerOrganization?.requireUpfrontPayment != null) {
    return customerOrganization.requireUpfrontPayment;
  }

  // 4. Custom notice type
  const noticeType = getNoticeTypeFromNoticeDataUnwrapped(notice, newspaper);
  if (noticeType && noticeType.upFrontPayment != null) {
    return noticeType.upFrontPayment;
  }

  // 5. Newspaper (does the newspaper default to requiring upfront payment?)
  if (newspaper.requireUpfrontPayment != null) {
    return newspaper.requireUpfrontPayment;
  }
  return false;
}

export const isSyncEvent = (
  event: ESnapshotExists<Event>
): event is ESnapshotExists<SyncEvent<SyncTriggerEvent>> => {
  const { type } = event.data();

  if (SYNC_EVENT_TYPES.includes(type as SyncEventType)) {
    return true;
  }

  return false;
};

export const getSyncEventsForNotice = async (
  ctx: EFirebaseContext,
  notice: ERef<ENotice>,
  options: { sortByCreatedAtDirection: OrderByDirection }
) => {
  const noticeEvents = await ctx
    .eventsRef()
    .where('notice', '==', notice)
    .orderBy('createdAt', options.sortByCreatedAtDirection)
    .get();

  const syncEvents = noticeEvents.docs.filter(isSyncEvent);

  return syncEvents;
};

export const isEligibleForAsyncDesign = (
  noticeSnapshot: ESnapshotExists<ENotice>
) => isDisplay(noticeSnapshot) || noticeSnapshot.data().postWithoutFormatting;

export const getInternalIdFromNotice = async (
  ctx: EFirebaseContext,
  notice: ESnapshotExists<ENotice>
) => {
  const { filer, newspaper, filedBy } = notice.data();
  const {
    customer,
    customerOrganization
  } = await getCustomerAndCustomerOrganization(ctx, {
    filer,
    newspaper,
    filedBy
  });

  const customerInternalId = customer?.data().internalID;
  const customerOrgInternalId = customerOrganization?.data().internalID;

  return { customerInternalId, customerOrgInternalId };
};

export const getNoticeAtDeadlineEvent = async (
  ctx: EFirebaseContext,
  noticeRef: ERef<ENotice>
) => {
  const noticeAtDeadlineEvents = (
    await ctx
      .eventsRef<NoticeAtDeadlineEvent>()
      .where('notice', '==', noticeRef)
      .where('type', '==', NOTICE_AT_DEADLINE)
      .orderBy('createdAt', 'desc')
      .get()
  ).docs;

  if (!noticeAtDeadlineEvents.length) {
    return undefined;
  }

  if (noticeAtDeadlineEvents.length > 1) {
    getErrorReporter().logInfo(
      'In getNoticeAtDeadlineEvent more than one deadline event found. Returning most recent deadline event.',
      { eventId: noticeAtDeadlineEvents[0].id }
    );
  }

  return noticeAtDeadlineEvents[0];
};

export const getNoticeCancelledEvent = async (
  ctx: EFirebaseContext,
  noticeRef: ERef<ENotice>
) => {
  const noticeCancelledEvents = (
    await ctx
      .eventsRef<NoticeCancelledEvent>()
      .where('notice', '==', noticeRef)
      .where('type', '==', NOTICE_CANCELLED)
      .orderBy('createdAt', 'desc')
      .get()
  ).docs;

  if (!noticeCancelledEvents.length) {
    return undefined;
  }

  if (noticeCancelledEvents.length > 1) {
    const cancelEventIds = noticeCancelledEvents.map(event => event.id);
    getErrorReporter().logAndCaptureWarning(
      'In getNoticeCancelledEvent multiple notice cancelled events found. Returning most recent event.',
      {
        noticeId: noticeRef.id,
        cancelEventIds: cancelEventIds.join(),
        mostRecentEventId: noticeCancelledEvents[0].id
      }
    );
  }

  return noticeCancelledEvents[0];
};

/**
 * Most notices have many tells that they have not been submitted, but some notices,
 * particularly duplicate notices, appear with many of the properties that only submitted
 * notices otherwise have. One of the few properties that does not get copied over to a
 * duplicated notice is the noticeStatus, which is why it's used as the determinative
 * property here.
 */
export const noticeIsSubmitted = (notice: ESnapshotExists<ENotice>) => {
  const { noticeStatus } = notice.data();
  return !!noticeStatus;
};

export const getNoticeText = (notice: ESnapshotExists<ENotice>) => {
  const { confirmedHtml, unusedConfirmedHtml } = notice.data();

  const noticeHtml = confirmedHtml || unusedConfirmedHtml || '';

  // Remove any base64 data URLs from the payload before deciding on the
  // max length. This regex replaces 'src="..."' attributes as well as
  // 'data-mce-src="..."' attributes.
  const noticeHtmlWithoutImageSrcs = noticeHtml.replace(/src="data:.+?"/g, '');

  return htmlToText.fromString(noticeHtmlWithoutImageSrcs);
};

/**
 * Gets the draft that was just copied to this notice, if any.
 * @param noticeSnap
 */
export const getJustSubmittedDraft = (
  noticeSnap: ESnapshot<ENotice>,
  drafts: ESnapshot<ENoticeDraft>[]
) => {
  const noticeLastModifiedDate =
    noticeSnap.data()?.editedAt || noticeSnap.data()?.confirmedAt;
  /**
   * The draft that was just copied to the notice is the one with
   * the same last modified date as the notice
   */
  const justSubmittedDraft =
    !!noticeLastModifiedDate &&
    drafts.find(draft => {
      const draftEditedDate = draft.data()?.editedAt;
      return (
        !!draftEditedDate &&
        Math.abs(
          moment(noticeLastModifiedDate.toDate()).diff(
            moment(draftEditedDate.toDate()),
            'seconds'
          )
        ) < 1
      );
    });

  return justSubmittedDraft;
};

/**
 * Determines if the given notice can be cancelled by an advertiser or a publisher
 */
export const canCancelNoticeWithoutSupport = async (
  notice: ESnapshotExists<ENotice>,
  isPublisher: boolean
): Promise<
  ResponseOrError<{
    canAdvertiserCancel: boolean;
    canPublisherCancel: boolean;
    canAdvertiserRequestToCancel: boolean;
  }>
> => {
  const { response: newspaper, error: newspaperError } = await safeGetOrThrow(
    notice.data().newspaper
  );
  if (newspaperError) {
    return wrapError(newspaperError);
  }

  const { response: invoice, error: invoiceError } = await safeAsync(async () =>
    notice.data().invoice?.get()
  )();
  if (invoiceError) {
    return wrapError(invoiceError);
  }

  const isAfterPublishingDeadline = getIsAfterPublishingDeadlineSimplified(
    notice,
    newspaper
  );

  const invoiceInitiatedOrPaid = exists(invoice)
    ? isInvoiceInitiatedOrPaid(invoice)
    : false;

  /**
   * TODO(goodpaul): Steps needed so all invoice statuses can be cancelled:
   *  - Build tooling so support can abort capture for 'authorized' invoices
   *  - Support refund of `partially_refunded` invoices (i.e. 2nd refund)
   */
  const canPublisherCancel = isPublisher;
  const isInvoiceStatusVoidable = exists(invoice)
    ? isInvoiceVoidable(invoice)
    : true;

  const canAdvertiserRequestToCancel = !isPublisher && isInvoiceStatusVoidable;

  const canAdvertiserCancel =
    canAdvertiserRequestToCancel &&
    !isAfterPublishingDeadline &&
    !invoiceInitiatedOrPaid;

  return wrapSuccess({
    canAdvertiserCancel,
    canPublisherCancel,
    canAdvertiserRequestToCancel
  });
};
