import { TextField } from 'lib/components/TextField';
import React, { useState } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

type PasswordFieldProps = {
  id: string;
  value: string;
  required?: boolean;
  className?: string;
  placeHolderText?: string;
  onValueChange: (value: string) => void;
  labelText?: string;
  errorText?: string;
};

export function PasswordField({
  id,
  value,
  className,
  placeHolderText,
  onValueChange,
  labelText,
  errorText
}: PasswordFieldProps) {
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  return (
    <div className={className}>
      <TextField
        id={id}
        labelText={labelText || ''}
        type={isRevealPwd ? 'text' : 'password'}
        placeholder={placeHolderText}
        required
        value={value}
        onChange={e => onValueChange(e)}
        errorText={errorText}
        suffix={
          <div onClick={() => setIsRevealPwd(!isRevealPwd)}>
            {isRevealPwd ? (
              <EyeIcon className="h-5 w-5" />
            ) : (
              <EyeSlashIcon className="h-5 w-5" />
            )}
          </div>
        }
      />
    </div>
  );
}
