import LoadingState from 'components/LoadingState';
import { TextField } from 'lib/components/TextField';
import { State, enumToSelectInput, Product } from 'lib/enums';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import { Customer, ESnapshot, ESnapshotExists, EUser, exists } from 'lib/types';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import {
  Order,
  AnonymousOrderContactInfo,
  AnonymousOrder,
  isPublisherAsAdvertiserOrder
} from 'lib/types/order';
import { selectActiveOrganization, selectIsPublisher } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import CustomerSearch from 'routes/placeScroll/ConfirmFilerStep/CustomerSearch';
import { getUserByEmail } from 'utils/users';
import CustomerService from 'lib/services/customerService';
import { getFirebaseContext } from 'utils/firebase';
import { getBooleanFlag } from 'utils/flags';
import { useState } from 'react';
import { GridInput } from 'lib/components/Card/Grid';
import { getModelFromSnapshot } from 'lib/model';
import { CustomerModel } from 'lib/model/objects/customerModel';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import { Alert } from 'lib/components/Alert';
import { ColumnService } from 'lib/services/directory';
import NativeSelect from './FlowChoice/FuneralHomeSearch/CustomerCreation/NativeSelect';
import MultiStepHeader from '../components/MultiStepHeader';

export const MINIMUM_ORDER: AnonymousOrderContactInfo = {
  contactEmail: '',
  firstName: '',
  lastName: '',
  phone: ''
};

type PersonalDetailProps = {
  product: Product;
  inputData: Partial<AnonymousOrder>;
  setInputData: (inputData: Partial<AnonymousOrder>) => void;
};

function PersonalDetail({
  product,
  inputData,
  setInputData
}: PersonalDetailProps) {
  const isPublisher = useAppSelector(selectIsPublisher);

  const customerRef = isPublisherAsAdvertiserOrder(inputData)
    ? inputData.advertiserCustomer
    : null;

  const activeOrganization = useAppSelector(selectActiveOrganization);

  const debouncedEmail = useDebounce(inputData.contactEmail, 500);

  const [customerExists, setCustomerExists] = useState(false);

  const customerService = new CustomerService(getFirebaseContext());

  const setInputDataFromCustomer = async (
    customerSnap: ESnapshot<Customer>
  ): Promise<ResponseOrError<void>> => {
    if (!exists(customerSnap)) {
      setCustomerExists(false);

      return wrapSuccess(undefined);
    }

    const customer = getModelFromSnapshot(
      CustomerModel,
      getFirebaseContext(),
      customerSnap
    );

    const contactEmailResult = await customer.getEmail();

    if (contactEmailResult.error) {
      return contactEmailResult;
    }

    setInputData({
      ...inputData,
      firstName: customer.modelData.firstName,
      lastName: customer.modelData.lastName || '',
      phone: customer.modelData.phone || '',
      contactEmail: contactEmailResult.response,
      addressLine1: customer.modelData.address || '',
      addressLine2: customer.modelData.addressLine2 || '',
      city: customer.modelData.city || '',
      state: customer.modelData.state || 0,
      zip: customer.modelData.zipCode || '',
      organizationName: customer.modelData.organizationName
    });

    setCustomerExists(true);

    return wrapSuccess(undefined);
  };

  const setInputDataFromUser = async (
    userSnap: ESnapshotExists<EUser>
  ): Promise<ResponseOrError<void>> => {
    if (!activeOrganization) {
      setCustomerExists(false);

      return wrapSuccess(undefined);
    }

    const customerSnap = await customerService.getCustomerFromUserAndOrg(
      userSnap.ref,
      activeOrganization.ref
    );

    return setInputDataFromCustomer(customerSnap);
  };

  const enableCustomerSearch =
    product === Product.Classified &&
    isPublisher &&
    getBooleanFlag(LaunchDarklyFlags.ENABLE_CLASSIFIEDS_CUSTOMERS);

  const { isError, isLoading } = useSafeAsyncEffect({
    fetchData: async () => {
      if (customerRef) {
        const customerSnap = await customerRef.get();

        return setInputDataFromCustomer(customerSnap);
      }

      if (
        !enableCustomerSearch ||
        !inputData.contactEmail ||
        !inputData.contactEmail.includes('@')
      ) {
        setCustomerExists(false);

        return wrapSuccess(undefined);
      }

      try {
        const userSnap = await getUserByEmail(inputData.contactEmail);

        if (!exists(userSnap)) {
          setCustomerExists(false);

          return wrapSuccess(undefined);
        }

        return setInputDataFromUser(userSnap);
      } catch (e) {
        return wrapError(e as Error);
      }
    },
    dependencies: [debouncedEmail],
    errorConfig: {
      message: 'Failed to load customer information',
      service: ColumnService.OBITS,
      tags: {
        customerId: customerRef?.id || '',
        email: inputData.contactEmail || ''
      }
    }
  });

  if (isError) {
    return (
      <Alert
        id="error"
        status="error"
        description="Failed to load customer info"
      />
    );
  }

  const {
    firstName = '',
    lastName = '',
    contactEmail = '',
    phone = '',
    addressLine1 = '',
    addressLine2 = '',
    city = '',
    state = 0,
    zip = '',
    organizationName = ''
  } = {
    ...MINIMUM_ORDER,
    ...inputData
  };

  const disableInputs = enableCustomerSearch && customerExists;

  const showLoading = enableCustomerSearch && isLoading;

  return (
    <>
      <MultiStepHeader
        title={isPublisher ? 'Customer Details' : 'Contact Information'}
        description={
          isPublisher
            ? 'Please provide contact information for your customer.'
            : 'We use this to reach you about any changes needed before publication.'
        }
      />
      <div className="grid md:grid-cols-2 gap-3 mb-8">
        {enableCustomerSearch && exists(activeOrganization) && (
          <GridInput fullWidth>
            <CustomerSearch
              publicationSnap={activeOrganization}
              onSelect={async selectedUser =>
                setInputDataFromUser(selectedUser)
              }
              active
            />
          </GridInput>
        )}
        <GridInput>
          <TextField
            id="first-name"
            value={firstName}
            type="text"
            onChange={value => setInputData({ ...inputData, firstName: value })}
            onBlur={() =>
              setInputData({
                ...inputData,
                firstName: firstName.trim()
              })
            }
            labelText="First name"
            placeholder="Jane"
            required
            disabled={disableInputs}
            suffix={showLoading && <LoadingState isFullScreen={false} />}
          />
        </GridInput>
        <GridInput>
          <TextField
            id="last-name"
            value={lastName}
            type="text"
            onChange={value => setInputData({ ...inputData, lastName: value })}
            placeholder="Doe"
            onBlur={() =>
              setInputData({
                ...inputData,
                lastName: lastName.trim()
              } as Partial<Order>)
            }
            labelText="Last name"
            required
            disabled={disableInputs}
            suffix={showLoading && <LoadingState isFullScreen={false} />}
          />
        </GridInput>
        <GridInput>
          <TextField
            id="email"
            value={contactEmail}
            type="email"
            onChange={value => {
              setInputData({
                ...inputData,
                contactEmail: value,
                advertiser: undefined,
                advertiserCustomer: undefined,
                advertiserOrganization: undefined
              } as Partial<Order>);
            }}
            labelText="Email address"
            placeholder={
              isPublisher ? 'yourclient@gmail.com' : 'youremail@gmail.com'
            }
            required
          />
        </GridInput>
        <GridInput>
          <TextField
            id="phone"
            value={phone}
            type="tel"
            onChange={value => setInputData({ ...inputData, phone: value })}
            labelText="Phone number"
            placeholder="(000) 000-0000"
            required
            disabled={disableInputs}
            suffix={showLoading && <LoadingState isFullScreen={false} />}
          />
        </GridInput>
        {getBooleanFlag(LaunchDarklyFlags.ENABLE_CLASSIFIEDS_CUSTOMERS) && (
          <>
            <GridInput>
              <TextField
                id="address-line-1"
                value={addressLine1}
                type="text"
                onChange={value =>
                  setInputData({ ...inputData, addressLine1: value })
                }
                labelText="Street address"
                disabled={disableInputs}
                suffix={showLoading && <LoadingState isFullScreen={false} />}
              />
            </GridInput>
            <GridInput>
              <TextField
                id="address-line-2"
                value={addressLine2}
                type="text"
                onChange={value =>
                  setInputData({ ...inputData, addressLine2: value })
                }
                labelText="Apt/Suite"
                disabled={disableInputs}
                suffix={showLoading && <LoadingState isFullScreen={false} />}
              />
            </GridInput>
            <GridInput>
              <TextField
                id="city"
                value={city}
                type="text"
                onChange={value => setInputData({ ...inputData, city: value })}
                labelText="City"
                disabled={disableInputs}
                suffix={showLoading && <LoadingState isFullScreen={false} />}
              />
            </GridInput>
            <GridInput>
              <NativeSelect
                id="state"
                labelText="State"
                allowUndefined
                placeholder="State"
                options={enumToSelectInput(State)}
                value={`${state ?? ''}`}
                onChange={value => {
                  setInputData({
                    ...inputData,
                    state: value ? Number(value) : undefined
                  });
                }}
                disabled={disableInputs}
              />
            </GridInput>
            <GridInput>
              <TextField
                id="zip"
                value={zip}
                type="postal-code"
                onChange={value => setInputData({ ...inputData, zip: value })}
                labelText="Zip code"
                disabled={disableInputs}
                suffix={showLoading && <LoadingState isFullScreen={false} />}
              />
            </GridInput>
            <GridInput>
              <TextField
                id="organization-name"
                value={organizationName}
                type="text"
                onChange={value =>
                  setInputData({ ...inputData, organizationName: value })
                }
                labelText="Organization name"
                disabled={disableInputs}
                suffix={showLoading && <LoadingState isFullScreen={false} />}
              />
            </GridInput>
          </>
        )}
      </div>
    </>
  );
}

export default PersonalDetail;
