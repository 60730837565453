import classNames from 'classnames';
import { ColumnButton } from 'lib/components/ColumnButton';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { useRef, useState } from 'react';
import { Layout } from 'lib/types/layout';
import { NewspaperOrder } from 'lib/types/newspaperOrder';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getModelFromRef } from 'lib/model';
import { FilingTypeModel } from 'lib/model/objects/filingTypeModel';
import { getFirebaseContext } from 'utils/firebase';
import LoadingState from 'components/LoadingState';
import { ColumnService } from 'lib/services/directory';
import ChoiceButton from '../../components/ChoiceButton';
import { useScrollPositionWithinParent } from './useScrollPosition';

const getBorderColorClass = (active: boolean) => ({
  'border-column-primary-400': active,
  'border-column-gray-200': !active
});

const layoutToString = ({ columns, photos }: Layout) =>
  `${columns} columns, ${photos} photos`;

type LayoutIconProps = {
  layout: Layout;
  active: boolean;
  disabled?: boolean;
};

function LayoutIcon({ layout: { id, iconSvgUrl }, active }: LayoutIconProps) {
  return (
    <div
      className={classNames(
        'rounded-lg p-2 border-2 mx-auto',
        getBorderColorClass(active)
      )}
    >
      <img className={'h-20 mx-auto'} src={iconSvgUrl} alt={id} />
    </div>
  );
}

function LayoutButton({ layout, active, disabled }: LayoutIconProps) {
  const textClassName = classNames('text-xs text-center pt-2', {
    'text-column-primary-500': active && !disabled,
    'text-column-gray-400': !active && !disabled,
    'text-column-gray-500': active && disabled,
    'text-column-gray-100': !active && disabled
  });

  return (
    <>
      <LayoutIcon layout={layout} active={active} />
      <div className={textClassName}>{layoutToString(layout)}</div>
    </>
  );
}

function LayoutScrollButton({
  disabled,
  onClick,
  icon
}: {
  disabled: boolean;
  onClick: () => void;
  icon: React.ReactElement;
}) {
  return (
    <div className="hidden lg:block">
      <ColumnButton
        type="button"
        buttonText={icon}
        disabled={disabled}
        onClick={onClick}
      />
    </div>
  );
}

type LayoutSelectorProps = {
  onLayoutChange: (layout: Layout) => void;
  newspaperOrder: Partial<NewspaperOrder>;
  layout: Layout;
  selectionDisabled?: boolean;
};

const SCROLL_INTERVAL = 400;
const NUMBER_OF_LAYOUTS_TO_SHOW_SCROLL_BUTTONS = 5;

function LayoutSelector({
  onLayoutChange,
  layout,
  selectionDisabled,
  newspaperOrder
}: LayoutSelectorProps) {
  const { value: availableLayouts, isLoading } = useAsyncEffect({
    fetchData: async () => {
      if (!newspaperOrder.filingType) {
        throw Error('Missing filing type on newspaper order');
      }

      const filingTypeModel = await getModelFromRef(
        FilingTypeModel,
        getFirebaseContext(),
        newspaperOrder.filingType
      );

      const { supportedLayouts } = filingTypeModel;

      /**
       * If the current layout is not available for the current newspaper order,
       * select the first available layout.
       */
      if (
        supportedLayouts.every(
          supportedLayout => supportedLayout.id !== layout.id
        )
      ) {
        onLayoutChange(supportedLayouts[0]);
      }

      return supportedLayouts;
    },
    dependencies: []
  });

  const [scrollPosition, setScrollPosition] = useState({
    leftOffset: 0,
    rightOffset: 0
  });
  const layoutSelectorRef = useRef<HTMLDivElement>(null);

  useScrollPositionWithinParent({
    elementRef: layoutSelectorRef,
    debounce: 100,
    callback: setScrollPosition
  });
  const showScrollButtons =
    (availableLayouts?.length ?? 0) > NUMBER_OF_LAYOUTS_TO_SHOW_SCROLL_BUTTONS;

  if (isLoading || !availableLayouts) {
    return <LoadingState context={{ service: ColumnService.OBITS }} />;
  }

  return (
    <div className="flex items-center w-full lg:w-3/4 mx-auto">
      {showScrollButtons && (
        <LayoutScrollButton
          icon={
            <ChevronLeftIcon className="w-6 h-6 column-gray-300 hover:column-primary-500" />
          }
          disabled={scrollPosition.leftOffset === 0}
          onClick={() =>
            layoutSelectorRef.current?.scrollBy({
              left: -SCROLL_INTERVAL,
              behavior: 'smooth'
            })
          }
        />
      )}

      <div
        className={classNames('flex gap-6 overflow-x-auto', {
          'mx-auto': !showScrollButtons,
          'mx-6': showScrollButtons
        })}
        ref={layoutSelectorRef}
      >
        {availableLayouts.map(selectableLayout => {
          const checked = selectableLayout.id === layout.id;

          return (
            <div
              key={selectableLayout.id}
              className="w-36 md:40 xl:w-48 flex-shrink-0 py-4"
            >
              <ChoiceButton
                id={selectableLayout.id}
                checked={checked}
                option={selectableLayout}
                onClick={onLayoutChange}
                disabled={selectionDisabled}
              >
                <div className="p-2">
                  <LayoutButton
                    layout={selectableLayout}
                    active={checked}
                    disabled={selectionDisabled}
                  />
                </div>
              </ChoiceButton>
            </div>
          );
        })}
      </div>
      {showScrollButtons && (
        <LayoutScrollButton
          icon={
            <ChevronRightIcon className="w-6 h-6 column-gray-300 hover:column-primary-500" />
          }
          disabled={scrollPosition.rightOffset === 0}
          onClick={() =>
            layoutSelectorRef.current?.scrollBy({
              left: SCROLL_INTERVAL,
              behavior: 'smooth'
            })
          }
        />
      )}
    </div>
  );
}

export default LayoutSelector;
