import React, { useEffect, useState } from 'react';
import FileDropzone from 'lib/components/FileUpload/FileDropzone';
import {
  ArrowTopRightOnSquareIcon,
  TrashIcon
} from '@heroicons/react/24/outline';
import { logAndCaptureException } from 'utils';
import { FileMetadata } from 'lib/types/metaFile';
import { MetaFile } from 'types/metaFile';
import { Alert } from 'lib/components/Alert';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import classNames from 'classnames';
import { ColumnService } from 'lib/services/directory';
import { PpiSelect } from './metadata/PpiSelect';
import { getMetaFilesFromFiles, isJpgFileName } from './metadata/helpers';

const UPLOAD_FILE_MESSAGE = 'Uploading...';
const DELETE_FILE_MESSAGE = 'Deleting...';

export type UploadedAttachmentData = {
  id: string;
  url: string;
  path: string;
  status: string;
  name: string;
  metadata?: FileMetadata;
};

type AttachmentUploadFieldProps = {
  id: string;
  multiSelect?: boolean;
  readOnly?: boolean;
  loading: boolean;
  acceptFileTypes: string;
  uploadedAttachments?: UploadedAttachmentData[];
  onUpdateMetadata: (
    uploadFieldId: string,
    attachmentId: string,
    key: keyof FileMetadata,
    value: string
  ) => Promise<void>;
  onDeleteAttachment: (
    uploadFieldId: string,
    attachmentId: string
  ) => Promise<void>;
  onUploadFiles: (uploadFieldId: string, files: MetaFile[]) => Promise<void>;
  onUpdateAttachmentStatus?: (
    uploadFieldId: string,
    attachmentId: string,
    status: string
  ) => Promise<void>;
};

export function AttachmentUploadField(props: AttachmentUploadFieldProps) {
  const [error, setError] = useState<string | undefined>();
  const [filesToUpload, setFilesToUpload] = useState<MetaFile[]>([]);
  const [deleting, setDeleting] = useState(false);
  // State used to lock features of this component until a rerender
  const [
    waitingForUpdatedAttachments,
    setWaitingForUpdatedAttachments
  ] = useState(false);

  const {
    id: uploadFieldId,
    multiSelect,
    readOnly,
    loading: parentLoading,
    acceptFileTypes,
    uploadedAttachments,
    onUpdateMetadata,
    onUploadFiles,
    onDeleteAttachment
  } = props;

  const loading =
    waitingForUpdatedAttachments ||
    parentLoading ||
    !!deleting ||
    !!filesToUpload.length;

  useEffect(() => {
    if (parentLoading === true) return;
    setWaitingForUpdatedAttachments(false);
    setDeleting(false);
    setFilesToUpload([]);
  }, [parentLoading]);

  const onFileDrop = async (files: File[]) => {
    setError('');

    const filesWithMetadata = getMetaFilesFromFiles(files);
    setFilesToUpload(filesWithMetadata);
    setWaitingForUpdatedAttachments(true);
    try {
      await onUploadFiles(uploadFieldId, filesWithMetadata);
    } catch (error) {
      logAndCaptureException(
        ColumnService.PAGINATION,
        error,
        'Failed to upload file',
        {
          uploadFieldId
        }
      );
      setError('Failed to upload file');
    }
  };

  const onPopOutClick = (url: string) => {
    window.open(url);
  };

  const onDeleteClick = async (attachmentId: string) => {
    setDeleting(true);
    setWaitingForUpdatedAttachments(true);
    await onDeleteAttachment(uploadFieldId, attachmentId);
  };

  return (
    <>
      {error && (
        <Alert
          id="uploadModalError"
          status="error"
          title="Something went wrong"
          description={error}
        />
      )}
      <FileDropzone
        id={uploadFieldId}
        multiple={multiSelect}
        acceptFileTypes={acceptFileTypes}
        disabled={readOnly}
        onDrop={async file => {
          await onFileDrop(file);
        }}
      />
      {deleting && (
        <div className="flex flex-row items-center mt-2 space-x-2">
          <div className="flex flex-row rounded-md border border-column-gray-100 bg-column-gray-50 align-middle min-h-11 w-full space-x-2 py-3">
            <div className="flex pl-3 items-center justify-center rounded-b">
              <LoadingSpinner />
            </div>
            <Alert
              id={`${uploadFieldId}-deleting-message`}
              status="info"
              title={DELETE_FILE_MESSAGE}
            />
          </div>
        </div>
      )}
      {filesToUpload &&
        filesToUpload.map((fileToUpload, index) => (
          <div
            key={`${uploadFieldId} uploading-file ${index}`}
            className="text-sm"
          >
            <div className="flex flex-row items-center mt-2 space-x-2">
              <div className="flex flex-row rounded-md border border-column-gray-100 bg-column-gray-50 align-middle min-h-11 w-full space-x-2 py-3">
                <div className="flex pl-3 items-center justify-center rounded-b">
                  <LoadingSpinner />
                </div>
                <Alert
                  id={`${uploadFieldId}-upload-message`}
                  status="info"
                  title={UPLOAD_FILE_MESSAGE}
                />
              </div>
            </div>
          </div>
        ))}
      {uploadedAttachments &&
        uploadedAttachments.map((uploadedAttachment, index) => (
          <div
            key={`${uploadFieldId} uploaded-file ${index}`}
            className={`text-sm flex justify-between`}
          >
            <div className="flex flex-row items-center space-x-2">
              <div className="border border-column-gray-100 align-middle rounded-md bg-column-gray-50 text-column-gray-400 flex items-center pl-3.5 min-h-11 w-full py-3">
                {uploadedAttachment.name}
              </div>
              <ArrowTopRightOnSquareIcon
                className="bg-column-primary-50 h-11 w-12 rounded-md text-column-primary-500 p-2 cursor-pointer"
                onClick={() => onPopOutClick(uploadedAttachment.url)}
              />
              <TrashIcon
                className={classNames(
                  'bg-column-red-50 h-11 w-12 rounded-md text-column-red-500 p-2',
                  {
                    'pointer-events-none': loading || readOnly,
                    'cursor-pointer': !loading && !readOnly
                  }
                )}
                onClick={() => onDeleteClick(uploadedAttachment.id)}
                aria-disabled={loading || readOnly}
              />
            </div>
            {isJpgFileName(uploadedAttachment?.name) && (
              <div className="flex items-center mb-8">
                <PpiSelect
                  uploadFieldId={uploadFieldId}
                  attachmentId={uploadedAttachment.id}
                  metadata={uploadedAttachment.metadata}
                  onChange={onUpdateMetadata}
                  disabled={readOnly}
                />
              </div>
            )}
          </div>
        ))}
    </>
  );
}
