import { DocumentTextIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import ToastActions from 'redux/toast';
import { EOrganization, ESnapshotExists, EUser } from 'lib/types';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { getFirebaseContext } from 'utils/firebase';
import { ColumnButton } from 'lib/components/ColumnButton';
import { userIsSuper } from 'utils/permissions';
import { Form } from 'lib/components/Form';
import SettingsPage from 'routes/settings/SettingsPage';
import { isColumnUser } from 'lib/helpers';
import { useAppDispatch } from 'redux/hooks';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { useBooleanFlag } from 'utils/flags';
import ImplementationInfo from './ImplementationInfo';
import SettingsHeader from '../../SettingsHeader';
import PublisherInfo from './PublisherInfo';
import PublisherInfoDrawer from './PublisherInfoDrawer';
import AdjudicationInfo from './AdjudicationInfo/AdjudicationInfo';

type PublisherSettingsInfoProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  user: ESnapshotExists<EUser>;
};

/**
 * Control panel for editing basic information about the publisher
 */
export default function PublisherSettingsInfo({
  activeOrganization,
  user
}: PublisherSettingsInfoProps) {
  const dispatch = useAppDispatch();
  const [showNotes, setShowNotes] = useState(false);

  const [orgData, setOrgData] = useState(activeOrganization.data());
  const [saving, setSaving] = useState(false);
  const edited = !columnObjectsAreEqual(orgData, activeOrganization.data());

  const publisherNotesQuery = useFirestoreQueryListener(
    getFirebaseContext()
      .notesRef()
      .where('publisher', '==', activeOrganization.ref)
      .where('noteCreatorOrganization', '==', activeOrganization.ref),
    [activeOrganization.id]
  );
  const numberOfActiveNotes =
    publisherNotesQuery?.docs.filter(note => note.data().status !== 'archived')
      .length || 0;

  const isAdjudicationAreaEnabled = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_ADJUDICATION_AREA_ORGANIZATION_SETTINGS
  );

  return (
    <>
      <SettingsPage>
        <Form
          id="publisher-settings-info"
          onSubmit={async () => {
            setSaving(true);
            await activeOrganization.ref.update(orgData);
            setSaving(false);
            dispatch(
              ToastActions.toastSuccess({
                headerText: 'Success',
                bodyText: 'Publisher settings updated successfully.'
              })
            );
          }}
        >
          <SettingsHeader
            header="Publisher Info"
            description="Tell us a bit about your publication."
          >
            {isColumnUser(user) && (
              <div className="mr-3">
                <ColumnButton
                  startIcon={<DocumentTextIcon className="w-5 h-5" />}
                  onClick={() => setShowNotes(true)}
                  buttonText={
                    numberOfActiveNotes
                      ? `Show Notes (${numberOfActiveNotes})`
                      : 'Show Notes'
                  }
                  id="show-notes"
                  type="button"
                />
              </div>
            )}
            <ColumnButton
              primary
              disabled={!edited || saving}
              buttonText="Save Changes"
              type="submit"
              id="submit"
            />
          </SettingsHeader>
          <PublisherInfo value={orgData} onChange={setOrgData} />
          {userIsSuper(user) && (
            <ImplementationInfo value={orgData} onChange={setOrgData} />
          )}
        </Form>
        {isAdjudicationAreaEnabled && <AdjudicationInfo />}
      </SettingsPage>
      {showNotes && publisherNotesQuery && (
        <PublisherInfoDrawer
          activeOrganization={activeOrganization}
          publisherNotes={publisherNotesQuery?.docs}
          onClose={() => setShowNotes(false)}
        />
      )}
    </>
  );
}
