import { EnumInputItem } from '../types/enums';

export type OrganizationStatusItemType = EnumInputItem<{
  description: string;
}>;

export const OrganizationStatusData = {
  live: {
    value: 1,
    label: 'Live',
    description: 'This newspaper is live and accepting notices',
    key: 'live'
  },
  in_implementation: {
    value: 2,
    label: 'In Implementation',
    description: 'This newspaper is onboarding, and not yet live with Column',
    key: 'in_implementation'
  },
  state_site_uploader: {
    value: 3,
    label: 'State Site Uploader',
    description: 'This newspaper uses Column to upload state site notices',
    key: 'state_site_uploader'
  },
  express: {
    value: 4,
    label: 'Express',
    description: 'This newspaper accepts notices via Column Express',
    key: 'express'
  },
  disengaged: {
    value: 5,
    label: 'Disengaged',
    description: 'This newspaper does not use Column to process public notices',
    key: 'disengaged'
  },
  not_applicable: {
    value: 6,
    label: 'Not Applicable',
    description: 'This organization is not a newspaper',
    key: 'not_applicable'
  },
  placement_only: {
    value: 7,
    label: 'Placement Only',
    description:
      'This newspaper only places notices on behalf of customers. Self-serve is disabled.',
    key: 'placement_only'
  }
};

export type OrganizationStatusItems = typeof OrganizationStatusData;
