import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { EOrganization, ESnapshotExists } from 'lib/types';
import moment from 'moment';
import React, { useCallback } from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { PublishingSettingModel } from 'lib/model/objects/publishingSettingModel';
import { shouldDisablePublishingDate } from '../helpers/publishingDates';
import RemovableDate from './RemovableDate';

const dateToFormattedString = (date: Date) => moment(date).format('YYYY-MM-DD');

type RemovableDateEntryProps = {
  date: string;
  selectedDates: string[];
  onChange: (date?: Date) => void;
  publishingSetting: PublishingSettingModel;
  newspaper: ESnapshotExists<EOrganization>;
  disabled?: boolean;
};

function RemovableDateEntry({
  date,
  selectedDates,
  onChange,
  publishingSetting,
  newspaper,
  disabled
}: RemovableDateEntryProps) {
  const shouldDisableDate = useCallback(
    (pickableDate: MaterialUiPickersDate) => {
      if (!pickableDate) {
        return false;
      }

      const stringFormattedDate = dateToFormattedString(pickableDate);

      // Don't disable the currently selected date
      if (stringFormattedDate === date) {
        return false;
      }
      return (
        shouldDisablePublishingDate({
          day: pickableDate,
          publishingSetting,
          newspaper
        }) || selectedDates.includes(stringFormattedDate)
      );
    },
    [date, selectedDates, publishingSetting]
  );

  return (
    <RemovableDate
      publicationDate={date}
      onRowDateChange={onChange}
      onRemoveRowClick={onChange}
      showDelete={selectedDates.length > 1}
      shouldDisableDate={shouldDisableDate}
      disabled={disabled}
    />
  );
}

type MultiDateSelectorProps = {
  selectedDates: string[];
  onSelectedDatesChange: React.Dispatch<React.SetStateAction<string[]>>;
  publishingSetting: PublishingSettingModel;
  newspaper: ESnapshotExists<EOrganization>;
  onAddMoreDatesClick: () => void;
  disabled?: boolean;
};

function MultiDateSelector({
  selectedDates,
  onSelectedDatesChange,
  publishingSetting,
  newspaper,
  onAddMoreDatesClick,
  disabled
}: MultiDateSelectorProps) {
  const handleSelectedDateChange = (date: Date, index: number) => {
    const updatedDates = [...selectedDates];
    updatedDates[index] = dateToFormattedString(date);
    onSelectedDatesChange(updatedDates.sort());
  };

  const handleRemoveSelectedDate = (index: number) => {
    const updatedDates = [...selectedDates];
    updatedDates.splice(index, 1);
    onSelectedDatesChange(updatedDates);
  };

  const spanClass = 'col-span-2 lg:col-span-1';

  return (
    <>
      {selectedDates.map((date, index) => (
        <div className={spanClass} key={index}>
          <RemovableDateEntry
            date={date}
            onChange={(newDate: Date | undefined) => {
              if (newDate instanceof Date) {
                handleSelectedDateChange(newDate, index);
              } else {
                handleRemoveSelectedDate(index);
              }
            }}
            selectedDates={selectedDates}
            publishingSetting={publishingSetting}
            newspaper={newspaper}
            disabled={disabled}
          />
        </div>
      ))}

      <div className={spanClass}>
        <ColumnButton
          type="button"
          onClick={onAddMoreDatesClick}
          secondary
          fullWidth
          startIcon={<PlusCircleIcon className="w-5 h-5" />}
          buttonText="Add another publication date"
          disabled={disabled}
        />
      </div>
    </>
  );
}

export default MultiDateSelector;
