import classNames from 'classnames';

// Checkboxes are usually used for multi-select applications
// Radios are round and used for single-select applications
type CheckboxInputType = 'checkbox' | 'radio';
type CheckboxInputPosition = 'left' | 'right';

type OptionTextProps = {
  disabled?: boolean;
  checked: boolean;
  labelText: string;
  labelDescription?: string;
};

type InputInnerProps = {
  disabled?: boolean;
  id: string;
  type?: CheckboxInputType;
  checked: boolean;
  onChange: (change: boolean) => void;
  prefix?: React.ReactNode;
};

type WithTextClasses<T> = T & { textClasses: string };

export type CheckboxInputProps = {
  inputPosition?: CheckboxInputPosition;
} & InputInnerProps &
  OptionTextProps;

function OptionText({
  disabled,
  checked,
  labelText,
  labelDescription,
  textClasses
}: WithTextClasses<OptionTextProps>) {
  const titleClasses = classNames({
    'text-primary-500': checked && !disabled,
    'text-primary-400': checked && disabled,
    'text-column-gray-600': !checked && !disabled,
    'text-column-gray-500': !checked && disabled
  });

  if (!labelDescription) {
    return <span className={textClasses}>{labelText}</span>;
  }

  return (
    <div>
      <p className={titleClasses}>{labelText}</p>
      <p className={`text-column-gray-400 text-xs font-normal`}>
        {labelDescription}
      </p>
    </div>
  );
}

function InputInner({
  disabled,
  id,
  type,
  checked,
  onChange,
  textClasses
}: WithTextClasses<InputInnerProps>) {
  return (
    <input
      disabled={disabled}
      id={id}
      type={type}
      onChange={() => onChange(!checked)}
      checked={checked}
      className={classNames('form-checkbox h-4 w-4', textClasses, {
        'rounded-full': type === 'radio'
      })}
    />
  );
}

export default function CheckboxInput({
  disabled,
  checked,
  onChange,
  labelText,
  labelDescription,
  id,
  type = 'checkbox',
  inputPosition = 'right',
  prefix
}: CheckboxInputProps) {
  const textClasses = classNames(`transition duration-150 ease-in-out`, {
    'text-primary-500': checked && !disabled,
    'text-primary-400': checked && disabled,
    'text-column-gray-400': !checked && !disabled,
    'text-column-gray-300': !checked && disabled,
    'cursor-pointer': !disabled,
    'cursor-not-allowed': disabled
  });

  const labelClasses = classNames(
    `flex gap-4 w-full bg-white border rounded-md items-center py-3 px-4 text-sm font-medium`,
    {
      'border-primary-500': checked && !disabled,
      'border-primary-400': checked && disabled,
      'border-column-gray-200': !checked && !disabled,
      'border-column-gray-300': !checked && disabled,
      'cursor-pointer': !disabled,
      'cursor-not-allowed bg-column-gray-25': disabled
    },
    textClasses
  );
  const wrapperClasses = 'flex-1 flex justify-between items-center gap-1';

  const input = (
    <InputInner
      disabled={disabled}
      id={id}
      type={type}
      checked={checked}
      onChange={onChange}
      textClasses={textClasses}
    />
  );

  if (!labelText) {
    return input;
  }

  const label = (
    <OptionText
      disabled={disabled}
      checked={checked}
      labelText={labelText}
      labelDescription={labelDescription}
      textClasses={textClasses}
    />
  );

  return (
    <label className={labelClasses} htmlFor={id}>
      {prefix}
      <div className={wrapperClasses}>
        {inputPosition === 'right' && label}
        {input}
        {inputPosition === 'left' && label}
      </div>
    </label>
  );
}
