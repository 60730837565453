import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import {
  ValidationMessageConfig,
  getCustomValidationMessage,
  useValidationEventListener
} from '../../helpers/inputValidation';

type UseValidationParams = {
  value?: string;
  inputRef: React.MutableRefObject<
    HTMLInputElement | HTMLTextAreaElement | null
  >;
  errorText?: string;
  validationMessages?: ValidationMessageConfig;
  setValidityTracker?: Dispatch<SetStateAction<boolean>>;
  setShowErrors: (showErrors: boolean) => void;
};

export function useValidationChecks({
  value,
  inputRef,
  errorText,
  validationMessages,
  setValidityTracker,
  setShowErrors
}: UseValidationParams) {
  const [
    currentValidationMessage,
    setCurrentValidationMessage
  ] = useState<string>();

  useEffect(() => {
    setCurrentValidationMessage(
      getValidationMessage(inputRef, validationMessages)
    );
  }, [value, Boolean(inputRef.current), validationMessages]);

  useEffect(() => {
    if (setValidityTracker) {
      setValidityTracker(!currentValidationMessage);
    }

    if (!currentValidationMessage && !errorText) {
      setShowErrors(false);
    }
  }, [currentValidationMessage, value, errorText]);

  const onValidationEvent = useCallback(() => setShowErrors(true), [
    setShowErrors
  ]);

  useValidationEventListener({ onValidationEvent, inputRef });

  return { currentValidationMessage };
}

function getValidationMessage(
  inputRef: React.MutableRefObject<
    HTMLInputElement | HTMLTextAreaElement | null
  >,
  validationMessages?: ValidationMessageConfig
) {
  if (inputRef?.current && !inputRef.current.validity.valid) {
    const customValidationMessage = validationMessages
      ? getCustomValidationMessage(
          inputRef.current.validity,
          validationMessages
        )
      : null;

    const nativeValidationMessage = inputRef.current.validationMessage;

    return customValidationMessage || nativeValidationMessage;
  }
}
