import classNames from 'classnames';
import LinkTo from 'components/LinkTo';

type HeaderNavBarLinkProps = {
  label: string;
  path: string;
  id: string;
};

/**
 * Navbar link in the header to items like notices, invoices, etc.
 */
export default function HeaderNavBarLink({
  label,
  path,
  id
}: HeaderNavBarLinkProps) {
  const isActive = window.location.pathname.startsWith(path.split('?')[0]);

  return (
    <LinkTo
      id={id}
      href={path}
      className={classNames(
        'flex font-medium h-full p-3 cursor-pointer hover:bg-primary-50 hover:text-primary-500 rounded-md',
        {
          'text-column-gray-400': !isActive,
          'text-primary-500': isActive
        }
      )}
    >
      <span className="ml-2">{label}</span>
    </LinkTo>
  );
}
