/**
 * Checks if two strings are very similar, ignoring casing and non-word characters.
 */
export const fuzzyStringMatch = (a: string, b: string) => {
  const aSimplified = a
    .toLocaleLowerCase()
    .trim()
    .replace(/[^a-z0-9\s]/gi, '')
    .replace(/\s/g, '');

  const bSimplified = b
    .toLocaleLowerCase()
    .trim()
    .replace(/[^a-z0-9\s]/gi, '')
    .replace(/\s/g, '');

  return aSimplified === bSimplified;
};

/**
 * Checks if one string contains (or nearly contains another), ignoring casing and non-word characters.
 */
export const fuzzyStringContains = (a: string, b: string) => {
  const aSimplified = a
    .toLocaleLowerCase()
    .trim()
    .replace(/[^a-z0-9\s]/gi, '')
    .replace(/\s/g, '');

  const bSimplified = b
    .toLocaleLowerCase()
    .trim()
    .replace(/[^a-z0-9\s]/gi, '')
    .replace(/\s/g, '');

  return aSimplified.indexOf(bSimplified) >= 0;
};

export const capitalizeWord = (word: string) => {
  return word.charAt(0).toLocaleUpperCase() + word.substring(1);
};

export const capitalizeWords = (words: string) => {
  const individualWords = words.toLowerCase().split(' ');
  return individualWords.map(capitalizeWord).join(' ');
};

export const formatPhoneNumber = (
  phone: string | undefined,
  { digitsOnly = false }: { digitsOnly?: boolean } = {}
) => {
  if (!phone) {
    return '';
  }

  const digits = phone.replace(/\D/g, '');

  if (digits.length < 10) {
    return '';
  }

  if (digitsOnly) {
    return digits;
  }

  return digits.slice(0, 10).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};
