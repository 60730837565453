import { useEffect, useState } from 'react';

import { EOrganization, ERate, ERef, ESnapshotExists, exists } from 'lib/types';
import { logAndCaptureException } from 'utils';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { getFirebaseContext } from 'utils/firebase';

import {
  isColumnUser,
  removeUndefinedFields,
  replaceUndefinedWithDelete
} from 'lib/helpers';
import FullScreenModal from 'components/FullScreenModal';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import { userHasPermission } from 'utils/permissions';
import { Permissions } from 'lib/permissions/roles';
import {
  AdRate,
  FlatAdditionalFee,
  PercentAdditionalFee,
  isFlatAdditionalFee,
  isNoticeRate,
  isPercentAdditionalFee
} from 'lib/types/rates';
import { PublisherOrgSharingCard } from 'routes/settings/publisher/sharing/PublisherOrgSharingCard';
import { RateType } from 'lib/enums';
import { ColumnService } from 'lib/services/directory';
import { rateTypeSupportsDisplay } from '../ratesTableSettingsUtils';
import BasicPropertiesCard from './BasicPropertiesCard';
import AdditionalFeesCard from './AdditionalFeesCard';
import {
  getDefaultBehaviorFromNewspaperAndRateData,
  DEFAULT_RATE_BEHAVIOR
} from './rateUpdateFormUtils';
import AdvancedSettingsCard from './AdvancedSettingsCard';
import AdminSettingsCard from './AdminSettingsCard';

function rateUpdatesAreValid(rate: AdRate) {
  const baseRates = [rate.minimum, rate.rate_0, rate.rate_1, rate.rate_2];
  const additionalRates = [...Object.values(rate.additionalRates || [])];
  const flatAdditionalFees = (rate.additionalFees || [])
    .filter((fee): fee is FlatAdditionalFee => isFlatAdditionalFee(fee))
    .map(fee => fee.amount);
  const percentAdditionalFees = (rate.additionalFees || [])
    .filter((fee): fee is PercentAdditionalFee => isPercentAdditionalFee(fee))
    .map(fee => fee.feePercentage);

  const flatAmountsAreValid = [
    ...baseRates,
    ...additionalRates,
    ...flatAdditionalFees
  ].every(n => n === undefined || n === null || n >= 0);
  const percentAmountsAreValid = percentAdditionalFees.every(
    n => n === undefined || n === null || n > 0
  );

  return flatAmountsAreValid && percentAmountsAreValid;
}

type RateUpdateFormProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  closeForm: () => void;
  rateData: AdRate | ERate;
  rateRef?: ERef<AdRate> | ERef<ERate>;
};

/**
 * Full-screen form to update properties associated with a rate
 */
export default function RateUpdateForm({
  activeOrganization,
  closeForm,
  rateData,
  rateRef
}: RateUpdateFormProps) {
  const user = useAppSelector(selectUser);

  const [updatedRateData, setUpdatedRateData] = useState(rateData);
  const [updating, setUpdating] = useState(false);

  const initialDefaultBehavior = getDefaultBehaviorFromNewspaperAndRateData(
    activeOrganization,
    rateRef?.id
  );
  const [defaultBehavior, setDefaultBehavior] = useState<DEFAULT_RATE_BEHAVIOR>(
    initialDefaultBehavior
  );

  const isEditingNoticeRate = isNoticeRate(updatedRateData);

  const edited =
    !columnObjectsAreEqual(updatedRateData, rateData) ||
    defaultBehavior !== initialDefaultBehavior;

  const updateRateProperties = async () => {
    setUpdating(true);
    try {
      let rateRefToUpdate = rateRef;
      // this runs if we are creating a new rate
      if (!rateRefToUpdate) {
        rateRefToUpdate = await getFirebaseContext()
          .adRatesRef()
          .add(
            removeUndefinedFields({
              ...updatedRateData
            })
          );
      }

      // determine if we need to update the default rates
      if (isEditingNoticeRate && defaultBehavior !== initialDefaultBehavior) {
        const defaultRate = rateRefToUpdate as ERef<ERate>;
        if (defaultBehavior === 'both') {
          await activeOrganization.ref.update({
            defaultDisplayRate: defaultRate,
            defaultLinerRate: defaultRate
          });
        } else if (defaultBehavior === 'display') {
          await activeOrganization.ref.update({
            defaultDisplayRate: defaultRate
          });
        } else if (defaultBehavior === 'liner') {
          await activeOrganization.ref.update({
            defaultLinerRate: defaultRate
          });
        }
      }

      await rateRefToUpdate.update(
        replaceUndefinedWithDelete(getFirebaseContext(), updatedRateData)
      );
      closeForm();
    } catch (err) {
      logAndCaptureException(
        ColumnService.WEB_PLACEMENT,
        err,
        'Unable to update rate properties',
        {
          rateId: rateRef?.id
        }
      );
    }
    setUpdating(false);
  };

  // Automatically turn off supporting display if the rate type is line-based
  useEffect(() => {
    if (!rateTypeSupportsDisplay(updatedRateData.rateType)) {
      setUpdatedRateData({
        ...updatedRateData,
        supportsDisplay: false
      });
    }
    // Default hide pricing for iowa forms as they need to be
    // manually priced during invoice creation
    if (updatedRateData.rateType === RateType.iowa_form.value) {
      setUpdatedRateData({
        ...updatedRateData,
        hidePreview: true
      });
    }
  }, [updatedRateData.rateType]);

  // Enable saving instantly for new rates, and after edit for existing rates
  const disableSave =
    !updatedRateData.description ||
    (rateRef && (updating || !edited)) ||
    !rateUpdatesAreValid(updatedRateData);

  return (
    <FullScreenModal
      submittable={{
        buttonText: rateRef ? 'Save' : 'Create Rate',
        disabled: !!disableSave,
        onSubmit: updateRateProperties
      }}
      onClose={closeForm}
      headerText={rateRef ? 'Edit Rate' : 'New Rate'}
      id="rate-update-form"
    >
      <BasicPropertiesCard
        activeOrganization={activeOrganization}
        setDefaultBehavior={setDefaultBehavior}
        onUpdateRateData={setUpdatedRateData}
        updatedRateData={updatedRateData}
        defaultBehavior={defaultBehavior}
        rateRef={rateRef}
      />
      <AdditionalFeesCard
        setUpdatedRateData={setUpdatedRateData}
        updatedRateData={updatedRateData}
        rateData={updatedRateData}
      />
      <AdvancedSettingsCard
        onUpdateRateData={setUpdatedRateData}
        updatedRateData={updatedRateData}
        initialRateData={rateData}
      />

      {exists(user) && isColumnUser(user) && (
        <PublisherOrgSharingCard
          resourceNoun="rate"
          value={updatedRateData.publisherOrganizations ?? []}
          owner={updatedRateData.organization}
          onChange={value => {
            setUpdatedRateData({
              ...updatedRateData,
              publisherOrganizations: value
            });
          }}
        />
      )}

      {exists(user) &&
        userHasPermission(user, Permissions.SETTINGS_RATES_ADMIN) && (
          <AdminSettingsCard
            onChange={newVals =>
              setUpdatedRateData({
                ...updatedRateData,
                ...newVals
              })
            }
            value={updatedRateData}
            isEditingNoticeRate={isEditingNoticeRate}
          />
        )}
    </FullScreenModal>
  );
}
