import React, { useState } from 'react';
import { WrenchScrewdriverIcon } from '@heroicons/react/24/outline';

import { PublicationIssueModel } from 'lib/model/objects/publicationIssueModel';
import { getOrThrow } from 'lib/utils/refs';
import {
  PRODUCTION_PROJECT,
  FIREBASE_PROJECT,
  DEMO_PROJECT
} from 'lib/constants';
import ClickableIconWrapper from 'lib/components/TableLayout/ClickableIconWrapper';

const getAdminPageURL = () => {
  if (FIREBASE_PROJECT === PRODUCTION_PROJECT) {
    return 'https://retoo.lol';
  }
  if (FIREBASE_PROJECT === DEMO_PROJECT) {
    return 'https://column-admin-demo.web.app';
  }
  return 'http://localhost:3001';
};

type BuildPaginationFilesButtonProps = {
  issue: PublicationIssueModel;
  /**
   * TODO(APP-3503): remove when we enable pagination builder for orders
   */
  disabled: boolean;
};

export function BuildPaginationFilesButton({
  issue,
  disabled
}: BuildPaginationFilesButtonProps) {
  const [loading, setLoading] = useState(false);

  const loadPaginationFiles = async () => {
    setLoading(true);
    const publisher = await getOrThrow(issue.modelData.publisher);
    const adTemplate = await getOrThrow(publisher.data().adTemplate);
    const {
      response: notices,
      error: noticeError
    } = await issue.slowGetNoticesForPublicationIssue();
    if (noticeError) {
      console.error('Error getting notices for pagination', noticeError);
    } else {
      const paginationUrl = `${getAdminPageURL()}/pagination-builder?templateID=${
        adTemplate.id
      }&noticeIDs=${notices.map(n => n.id).join(',')}&issueID=${issue.id}`;
      window.open(paginationUrl, '_blank');
    }
    setLoading(false);
  };

  return (
    <ClickableIconWrapper
      id={`pagination-builder-${issue.id}`}
      loading={loading}
      icon={<WrenchScrewdriverIcon className="h-5 w-5" />}
      onClick={loadPaginationFiles}
      disabled={disabled}
    />
  );
}
