import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  BuildingOffice2Icon,
  ChevronDownIcon,
  PlusCircleIcon,
  MagnifyingGlassIcon
} from '@heroicons/react/24/outline';
import { EOrganization, ESnapshotExists, exists } from 'lib/types';
import { Popover, PopoverContext } from 'lib/components/Popover';

import { useAppSelector } from 'redux/hooks';
import { ColumnButton } from 'lib/components/ColumnButton';
import { selectShowAllOrgsNotices } from 'redux/auth';
import { buildingStyles } from 'lib/components/UserIDCard';
import { TextField } from 'lib/components/TextField';
import { fuzzyStringContains } from 'lib/utils/strings';

/**
 * Runs through a series of formatting rules to make the publisher name look nice.
 * @param {ESnapshotExists<EOrganization>} organization name of the publisher
 * @returns {string} cleaned name
 */
export const getPublisherNameForDropdown = (
  organization: ESnapshotExists<EOrganization>
) => {
  const publisherName = organization.data().name;
  if (publisherName.startsWith('The ')) {
    return publisherName.replace('The ', '');
  }
  return publisherName;
};

type OrganizationSelectPopoverItemProps = {
  id: string;
  onClick: () => void;
  iconStyles: string;
  active: boolean;
  icon: React.ReactNode;
  name: React.ReactNode;
};
function OrganizationSelectPopoverItem({
  iconStyles,
  onClick,
  active,
  icon,
  name,
  id
}: OrganizationSelectPopoverItemProps) {
  return (
    <button
      id={id}
      className="p-2 hover:bg-column-gray-25 cursor-pointer rounded-md flex items-center"
      onClick={onClick}
    >
      <div className="w-12 flex">
        <div
          className={classNames(
            'w-10 h-10 rounded-full flex items-center justify-center',
            iconStyles
          )}
        >
          {icon}
        </div>
        {active && (
          <div className="-ml-2 mt-6 rounded-full bg-column-green-400 w-3 h-3 border border-white" />
        )}
      </div>
      <span className="ml-2 truncate font-medium text-sm text-column-gray-500">
        {name}
      </span>
    </button>
  );
}

type ActiveOrganizationPopoverProps = {
  options: ESnapshotExists<EOrganization>[];
  value: ESnapshotExists<EOrganization> | undefined;
  onChange: (org: ESnapshotExists<EOrganization>) => unknown;
  onAllOrgsClicked?: () => unknown;
  onCreateOrgClicked?: () => unknown;
  showAllOrgsOption?: boolean;
  showCreateOrgOption?: boolean;
};

function ActiveOrganizationPopover({
  options,
  value,
  onChange,
  onAllOrgsClicked,
  onCreateOrgClicked,
  showAllOrgsOption,
  showCreateOrgOption
}: ActiveOrganizationPopoverProps) {
  const initialSearchOrgState = '';
  // Limit of the number of organizations where the search organization field will not appear
  const organizationLimit = 3;
  const { setOpen, open } = useContext(PopoverContext);
  const [searchOrgValue, setSearchOrgValue] = useState(initialSearchOrgState);

  useEffect(() => {
    // On closing of popover if there is some value in search field; it should reset to empty state
    if (!open && searchOrgValue) {
      setSearchOrgValue(initialSearchOrgState);
    }
  }, [searchOrgValue, open]);

  const showAllOrgsNotices = useAppSelector(selectShowAllOrgsNotices) || false;
  const filteredOrgs = options.filter(org => {
    return fuzzyStringContains(
      getPublisherNameForDropdown(org),
      searchOrgValue
    );
  });

  return (
    <>
      {options.length > organizationLimit && (
        <OrganizationSearchBox
          searchOrgValue={searchOrgValue}
          onChangeSearchOrgValue={setSearchOrgValue}
        />
      )}
      {!filteredOrgs.length && (
        <div className="w-72 text-center text-column-gray-300 pb-3">
          No organization found.
        </div>
      )}
      {filteredOrgs.length > 0 && (
        <div className="w-72 p-2 max-h-64 overflow-y-scroll">
          {/* All Organizations option moved to top for easy access */}
          {showAllOrgsOption && filteredOrgs.length > 1 && (
            <OrganizationSelectPopoverItem
              id="organization-select-all"
              onClick={() => {
                setOpen(false);
                if (onAllOrgsClicked) {
                  onAllOrgsClicked();
                }
              }}
              active={Boolean(showAllOrgsNotices)}
              iconStyles={ACTIVE_ICON_STYLES}
              icon={<BuildingOffice2Icon className={classNames('w-5 h-5')} />}
              name="All Organizations"
            />
          )}

          {filteredOrgs
            .sort((a, b) =>
              getPublisherNameForDropdown(a).localeCompare(
                getPublisherNameForDropdown(b)
              )
            )
            .map((organization, index) => {
              return (
                <OrganizationSelectPopoverItem
                  id={`organization-select-${organization.id.slice(0, 10)}`}
                  onClick={() => {
                    setOpen(false);
                    onChange(organization);
                  }}
                  iconStyles={buildingStyles[index % buildingStyles.length]}
                  active={!showAllOrgsNotices && value?.id === organization.id}
                  name={getPublisherNameForDropdown(organization)}
                  key={organization.id}
                  icon={
                    <BuildingOffice2Icon className={classNames('w-5 h-5')} />
                  }
                />
              );
            })}

          {showCreateOrgOption && (
            <OrganizationSelectPopoverItem
              id="organization-select-add-organization"
              onClick={() => {
                setOpen(false);
                if (onCreateOrgClicked) {
                  onCreateOrgClicked();
                }
              }}
              iconStyles="text-column-primary-500 bg-column-primary-50"
              name={
                <span className="text-column-primary-500">
                  Add Organization
                </span>
              }
              active={false}
              icon={<PlusCircleIcon className={classNames('w-5 h-5')} />}
            />
          )}
        </div>
      )}
    </>
  );
}

type OrganizationSearchBoxProps = {
  searchOrgValue: string;
  onChangeSearchOrgValue: (value: string) => void;
};

// Search component in the organization select dropdown
function OrganizationSearchBox({
  searchOrgValue,
  onChangeSearchOrgValue
}: OrganizationSearchBoxProps) {
  return (
    <div className="mx-4 my-3">
      <TextField
        type="text"
        id="organization-search"
        labelText=""
        placeholder="Search"
        value={searchOrgValue}
        onChange={value => onChangeSearchOrgValue(value)}
        prefix={
          <MagnifyingGlassIcon className="h-5 w-5 text-column-gray-300 stroke-2" />
        }
      />
    </div>
  );
}

// choose to make the active icon the red icon
const ACTIVE_ICON_STYLES = buildingStyles[2];

// Most of the props are passed down to ActiveOrganizationPopover so they
// share a type
type OrganizationSelectProps = ActiveOrganizationPopoverProps & {
  popoverHeader?: string;
};

/**
 * Dropdown select component in the header bar to switch between organizations.
 */
export default function OrganizationSelect({
  options,
  value,
  onChange,
  onAllOrgsClicked,
  onCreateOrgClicked,
  popoverHeader,
  showAllOrgsOption,
  showCreateOrgOption
}: OrganizationSelectProps) {
  const showAllOrgsNotices =
    useAppSelector(selectShowAllOrgsNotices) && !!showAllOrgsOption;

  const activatorButtonText = exists(value)
    ? showAllOrgsNotices
      ? 'All Organizations'
      : getPublisherNameForDropdown(value)
    : 'Select organization';

  return (
    <>
      <div className="hidden md:flex">
        <Popover
          id="organization-select-popover"
          headerText={popoverHeader}
          activator={
            <ColumnButton
              buttonText={activatorButtonText}
              startIcon={
                <BuildingOffice2Icon
                  className={classNames('w-5 h-5', ACTIVE_ICON_STYLES)}
                />
              }
              fullWidth
              alignment="between"
              endIcon={<ChevronDownIcon className="w-4 h-4" />}
              type="button"
            />
          }
          alignment="left"
        >
          <ActiveOrganizationPopover
            options={options}
            value={value}
            onChange={onChange}
            showAllOrgsOption={showAllOrgsOption}
            onAllOrgsClicked={onAllOrgsClicked}
            showCreateOrgOption={showCreateOrgOption}
            onCreateOrgClicked={onCreateOrgClicked}
          />
        </Popover>
      </div>
      <div className="md:hidden">
        <Popover
          id="organization-select-popover"
          headerText={popoverHeader}
          activator={
            <button className="bg-red-50 rounded-full p-2 md:hidden">
              <BuildingOffice2Icon
                className={classNames('w-4 h-4', ACTIVE_ICON_STYLES)}
              />
              <div className="bg-column-green-500 w-3 h-3 absolute ml-4 -mt-.5 rounded-full border-white border-2" />
            </button>
          }
          alignment="right"
        >
          <ActiveOrganizationPopover
            options={options}
            value={value}
            onChange={onChange}
            showAllOrgsOption={showAllOrgsOption}
            onAllOrgsClicked={onAllOrgsClicked}
            showCreateOrgOption={showCreateOrgOption}
            onCreateOrgClicked={onCreateOrgClicked}
          />
        </Popover>
      </div>
    </>
  );
}
