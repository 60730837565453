import { useState } from 'react';
import { CouponModel } from 'lib/model/objects/couponModel';
import api from 'api';
import { logAndCaptureCriticalError } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { OrderModel } from 'lib/model/objects/orderModel';
import { useLoading } from 'lib/components/hooks/useLoading';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';

type UseCouponsProps = {
  orderModel: OrderModel;
  version: number;
  onCouponUpdate: () => Promise<void>;
  shouldDelayFetchCoupons: boolean;
};

export const useCoupons = ({
  orderModel,
  version,
  onCouponUpdate,
  shouldDelayFetchCoupons
}: UseCouponsProps) => {
  const [error, setError] = useState('');
  const [loading, runWithLoading] = useLoading();

  const {
    value: coupons,
    isLoading: couponLoading,
    invalidateData
  } = useAsyncEffect<CouponModel[]>({
    fetchData: async () => {
      const {
        response: orderDetail,
        error: orderDetailError
      } = await orderModel.getOrCreateOrderDetail(version);
      if (orderDetailError) {
        setError('Failed to fetch order detail');
        throw orderDetailError;
      }
      const {
        response: fetchedCoupons,
        error: couponFetchError
      } = await orderDetail.fetchAppliedCoupons();
      if (couponFetchError) {
        setError('Failed to fetch applied discount codes');
        throw couponFetchError;
      }
      return fetchedCoupons;
    },
    dependencies: [orderModel.id, version, shouldDelayFetchCoupons],
    initialData: null,
    errorConfig: {
      service: ColumnService.PAYMENTS,
      message: 'Error fetching coupons'
    }
  });

  const addCoupon = async (couponCode: string) => {
    await runWithLoading(async () => {
      setError('');
      if (!couponCode || couponCode === '') {
        setError('Please enter a discount code');
        return;
      }
      if (coupons?.some(coupon => coupon.modelData.code === couponCode)) {
        setError('Discount code has already been applied');
        return;
      }
      const { error: couponError } = await api.safePost('orders/apply-coupon', {
        couponCode,
        orderId: orderModel.id,
        version
      });
      if (couponError) {
        if (couponError === 'Coupon not found') {
          setError(`Discount code '${couponCode}' not found`);
          return;
        }
        logAndCaptureCriticalError(
          ColumnService.PAYMENTS,
          couponError,
          'Error applying coupon',
          { couponCode, orderId: orderModel.id, version: version.toString() }
        );
        setError('Failed to apply discount code. Please try again.');
        return;
      }
      invalidateData();
      await onCouponUpdate();
    });
  };

  const removeCoupon = async (couponCode: string) => {
    await runWithLoading(async () => {
      setError('');
      const { error: couponError } = await api.safePost(
        'orders/remove-coupon',
        {
          couponCode,
          orderId: orderModel.id,
          version
        }
      );
      if (couponError) {
        logAndCaptureCriticalError(
          ColumnService.PAYMENTS,
          couponError,
          'Error removing coupon',
          { couponCode, orderId: orderModel.id, version: version.toString() }
        );
        setError('Failed to remove discount code. Please try again.');
        return;
      }
      invalidateData();
      await onCouponUpdate();
    });
  };

  return {
    coupons,
    addCoupon,
    removeCoupon,
    loading: couponLoading || loading,
    error
  };
};
