import LoadingState from 'components/LoadingState';
import { Alert } from 'lib/components/Alert';
import AuthActions, { selectAuthLoading, selectUser } from 'redux/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { Product } from 'lib/enums';
import { useState } from 'react';
import { apiPost } from 'api/typed';
import { getLoadOrderEmail, loadOrderItems } from './helpers/orderOperations';
import PlacementFlowStepSelector from './PlacementFlowStepSelector';
import { PlacementFlowStep } from './placementFlowStep';
import { useOrderEditableData } from './hooks/useOrderEditableData';

const steps = [
  PlacementFlowStep.Schedule,
  PlacementFlowStep.Content,
  PlacementFlowStep.Summary
];

type OrderEditFlowProps = {
  claim: string;
  orderId: string;
  product: Product;
};

export default function OrderEditFlow({
  claim,
  orderId,
  product
}: OrderEditFlowProps) {
  const params = getLocationParams();
  const dispatch = useAppDispatch();
  const userSnap = useAppSelector(selectUser);
  const authIsLoading = useAppSelector(selectAuthLoading);
  const version = parseInt(getLocationParams().get('version') || '', 10);
  const accessCode = params.get('accessCode');
  const authEmail = getLoadOrderEmail();
  const isAnonymousFlow =
    !userSnap && !authIsLoading && !!accessCode && !!authEmail;
  const [reauthorized, setReauthorized] = useState(false);
  const shouldReauthorize = isAnonymousFlow && !reauthorized;

  const reauthorizeUserWithEditClaims = async () => {
    if (!shouldReauthorize) {
      return;
    }

    const accessResult = await apiPost('orders/grant-access', {
      accessCode,
      authEmail,
      forFuneralHomeVerification: false,
      editVersion: version
    });
    if (!accessResult.success) {
      throw Error(accessResult.error);
    }

    const { token } = accessResult;
    dispatch(AuthActions.loginToken(token));
  };

  const { value, isError: failedToLoad } = useAsyncEffect({
    fetchData: async () => {
      if (shouldReauthorize) {
        await reauthorizeUserWithEditClaims();
        setReauthorized(true);
        return null;
      }

      if (!orderId || !version) {
        return null;
      }

      return await loadOrderItems(orderId, version);
    },
    errorConfig: {
      service: ColumnService.OBITS,
      message: 'Failed to retrieve order model',
      tags: {
        orderId,
        claim
      }
    },
    dependencies: [orderId, version, shouldReauthorize]
  });

  const { orderModel, adModel, newspaperOrders } = value ?? {};

  const editData = useOrderEditableData({
    userSnap,
    order: orderModel ?? null,
    newspaperOrders: newspaperOrders ?? []
  });

  if (failedToLoad) {
    return <Alert title="Loading failure" id="loading-failure-error" />;
  }

  if (orderModel && adModel && version) {
    return (
      <PlacementFlowStepSelector
        orderModel={orderModel}
        adModel={adModel}
        draftNewspaperOrders={newspaperOrders}
        steps={steps}
        version={version}
        product={product}
        editData={editData ?? undefined}
      />
    );
  }

  return (
    <LoadingState
      context={{
        service: ColumnService.OBITS,
        location: 'Ad placement - Order edit flow',
        tags: {
          orderId,
          version: version.toString(),
          claim,
          adPlacementFlow: 'true'
        }
      }}
    />
  );
}
