import { Switch, Route } from 'react-router';

import { ConnectedRouter } from 'connected-react-router';
import RegistrationRoute from 'layouts/registrationLayout/RegistrationRoute';
import AddAdvertiserOrganization from 'routes/addAdvertiserOrganization';
import RegisterIndividual from 'routes/register/individual';
import RegisterPublisher from 'routes/register/publisher';
import RegistrationOccupationSelect from 'routes/register/occupations';
import StripeConnect from 'routes/settings/publisher/paymentSettings/StripeConnect';
import {
  INDIVIDUAL_REGISTRATION_ENTRYPOINT,
  POST_ORGANIZATION_REGISTRATION_ENTRYPOINT,
  PAGINATION_TABLE
} from 'redux/router';
import SignInRoute from 'layouts/signInLayout/SignInRoute';
import FuneralHomeVerification from 'routes/verify/FuneralHomeVerification';
import Ads from 'routes/ads';
import {
  selectHasClassifiedsActive,
  selectHasObituariesActive
} from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import AdDetail from 'routes/ads/details/AdDetail';
import { useToggleElementIdForLocationPaths } from 'hooks/useToggleElementIdForLocationPaths';
import { frontIFrameId, pathsToHideFrontChat } from 'utils/front';
import EditOrderContainer from 'routes/ads/place/EditOrderContainer';
import Impersonate from './routes/impersonate';
import ForgotPassword from './routes/forgotPassword';
import LoadingState from './components/LoadingState';
import AppRoute from './layouts/appLayout/AppRoute';
import history from './customHistory';
import Reports from './routes/reports';

import OrganizationSettings from './routes/settings/OrganizationSettings';
import NewspaperTypeForm from './routes/typeform/newspaper';

import RegisterOrganization from './routes/register/organization';
import PostOrgRegistration from './routes/register/organization/PostOrgRegistration';

import Payments from './routes/payments';
import Affidavits from './routes/automatedAffidavits';

import AddNoticeWrapper from './routes/addNotice';
import UserSettings from './routes/settings/userSettings';
import Register from './routes/register/user';
import Invitation from './routes/invites/invitation';
import Login from './routes/login';
import Logout from './routes/logout';
import ErrorPage from './routes/errors/ErrorPage';
import NoticeDetail from './routes/notice';
import CreateInvoice from './routes/notice/billing/CreateInvoice';

import EmailNeedsConfirm from './routes/register/user/EmailNeedsConfirm';
import PayInvoice from './routes/notice/billing/payInvoice/PayInvoice';
import PlaceScroll from './routes/placeScroll';
import SubscriptionManagement from './routes/subscriptions';
import ResetPassword from './routes/resetPassword/index';
import Madlib from './routes/madlib';
import Pagination, { PaginationDocumentation } from './routes/pagination';
import UpdateEmail from './routes/updateEmail/index';
import PlaceAdContainer from './routes/ads/place';

export default function ColumnRouter() {
  const hasClassifiedsActive = useAppSelector(selectHasClassifiedsActive);

  const hasObituariesActive = useAppSelector(selectHasObituariesActive);

  useToggleElementIdForLocationPaths(frontIFrameId, pathsToHideFrontChat);

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <AppRoute
          exact
          path="/(obituaries|classifieds)/place/:orderDraftId?"
          component={PlaceAdContainer}
        />
        <AppRoute
          exact
          path="/(obituaries|classifieds)/edit/:orderId"
          component={EditOrderContainer}
        />
        <AppRoute exact path="/verify/" component={FuneralHomeVerification} />
        <AppRoute exact path="/logout/" component={Logout} />
        <AppRoute exact path="/reports/" component={Reports} />
        <AppRoute exact path="/affidavits/" component={Affidavits} />
        <AppRoute
          exact
          path={PAGINATION_TABLE}
          Documentation={PaginationDocumentation}
          component={Pagination}
        />
        <AppRoute exact path="/place/" hideFrame component={PlaceScroll} />
        <AppRoute exact path="/place/:id/" hideFrame component={PlaceScroll} />
        <AppRoute exact path="/stripe-connect/" component={StripeConnect} />

        <SignInRoute exact path="/login/" component={Login} />
        <SignInRoute exact path="/register/" component={Register} />
        <SignInRoute exact path="/reset-password/" component={ResetPassword} />
        <SignInRoute
          exact
          path="/forgot-password/"
          component={ForgotPassword}
        />
        <SignInRoute exact path="/invites/:id" component={Invitation} />

        <RegistrationRoute
          exact
          path="/register/occupations"
          component={RegistrationOccupationSelect}
        />
        <RegistrationRoute
          exact
          path={POST_ORGANIZATION_REGISTRATION_ENTRYPOINT}
          component={PostOrgRegistration}
        />
        <RegistrationRoute
          exact
          path={INDIVIDUAL_REGISTRATION_ENTRYPOINT}
          component={RegisterIndividual}
        />
        <RegistrationRoute
          exact
          path="/register/organization/"
          component={RegisterOrganization}
        />
        <RegistrationRoute
          exact
          path="/register/publisher/"
          component={RegisterPublisher}
        />
        <RegistrationRoute
          exact
          path="/add-organization/"
          component={AddAdvertiserOrganization}
        />
        <RegistrationRoute
          exact
          path="/register/confirm/"
          component={EmailNeedsConfirm}
        />
        <Route exact path="/update-email/" component={UpdateEmail} />
        <AppRoute exact path="/settings/" component={UserSettings} />
        <AppRoute
          exact
          path="/form/newspaper/:noticeId"
          component={NewspaperTypeForm}
        />
        <AppRoute
          exact
          path="/file/by-type/:type"
          component={AddNoticeWrapper}
        />
        <AppRoute exact path="/file/:id/" component={AddNoticeWrapper} />
        <AppRoute
          exact
          path="/file/:id/:noticeSlug?"
          component={AddNoticeWrapper}
        />
        <AppRoute exact path="/notices/" component={Ads} />
        {hasObituariesActive && (
          <AppRoute exact path="/obituaries/" component={Ads} />
        )}
        {hasClassifiedsActive && (
          <AppRoute exact path="/classifieds/" component={Ads} />
        )}
        <AppRoute
          exact
          path="/settings/organization/"
          component={OrganizationSettings}
        />
        <AppRoute
          exact
          path="/:path(notice|publish)/:id/"
          component={NoticeDetail}
        />
        <AppRoute
          exact
          path="/:path(classified|obituary)/:orderId/"
          component={AdDetail}
        />
        <AppRoute
          exact
          path="/:path(notice|publish)/:id/invoice/create"
          component={CreateInvoice}
        />
        <AppRoute exact path="/payments/" component={Payments} />
        <AppRoute exact path="/error/:code" component={ErrorPage} />
        <AppRoute
          exact
          hideFrame
          path="/invoices/:id/pay/"
          component={PayInvoice}
        />

        <AppRoute exact path="/madlib/" component={Madlib} />

        <AppRoute exact path="/impersonate/" component={Impersonate} />

        <AppRoute
          exact
          path="/subscriptions/"
          component={SubscriptionManagement}
        />

        <Route
          exact
          path="/"
          render={() => <LoadingState context={{ location: 'Router' }} />}
        />
      </Switch>
    </ConnectedRouter>
  );
}
