import React, { useEffect, useState } from 'react';
import { push, Push } from 'connected-react-router';
import FreeformCModal from 'components/modals/FreeFormCModal';
import CButton from 'components/CButton';
import { connect } from 'react-redux';
import api from 'api';
import { PasswordField } from 'components/PasswordField';
import Firebase from 'EnoticeFirebase';
import { EResponseTypes } from 'lib/types';
import {
  dwollaInvalidPasswordMessage,
  dwollaPasswordValidation,
  passwordValidation
} from 'lib/passwordValidators';
import ToastActions from 'redux/toast';
import { useAppDispatch } from 'redux/hooks';

type ResetPasswordProps = {
  push: Push;
};

function ResetPassword({ push }: ResetPasswordProps) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMatch, setErrorMatch] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState('');
  const [codeVerified, setCodeVerified] = useState(true);
  const [oobCode, setOobCode] = useState('');
  const [dwollaEnabledUser, setDwollaEnabledUser] = useState(false);
  const [validationLoading, setValidationLoading] = useState(true);

  const ButtonStyle = `${
    isDisabled || loading
      ? 'bg-gray-200 text-gray-700 cursor-default'
      : 'bg-gray-200 focus:border-gray-500 bg-gray-600 text-white'
  }`;
  const inputStyle =
    'flex items-center mr-1 mb-2 appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5';

  useEffect(() => {
    const code = new URLSearchParams(window.location.search)?.get('oobCode');
    if (code) {
      setOobCode(code);
      Firebase.auth()
        .verifyPasswordResetCode(code)
        .then(email => {
          setEmail(email);
          setCodeVerified(true);
        })
        .catch(error => {
          setCodeVerified(false);
          setValidationLoading(false);
          console.error(`Action code is invalid${error}`);
        });
    }
  }, []);

  const getUser = async () => {
    try {
      const data: EResponseTypes['users/get-required-password-security'] = await api.post(
        'users/get-required-password-security',
        {
          email
        }
      );

      if (data.success) {
        setDwollaEnabledUser(data.dwollaPasswordSecurityEnabled);
      }
      setValidationLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (email) {
      void getUser();
    }
  }, [email]);

  const matchPassword = () => {
    if (password && confirmPassword) {
      if (dwollaEnabledUser && !dwollaPasswordValidation.test(password)) {
        setDisabled(true);
        return;
      }
      if (!passwordValidation.test(password)) {
        setDisabled(true);
        return;
      }
      if (
        password.length === confirmPassword.length &&
        password === confirmPassword
      ) {
        setErrorMatch(false);
        if (password.length > 8) {
          setDisabled(false);
          return true;
        }
      } else {
        setErrorMatch(true);
        setDisabled(true);
        return false;
      }
    }
  };

  useEffect(() => {
    if (password) {
      if (dwollaEnabledUser) {
        if (!dwollaPasswordValidation.test(password)) {
          setNewPasswordError(dwollaInvalidPasswordMessage);
          setDisabled(true);
          return;
        }
      } else {
        if (password.length < 9) {
          setNewPasswordError('Password must be at least 9 characters');
          return;
        }
        if (!passwordValidation.test(password)) {
          setNewPasswordError(
            'Password must contain a number or a special character'
          );
          return;
        }
      }
      setNewPasswordError('');
    }
  }, [password]);

  useEffect(() => {
    matchPassword();
  }, [password, confirmPassword]);

  const handleReset = async () => {
    if (matchPassword()) {
      setDisabled(true);
      setLoading(true);
      Firebase.auth()
        .confirmPasswordReset(oobCode, password)
        .then(async () => {
          await api.post('users/update-temporary-password-status', {
            email
          });
          setLoading(false);
          dispatch(
            ToastActions.toastSuccess({
              headerText: 'Success!',
              bodyText:
                'Your password is reset. Please log in with your updated credentials.'
            })
          );
          setTimeout(() => {
            push('/login');
          }, 2000);
        })
        .catch(error => {
          console.error('error in password reset', error);
        });
    }
  };

  const [buttonText, handleClick, buttonStyle] = [
    'Set New Password',
    handleReset,
    ButtonStyle
  ];
  return (
    <div>
      <div>
        <div className="hidden md:block h-0 float-right">
          <img
            className="-mt-1/3 -mr-20"
            src="https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Fe-notice%2Frectangles.png?alt=media&token=6bab4a5b-fb6d-43a3-8425-cbc22ee8fdff"
          />
        </div>
        <div className="hidden md:block h-0 float-left">
          <img
            className="-ml-8 mt-6/12"
            src="https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Fe-notice%2Frectangles.png?alt=media&token=6bab4a5b-fb6d-43a3-8425-cbc22ee8fdff"
          />
        </div>
        {validationLoading ? (
          <div className="flex h-screen items-center justify-center">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-500 h-5 w-5" />
          </div>
        ) : (
          <FreeformCModal
            noExitOutsideModal
            setOpen={() => push('/login')}
            header={
              codeVerified
                ? 'Reset your password'
                : 'Try resetting your password again'
            }
          >
            {codeVerified ? (
              <section className="mt-3">
                <div>
                  <div className="items-start flex-col sm:mr-4">
                    <label id="email" htmlFor="email">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Email"
                        className={`${inputStyle}`}
                        value={email}
                        disabled
                        onChange={e => setEmail(e.target.value)}
                      />
                    </label>
                    <div className="space-y-2">
                      <PasswordField
                        id="password"
                        required
                        value={password}
                        placeHolderText="New password *"
                        onValueChange={(value: string) => setPassword(value)}
                        data-testid="newPassword"
                      />
                      {newPasswordError && (
                        <div className="text-column-red-600 text-sm ml-1">
                          {newPasswordError}
                        </div>
                      )}
                      <PasswordField
                        id="cpassword"
                        required
                        value={confirmPassword}
                        placeHolderText="Confirm new password *"
                        onValueChange={(value: string) =>
                          setConfirmPassword(value)
                        }
                        data-testid="newPassword"
                      />
                      {errorMatch && (
                        <div className="text-column-red-600 text-sm ml-1">
                          Passwords do not match
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <CButton
                      id="reset-password"
                      onClick={() => {
                        void handleClick();
                      }}
                      startClasses={'col-span-0'}
                      middleClasses="col-span-10"
                      disabled={isDisabled || loading}
                      className={` ${buttonStyle} border border-transparent duration-150 ease-in-out focus:outline-none focus:shadow-outline-red font-medium leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base transition w-3/6`}
                      startIcon={
                        loading ? (
                          <div className="loader ease-linear rounded-full border-4 text-center border-t-4 border-gray-500 h-5 w-5" />
                        ) : (
                          ''
                        )
                      }
                    >
                      {buttonText}
                    </CButton>
                  </div>
                </div>
              </section>
            ) : (
              <div className="flex justify-center align-center mt-8">
                Your request to reset your password has expired or the link has
                already been used
              </div>
            )}
          </FreeformCModal>
        )}
      </div>
    </div>
  );
}

export default connect(null, { push })(ResetPassword);
