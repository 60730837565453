import React from 'react';
import { Product } from 'lib/enums';
import { Ad } from 'lib/types/ad';
import {
  captureTaglessEscapedHTML,
  escapeHTML,
  stripHtmlTags
} from 'lib/helpers';
import MinimalMce from '../MinimalMce';
import { AdEditorData } from '../FormattedEditorWrapper';

const titlePlaceholderByProduct: Record<Product, string> = {
  [Product.Classified]: 'Title',
  [Product.Notice]: 'Title',
  [Product.Obituary]: 'Name of loved one'
};

type TitleEditorProps<T extends Ad> = {
  adData: Partial<T>;
  onChange: (data: AdEditorData) => void;
  disableEditing: boolean;
  product: Product;
};

function TitleEditor<T extends Ad>({
  adData,
  onChange,
  disableEditing,
  product
}: TitleEditorProps<T>) {
  return (
    <MinimalMce
      toolbarItems={[['undo', 'redo']]}
      onChange={value =>
        onChange({
          title: captureTaglessEscapedHTML(stripHtmlTags(value))
        })
      }
      inline
      value={escapeHTML(adData?.title || '')}
      id="ad-title-editor"
      disabled={disableEditing}
      placeholder={titlePlaceholderByProduct[product]}
      required
    />
  );
}

export default TitleEditor;
