import { FilingTypeVisibility } from '../../enums';
import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import {
  ColumnError,
  NotFoundError,
  wrapErrorAsColumnError
} from '../../errors/ColumnErrors';
import { safeGetOrThrow } from '../../safeWrappers';
import { AdRate, ESnapshotExists } from '../../types';
import { OrderFilingType } from '../../types/filingType';
import { ResponseOrError, wrapSuccess } from '../../types/responses';
import {
  findLayoutById,
  getDefaultLayoutsByFilingType
} from '../../orders/layouts';
import { Layout } from '../../types/layout';

export class FilingTypeModel extends SnapshotModel<
  OrderFilingType,
  typeof Collections.filingTypes
> {
  get type() {
    return Collections.filingTypes;
  }

  public async getRate(): Promise<
    ResponseOrError<ESnapshotExists<AdRate>, ColumnError>
  > {
    const { response: rate, error: rateError } = await safeGetOrThrow(
      this.modelData.rate
    );
    if (rateError) {
      return wrapErrorAsColumnError(rateError, NotFoundError);
    }
    return wrapSuccess(rate);
  }

  get supportedLayouts(): Layout[] {
    const populatedLayouts = populateLayoutsForFilingType(this.modelData);
    if (populatedLayouts) {
      return populatedLayouts;
    }

    return getDefaultLayoutsByFilingType(this.modelData);
  }

  public isVisibleToUser(isUserPublisher: boolean) {
    const availableVisibilities = [FilingTypeVisibility.all_users.value];

    if (isUserPublisher) {
      availableVisibilities.push(FilingTypeVisibility.publisher_only.value);
    }

    return availableVisibilities.includes(
      this.modelData.visibility || FilingTypeVisibility.disabled.value
    );
  }
}

function populateLayoutsForFilingType(
  filingType: OrderFilingType
): Layout[] | null {
  const { supportedLayoutIds } = filingType;
  const hasSupportedLayouts =
    supportedLayoutIds && supportedLayoutIds?.length > 0;

  if (hasSupportedLayouts) {
    const populatedLayouts = supportedLayoutIds
      .map(findLayoutById)
      .filter((layout): layout is Layout => !!layout);

    if (populatedLayouts.length > 0) {
      return populatedLayouts;
    }
  }

  return null;
}
