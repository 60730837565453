import { getOffset, roundUp } from '../math';
import { floatToP2Float } from '../ui';
import { PricingFunction } from './types';

export const priceWordCount: PricingFunction = options => {
  const { displayParameters, rateRecord, applicableRate } = options;

  const offset = getOffset(rateRecord);

  const processedWordCount = Math.max(
    roundUp(displayParameters.words ?? 0, rateRecord.folioSize) - offset,
    0
  );
  return floatToP2Float(processedWordCount * applicableRate);
};
