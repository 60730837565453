import moment from 'moment';
import axios from 'axios';
import { EFirebaseContext, ESnapshotExists, EUser, exists } from '../types';
import { cdnIfy, isColumnUser } from '../helpers';
import { ResponseOrError, wrapError, wrapSuccess } from '../types/responses';
import { LaunchDarklyFlags } from '../types/launchDarklyFlags';
import { getLaunchDarklyContext } from '../utils/flags';
import { getErrorReporter } from '../utils/errors';
import { safeGetOrThrow } from '../safeWrappers';
import { Product } from '../enums';
import { ProductUploadSetting } from '../types/upload';
import { UploadDestination } from './constants';
import { getProductUploadSettingsForNewspaper } from '../publishers/uploads';

export const getUploadDestinationByOrgId = async (
  ctx: EFirebaseContext,
  orgId: string,
  product: Product
): Promise<ResponseOrError<ProductUploadSetting>> => {
  const newspaperRef = ctx.organizationsRef().doc(orgId);
  const [newspaperError, newspaper] = await safeGetOrThrow(newspaperRef);
  if (newspaperError) {
    return wrapError(newspaperError);
  }

  return getProductUploadSettingsForNewspaper(newspaper, product);
};

/**
 * Splits the newspaper code into its site and publication code parts, then
 * joins them with the date and a sequence number.
 *
 * E.g., given a newspaper code of 'COL-NEWS', a publication date of 12/13, and
 * an index of 0, this function would return 'COL1213NEWS01.pdf'.
 */
export const getPaginationFileName = (
  newspaperCode: string | undefined,
  publicationDate: Date | undefined,
  index: number
) => {
  const code = newspaperCode || 'XXX-X';

  const [siteCode = '', publicationCode = ''] = code.split('-');

  if (!publicationCode) {
    getErrorReporter().logInfo(
      '[getPaginationFileName] Newspaper code does not have publication code (missing hyphen or no publication code after hyphen)',
      { code }
    );
  }

  const dateString = moment(publicationDate).format('MMDD');

  const sequenceNumber = (index + 1).toString().padStart(2, '0');

  return `${siteCode}${dateString}${publicationCode}${sequenceNumber}.pdf`;
};

export const getNewspaperCodeFromDestination = (setting: UploadDestination) => {
  return `${setting.primaryIdentifier}${
    setting.secondaryIdentifier ? `-${setting.secondaryIdentifier}` : ''
  }`;
};

export type AttachmentDimensions = {
  height: number;
  width: number;
};

const getFileDimensionsFromPixelsAndDPI = (
  pixelWidth: number,
  pixelHeight: number,
  DPIWidth: number,
  DPIHeight: number
) => {
  // Round these values to the nearest hundredth
  const heightInInches = parseFloat((pixelHeight / DPIHeight).toFixed(2));
  const widthInInches = parseFloat((pixelWidth / DPIWidth).toFixed(2));

  return { height: heightInInches, width: widthInInches };
};

export const getJpgFileDimensions = async (
  storagePath: string
): Promise<AttachmentDimensions> => {
  /**
   * imgix has stopped sending back DPI for JPGs, so we use a default instead
   * Slack thread: https://columnpbc.slack.com/archives/C0630CV3998/p1705693259428069
   */
  const defaultDPI = 600;

  const useColumnCDN = await getLaunchDarklyContext().getBooleanFeatureFlag(
    LaunchDarklyFlags.ENABLE_COLUMN_CDN,
    { type: 'anonymous', defaultValue: false }
  );
  const sizeURL = cdnIfy(storagePath, {
    useImgix: true,
    imgixTransformations: { fm: 'json' },
    useColumnCDN
  });
  const sizeResponse = await axios.get(sizeURL);
  const res = await sizeResponse.data;

  const { PixelHeight, PixelWidth } = res;
  if (!PixelHeight || !PixelWidth) {
    throw new Error(`Could not get dimensions for file at ${storagePath}`);
  }

  if (typeof PixelHeight !== 'number' || typeof PixelWidth !== 'number') {
    throw new Error(
      `Unexpected type encountered in file dimensions: ${typeof PixelHeight} (PixelHeight), ${typeof PixelWidth} (PixelWidth)`
    );
  }
  return getFileDimensionsFromPixelsAndDPI(
    PixelWidth,
    PixelHeight,
    defaultDPI,
    defaultDPI
  );
};

export const getPDFFileDimensions = async (
  storagePath: string,
  DPIHeight: number,
  DPIWidth: number
): Promise<ResponseOrError<AttachmentDimensions>> => {
  const useColumnCDN = await getLaunchDarklyContext().getBooleanFeatureFlag(
    LaunchDarklyFlags.ENABLE_COLUMN_CDN,
    { type: 'anonymous', defaultValue: false }
  );
  const sizeURL = cdnIfy(storagePath, {
    useImgix: true,
    imgixTransformations: { fm: 'json' },
    useColumnCDN
  });

  try {
    const sizeResponse = await axios.get(sizeURL);
    const res = await sizeResponse.data;

    const { PixelHeight, PixelWidth } = res;
    if (!PixelHeight || !PixelWidth) {
      return wrapError(
        new Error(`Could not get dimensions for file at ${storagePath}`)
      );
    }

    if (typeof PixelHeight !== 'number' || typeof PixelWidth !== 'number') {
      return wrapError(
        new Error(
          `Unexpected type encountered in file dimensions: ${typeof PixelHeight} (PixelHeight), ${typeof PixelWidth} (PixelWidth)`
        )
      );
    }
    return wrapSuccess(
      getFileDimensionsFromPixelsAndDPI(
        PixelWidth,
        PixelHeight,
        DPIWidth,
        DPIHeight
      )
    );
  } catch (err) {
    return wrapError(err as Error);
  }
};

export const shouldShowDisabledColumnForUser = (
  user: ESnapshotExists<EUser> | null
) => {
  return exists(user) && isColumnUser(user);
};

export const __private = {
  getFileDimensionsFromPixelsAndDPI
};
