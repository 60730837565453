import { OrganizationType } from 'lib/enums';
import { removeUndefinedFields } from 'lib/helpers';
import { ObituaryModel } from 'lib/model/objects/obituaryModel';
import { OrderModel } from 'lib/model/objects/orderModel';
import { ObituaryService } from 'lib/services/obituaryService';
import { ERef, ESnapshotExists, EUser } from 'lib/types';
import { Obituary } from 'lib/types/obituary';
import { Order } from 'lib/types/order';
import { getFirebaseContext } from 'utils/firebase';
import { isObituaryModel } from 'lib/model/objects/adModel';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { getErrorReporter } from 'lib/utils/errors';
import { ColumnService } from 'lib/services/directory';
import { getModelFromRef } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import IPlacementFlowHelper from '.';
import { MINIMUM_ORDER } from '../../steps/PersonalDetail';

class ObituaryPlacementFlowHelper
  implements IPlacementFlowHelper<Obituary, 'obituaries'> {
  private context = getFirebaseContext();

  private obituaryService = new ObituaryService(this.context);

  async createAd(
    user: ESnapshotExists<EUser>
  ): Promise<ResponseOrError<ERef<Order>, Error>> {
    const orgRef = user.data().activeOrganization;
    if (!orgRef) {
      return wrapError(
        new Error(
          `User must have an active organization to create an obituary: ${user.id}`
        )
      );
    }

    const activeOrganizationModel = await getModelFromRef(
      OrganizationModel,
      getFirebaseContext(),
      orgRef
    );

    const extraInfo =
      activeOrganizationModel &&
      activeOrganizationModel.modelData.organizationType ===
        OrganizationType.funeral_home.value
        ? {
            advertiserOrganization: activeOrganizationModel.ref,
            advertiser: user.ref
          }
        : { ...MINIMUM_ORDER };

    const obituaryModel = await this.obituaryService.create(
      removeUndefinedFields({
        user: user.ref,
        authorizedOrganization: activeOrganizationModel.ref,
        ...extraInfo
      })
    );

    return wrapSuccess(obituaryModel.modelData.order);
  }

  async getAdModel(
    orderModel: OrderModel
  ): Promise<ResponseOrError<ObituaryModel>> {
    const adResult = await orderModel.getAdByVersion();

    if (adResult.error) {
      return adResult;
    }

    const adModel = adResult.response;

    if (!isObituaryModel(adModel)) {
      const errorMessage = 'Not an obituary';

      const error = Error(errorMessage);

      getErrorReporter().logAndCaptureError(
        ColumnService.OBITS,
        error,
        errorMessage,
        { orderId: orderModel.id }
      );

      return wrapError(error);
    }

    return wrapSuccess(adModel);
  }
}

export default ObituaryPlacementFlowHelper;
