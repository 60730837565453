import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';

import { OrderImage } from 'lib/orders/images';
import { Layout } from 'lib/types/layout';
import { Modal } from 'lib/components/Modal';

type ImageEditorModalProps = {
  onUpdateOrderImage: (orderImage: OrderImage) => void;
  orderImage: OrderImage;
  onClose: () => void;
  layout: Layout;
  disabled?: boolean;
};

export default function ImageEditorModal({
  onUpdateOrderImage,
  orderImage,
  onClose,
  layout,
  disabled
}: ImageEditorModalProps) {
  const aspectRatioValues = layout.aspectRatio.split(' / ');

  const [crop, setCrop] = useState<Required<ReactCrop.PercentCrop>>({
    aspect:
      parseInt(aspectRatioValues[0], 10) / parseInt(aspectRatioValues[1], 10),

    // default to 100% width
    width: orderImage.crop?.widthPct || 100,

    // don't default on height so the aspect ratio starts working
    height: orderImage.crop?.heightPct || 0,
    unit: '%',
    x: orderImage.crop?.xPct || 0,
    y: orderImage.crop?.yPct || 0
  });

  return (
    <Modal
      title={'Crop Image'}
      id="crop-modal"
      onClose={onClose}
      primaryAction={{
        buttonText: 'Save Edits',
        onClick: () => {
          onUpdateOrderImage({
            ...orderImage,
            crop: {
              heightPct: crop.height,
              widthPct: crop.width,
              xPct: crop.x,
              yPct: crop.y
            }
          });
          onClose();
        },
        type: 'button'
      }}
    >
      <div className="flex justify-center">
        <ReactCrop
          ruleOfThirds
          onChange={(_crop, pctCrop) => {
            setCrop(pctCrop as Required<ReactCrop.PercentCrop>);
          }}
          locked
          crop={crop}
          src={orderImage.imageUrl}
          disabled={disabled}
        />
      </div>
    </Modal>
  );
}
