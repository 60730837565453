import { useState } from 'react';
import { CustomerTypeString } from 'routes/ads/filters/helpers';
import { Customer, ERef } from 'lib/types';
import FlowChoiceForm from './FlowChoiceForm';
import FuneralHomeCustomerSearch from './FuneralHomeSearch';
import { FuneralHomeCustomerInformation } from './funeralHomeCustomerInformation';
import MultiStepHeader from '../../components/MultiStepHeader';

type FlowChoiceStepProps = {
  chosenCustomer: ERef<Customer> | undefined;
  onChosenCustomerChange: (
    advertiser: FuneralHomeCustomerInformation | undefined
  ) => void;
};

function FlowChoiceStep({
  chosenCustomer,
  onChosenCustomerChange
}: FlowChoiceStepProps) {
  const [flowChoice, setFlowChoice] = useState(
    chosenCustomer
      ? CustomerTypeString.FUNERAL_HOME
      : CustomerTypeString.INDIVIDUAL
  );

  return (
    <>
      <MultiStepHeader
        title="Customer type"
        description="Select the type of customer you are placing for."
      />
      <div className="grid gap-4">
        <FlowChoiceForm
          onFlowChoiceChange={o => {
            setFlowChoice(o);
            onChosenCustomerChange(undefined);
          }}
          flowChoice={flowChoice}
        />
        {flowChoice === CustomerTypeString.FUNERAL_HOME && (
          <FuneralHomeCustomerSearch
            chosenCustomer={chosenCustomer}
            onChosenCustomerChange={o => {
              onChosenCustomerChange(o);
            }}
          />
        )}
      </div>
    </>
  );
}

export default FlowChoiceStep;
