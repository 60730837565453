import { ExclamationCircle } from 'icons';
import {
  EOrganization,
  ESnapshot,
  ESnapshotExists,
  ERate,
  Discount,
  EUser
} from 'lib/types';
import React from 'react';
import { Alert } from 'lib/components/Alert';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { supportsDisplay } from 'lib/notice/rates';
import { useBulkPaymentV2EnabledOnOrganization } from 'components/hooks/useBulkPaymentV2EnabledOnOrganization';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { Tooltip } from 'lib/components/Tooltip';
import { isColumnUser } from 'lib/helpers';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import { BillingTermType, enumToSelectInput } from 'lib/enums';
import { DiscountSettings, FLAT_DISCOUNT_CONFIG } from './DiscountSettings';
import { CustomerObjDataFields } from './CreateOrEditModalDetails';

export type CreateOrEditModalSettingsProps = {
  activeOrganization: ESnapshot<EOrganization>;
  rates?: ESnapshotExists<ERate>[];
  value: CustomerObjDataFields;
  advertiser: EUser | null;
  onChange: (value: CustomerObjDataFields) => void;
};

export type RequireUpfrontPaymentType = {
  id: string;
  description: string;
  value: boolean | null;
};

export const REQUIRE_UPFRONT_PAYMENT_TYPES = {
  DEFAULT: { id: '0', description: 'Default', value: null },
  ALWAYS_REQUIRE: { id: '1', description: 'Always require', value: true },
  NEVER_REQUIRE: { id: '2', description: 'Never require', value: false }
};
export const REQUIRE_UPFRONT_PAYMENT_OPTIONS = Object.values(
  REQUIRE_UPFRONT_PAYMENT_TYPES
);

export function CreateOrEditCustomerModalSettings({
  activeOrganization,
  rates,
  value,
  advertiser,
  onChange
}: CreateOrEditModalSettingsProps) {
  const user = useAppSelector(selectUser);

  // we only want to display the bulk invoice editing ability if the newspaper
  // has bulkPaymentEnabled_v2 on and the user affiliated with the customer is an individual,
  // or if the newspaper has bulkPaymentEnabled (regardless of user occupation)
  const isBulkPaymentV2EnabledOnOrganization = useBulkPaymentV2EnabledOnOrganization(
    activeOrganization
  );
  const userIsIndividual = !advertiser?.allowedOrganizations?.length;
  const activeOrganizationHasBulkPaymentEnabled =
    isBulkPaymentV2EnabledOnOrganization && userIsIndividual;

  const linerRateIsArchived = !!value.linerRateSnap?.data()?.archived;
  const displayRateIsArchived = !!value.displayRateSnap?.data()?.archived;

  const linerRateOptions = rates
    ?.filter(rate => {
      if (value.linerRateSnap?.id === rate.id) return true;
      return !rate.data().archived;
    })
    .map(rate => ({
      value: rate.id,
      label: rate.data()?.description
    }));

  const displayRateOptions = rates
    ?.filter(rate => {
      if (value.displayRateSnap?.id === rate.id) return true;
      const rateSupportsDisplay = supportsDisplay(rate.data());
      const isOrganizationDefaultDisplayRate =
        rate.id === activeOrganization.data()?.defaultDisplayRate?.id;
      return (
        isOrganizationDefaultDisplayRate ||
        (rateSupportsDisplay && !rate.data().archived)
      );
    })
    .map(rate => ({
      value: rate.id,
      label: rate.data()?.description
    }));

  return (
    <CardGridLayout
      header={{
        title: "Set up your customer's preferences.",
        description:
          'Configure how you want this customer to interact with your publication.'
      }}
    >
      <GridInput fullWidth>
        <Alert
          id="customization-warning"
          status="warning"
          icon={<ExclamationCircle className="w-5 h-5" />}
          description="Customer liner and display rates are set to your organization's default settings. You should only make changes if you want a different rate for this customer."
        />
      </GridInput>
      <GridInput>
        <ColumnSelect
          labelText="Liner rate"
          id="liner_rate"
          value={value.linerRateSnap?.id}
          options={linerRateOptions || []}
          onChange={newValue => {
            const selectedRate = rates?.find(r => r.id === newValue);
            if (selectedRate) {
              onChange({
                ...value,
                linerRateSnap: selectedRate
              });
            }
          }}
          errorText={
            linerRateIsArchived
              ? 'The selected rate is archived, please select another rate'
              : undefined
          }
        />
      </GridInput>
      <GridInput>
        <ColumnSelect
          labelText="Display rate"
          id="display_rate"
          value={value.displayRateSnap?.id}
          options={displayRateOptions || []}
          onChange={newValue => {
            const selectedRate = rates?.find(r => r.id === newValue);
            if (selectedRate) {
              onChange({
                ...value,
                displayRateSnap: selectedRate
              });
            }
          }}
          errorText={
            displayRateIsArchived
              ? 'The selected rate is archived, please select another rate'
              : undefined
          }
        />
      </GridInput>
      <GridInput fullWidth>
        <ColumnSelect
          labelText="Do you require upfront payment for this customer?"
          placeholder="Require upfront payment?"
          id="upfront_payment"
          value={value.upfrontPaymentSetting.id}
          disabled={!!value.bulkPaymentEnabled_v2}
          options={REQUIRE_UPFRONT_PAYMENT_OPTIONS.map(option => ({
            value: option.id,
            label: option.description
          }))}
          onChange={newValue => {
            if (newValue === REQUIRE_UPFRONT_PAYMENT_TYPES.DEFAULT.id) {
              onChange({
                ...value,
                upfrontPaymentSetting: REQUIRE_UPFRONT_PAYMENT_TYPES.DEFAULT
              });
            } else if (
              newValue === REQUIRE_UPFRONT_PAYMENT_TYPES.ALWAYS_REQUIRE.id
            ) {
              onChange({
                ...value,
                upfrontPaymentSetting:
                  REQUIRE_UPFRONT_PAYMENT_TYPES.ALWAYS_REQUIRE
              });
            } else {
              onChange({
                ...value,
                upfrontPaymentSetting:
                  REQUIRE_UPFRONT_PAYMENT_TYPES.NEVER_REQUIRE
              });
            }
          }}
        />
      </GridInput>

      {/* Expose additional controls to Column users to set default columns and default notice type */}
      {user && isColumnUser(user) && (
        <>
          <GridInput>
            <ColumnSelect
              labelText="Default columns (available to Column users only)"
              id="default-columns"
              value={value.defaultColumns?.toString() || 'default'}
              options={[
                { value: 'default', label: 'Default' },
                { value: '1', label: '1' },
                { value: '2', label: '2' },
                { value: '3', label: '3' }
              ]}
              onChange={newValue => {
                if (newValue === 'default') {
                  const newValue = { ...value };
                  delete newValue.defaultColumns;
                  onChange(newValue);
                } else {
                  onChange({
                    ...value,
                    defaultColumns: parseInt(newValue, 10)
                  });
                }
              }}
            />
          </GridInput>
          {!!activeOrganization.data()?.allowedNotices?.length && (
            <GridInput>
              <ColumnSelect
                labelText="Default notice type (available to Column users only)"
                id="default-notice-type"
                value={value.defaultNoticeType?.toString() || 'default'}
                options={[
                  { value: 'default', label: 'Default' },
                  ...(activeOrganization
                    .data()
                    ?.allowedNotices?.map(noticeType => ({
                      value: noticeType.value.toString(),
                      label: noticeType.label
                    })) || [])
                ]}
                onChange={newValue => {
                  if (newValue === 'default') {
                    const newValue = { ...value };
                    delete newValue.defaultNoticeType;
                    onChange(newValue);
                  } else {
                    onChange({
                      ...value,
                      defaultNoticeType: parseInt(newValue, 10)
                    });
                  }
                }}
              />
            </GridInput>
          )}
        </>
      )}
      <GridInput fullWidth>
        <LabeledSwitch
          label="Allow affidavits before payment?"
          description="Customers will be allowed to download affidavits before paying
              invoices."
          value={!!value.enableAffidavitsBeforePayment}
          onChange={enableAffidavitsBeforePayment => {
            onChange({
              ...value,
              enableAffidavitsBeforePayment
            });
          }}
        />
      </GridInput>
      {activeOrganizationHasBulkPaymentEnabled && (
        <GridInput fullWidth>
          <Tooltip
            helpText={
              !value.bulkPaymentEnabled_v2
                ? 'Please create an organization for this customer first'
                : ''
            }
            position="top"
          >
            <LabeledSwitch
              label="Allow bulk invoicing?"
              description="Toggle this on if you want to create one monthly invoice for
                this customer."
              value={!!value.bulkPaymentEnabled_v2}
              onChange={enabled => {
                onChange({
                  ...value,
                  bulkPaymentEnabled_v2: enabled
                });
              }}
              disabled={!value.bulkPaymentEnabled_v2}
            />
          </Tooltip>
        </GridInput>
      )}
      {activeOrganization.data()?.allowInvoiceOutsideColumn && (
        <GridInput fullWidth>
          <LabeledSwitch
            label="Invoice outside Column?"
            description="By default, invoices created for this customer will be billed outside of Column."
            value={!!value.invoicedOutsideColumn}
            onChange={invoicedOutsideColumn => {
              onChange({
                ...value,
                invoicedOutsideColumn
              });
            }}
          />
        </GridInput>
      )}
      {activeOrganization.data()?.hideDigitalAffidavits && (
        <GridInput fullWidth>
          <LabeledSwitch
            label="Allow emailed affidavits?"
            description="Toggle this on if you want to allow this customer to request
                  affidavits by email."
            value={!!value.allowAffidavitEmail}
            onChange={allowAffidavitEmail => {
              onChange({
                ...value,
                allowAffidavitEmail
              });
            }}
          />
        </GridInput>
      )}
      <SwitchControlledCard
        labelProps={{
          label: 'Add a discount to this account?',
          description:
            'You can specify a flat discount or a percentage discount along with an expiration date.',
          value: !!value.discountConfig?.subtotal,
          onChange: enableDiscount => {
            if (!enableDiscount) {
              onChange({
                ...value,
                discountConfig: {}
              });
            } else {
              onChange({
                ...value,
                discountConfig: { subtotal: FLAT_DISCOUNT_CONFIG }
              });
            }
          }
        }}
        header=""
      >
        <DiscountSettings
          value={value.discountConfig?.subtotal ?? FLAT_DISCOUNT_CONFIG}
          onChange={(discount: Discount) => {
            onChange({
              ...value,
              discountConfig: { subtotal: discount }
            });
          }}
        />
      </SwitchControlledCard>
      {userIsIndividual && (
        <GridInput fullWidth>
          <ColumnSelect
            id="billing-term-select"
            labelText="Default invoice due date for billable notices"
            noteText={
              <>
                Learn more about this option{' '}
                <a
                  className="text-blue-400"
                  href="https://help.column.us/en_us/how-do-i-update-my-customer-invoice-due-date-rJ48X3KT"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
              </>
            }
            value={value.billingTerm?.toString()}
            options={enumToSelectInput(BillingTermType)}
            onChange={stringValue =>
              onChange({ ...value, billingTerm: Number(stringValue) })
            }
          />
        </GridInput>
      )}
    </CardGridLayout>
  );
}
