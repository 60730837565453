import { ColumnButton } from 'lib/components/ColumnButton';
import { OrderEditableData } from 'lib/model/objects/orderModel';
import { ResponseOrError } from 'lib/types/responses';
import React from 'react';

type EditOrderButtonProps = {
  editData: OrderEditableData | null;
  onEditOrder: () => Promise<ResponseOrError<void>>;
};

export function EditOrderButton({
  editData,
  onEditOrder
}: EditOrderButtonProps) {
  const canEdit = editData && Object.values(editData).some(ed => ed.canEdit);
  return (
    <ColumnButton
      id="edit-order-button"
      type="button"
      onClick={onEditOrder}
      buttonText={'Edit'}
      disabled={!canEdit}
    />
  );
}
