import { EFirebaseContext, EOrganization, ERef, EUser } from '../types';

class CustomerService {
  public constructor(private context: EFirebaseContext) {}

  getCustomerFromUserAndOrg(
    user: ERef<EUser>,
    organization: ERef<EOrganization>
  ) {
    const customerRef = this.context
      .customersRef()
      .doc(`${user.id}_${organization.id}`);

    return customerRef.get();
  }
}

export default CustomerService;
