import { connect } from 'react-redux';
import { EReduxState } from 'redux/types';
import ResetPassword from './ResetPassword';

const mapStateToProps = (state: EReduxState) => ({
  authError: state.auth.error,
  userAuth: state.auth.userAuth
});

export default connect(mapStateToProps)(ResetPassword);
