import { OrderEditableData, OrderModel } from 'lib/model/objects/orderModel';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getReceiptUrlFromInvoice } from 'lib/utils/receipts';
import { useState } from 'react';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { useAppDispatch } from 'redux/hooks';
import { push } from 'connected-react-router';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { OrderDetailModel } from 'lib/model/objects/orderDetailModel';
import { CancelOrderButton } from './actions/CancelOrderButton';
import MoreActions from './actions/MoreActions';
import { sendCancelOrderRequest } from './actions/cancelOrder';
import { EditOrderButton } from './actions/EditOrderButton';

type OrderActionsProps = {
  order: OrderModel;
  orderDetail: OrderDetailModel;
  editData: OrderEditableData | null;
  anonymousUserAuthInfo: { authEmail: string; accessCode: string } | undefined;
};

export function OrderActions({
  order,
  orderDetail,
  editData,
  anonymousUserAuthInfo
}: OrderActionsProps) {
  const dispatch = useAppDispatch();
  const [invoiceIsUpdating, setInvoiceIsUpdating] = useState(false);

  const { isLoading: invoiceIsGetting, value: invoice } = useAsyncEffect({
    fetchData: async () => {
      const {
        response: invoice,
        error: invoiceError
      } = await order.getInvoice();

      if (invoiceError || !invoice) {
        return;
      }
      return invoice;
    },
    dependencies: [order.id]
  });
  const receiptUrl = invoice ? getReceiptUrlFromInvoice(invoice) : undefined;
  const invoiceIsLoading = invoiceIsGetting || invoiceIsUpdating;

  const handleSendToEditFlow = async (): Promise<ResponseOrError<void>> => {
    const {
      response,
      error: duplicateError
    } = await order.generateNewVersionOfOrderItems();
    if (duplicateError) {
      return wrapError(duplicateError);
    }

    const { version: newVersion } = response;
    const reauthorizationParams = anonymousUserAuthInfo
      ? `&accessCode=${
          anonymousUserAuthInfo.accessCode
        }&email=${encodeURIComponent(anonymousUserAuthInfo.authEmail)}`
      : '';
    dispatch(
      push(
        `/${PRODUCT_TO_NAME[
          order.modelData.product
        ].plural.toLowerCase()}/edit/${
          order.id
        }?version=${newVersion}${reauthorizationParams}`
      )
    );
    return wrapSuccess(undefined);
  };

  const handleCancelOrder = async (
    cancellationReason: string
  ): Promise<ResponseOrError<void>> => {
    setInvoiceIsUpdating(true);
    const { error: cancelError } = await sendCancelOrderRequest(
      cancellationReason,
      order,
      anonymousUserAuthInfo
    );
    await invoice?.refreshData();
    setInvoiceIsUpdating(false);
    if (cancelError) {
      return wrapError(new Error('Failed to cancel order'));
    }
    return wrapSuccess(undefined);
  };

  return (
    <>
      <EditOrderButton editData={editData} onEditOrder={handleSendToEditFlow} />
      <CancelOrderButton
        order={order}
        orderDetail={orderDetail}
        onCancelOrder={handleCancelOrder}
        isAnonymousUser={!!anonymousUserAuthInfo}
      />
      <MoreActions
        order={order}
        receiptUrl={receiptUrl}
        invoiceIsLoading={invoiceIsLoading}
      />
    </>
  );
}
