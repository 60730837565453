import { EOrganization, ESnapshotExists } from '../types';
import { Product } from '../enums';
import { getInheritedProperty } from '../utils/inheritance';
import { ResponseOrError, wrapError, wrapSuccess } from '../types/responses';
import { ColumnService } from '../services/directory';
import { getErrorReporter } from '../utils/errors';
import { ProductUploadSetting } from '../types/upload';

export const getProductUploadSettingsForNewspaper = async (
  newspaper: ESnapshotExists<EOrganization>,
  product: Product
): Promise<ResponseOrError<ProductUploadSetting>> => {
  const ftpSettings = await getInheritedProperty(
    newspaper.ref,
    'productUploadSettings'
  );
  const relevantUploadSettings = ftpSettings?.filter(setting =>
    setting.products.includes(product)
  );
  if (!relevantUploadSettings || relevantUploadSettings.length !== 1) {
    const err = new Error(
      'Expected exactly one upload setting for product and newspaper'
    );
    getErrorReporter().logAndCaptureCriticalError(
      ColumnService.OBITS,
      err,
      'getProductUploadSettingsForNewspaper failed! Possibly impacting auto bulk uploads!',
      {
        newspaperId: newspaper.id,
        product,
        numberOfSettingsFound: `${(relevantUploadSettings || []).length}`
      }
    );
    return wrapError(err);
  }
  return wrapSuccess(relevantUploadSettings[0]);
};
