import { useEffect, useState } from 'react';
import { PublicationIssueAttachmentModel } from 'lib/model/objects/publicationIssueAttachmentModel';
import {
  AttachmentDimensions,
  getJpgFileDimensions
} from 'lib/pagination/helpers';

export function useGetBlockDimensions(
  issueAttachment: PublicationIssueAttachmentModel
) {
  const [
    attachmentDimensions,
    setAttachmentDimensions
  ] = useState<AttachmentDimensions>();

  useEffect(() => {
    if (issueAttachment.modelData.storagePath) {
      void (async () => {
        const { storagePath } = issueAttachment.modelData;
        const { height, width } = await getJpgFileDimensions(storagePath);
        setAttachmentDimensions({
          height,
          width
        });
      })();
    }
  }, [issueAttachment.modelData.storagePath]);

  return { attachmentDimensions };
}
