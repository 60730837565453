import moment from 'moment';
import { NotFoundError } from '../../errors/ColumnErrors';
import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import {
  NewspaperOrder,
  NewspaperOrderStatus
} from '../../types/newspaperOrder';
import {
  ResponseOrColumnError,
  ResponseOrError,
  wrapError,
  wrapSuccess
} from '../../types/responses';
import { getProductDeadlineTimeForPaper } from '../../utils/deadlines';
import { OrderModel } from './orderModel';
import { safeAsync } from '../../safeWrappers';
import { ColumnService } from '../../services/directory';
import { getErrorReporter } from '../../utils/errors';
import { OrganizationModel } from './organizationModel';
import { FilingTypeModel } from './filingTypeModel';
import {
  AdRate,
  ERef,
  ESnapshotExists,
  EUser,
  FirebaseTimestamp
} from '../../types';
import { safeGetModelFromRef } from '../getModel';
import { UserModel } from './userModel';
import { PublicationIssue } from '../../types/publicationIssue';
import { getDateForDateStringInTimezone } from '../../utils/dates';
import {
  ORDER_NEWSPAPER_ORDER_CONFIRMED,
  OrderNewspaperOrderConfirmedEvent
} from '../../types/events';

export type NewspaperOrderEditableData = {
  canEdit: boolean;
  isBeforeDeadline: boolean;
  bannerMessage: string | undefined;
  newspaperId: string;
};

export class NewspaperOrderModel extends SnapshotModel<
  NewspaperOrder,
  typeof Collections.newspaperOrders
> {
  private newspaper: OrganizationModel | null = null;

  get type() {
    return Collections.newspaperOrders;
  }

  get transferHasOccurred() {
    return !!this.modelData.transfer;
  }

  public getOrderRef() {
    return this.ref.parent.parent;
  }

  public async getOrder(): Promise<ResponseOrColumnError<OrderModel>> {
    const orderRef = this.getOrderRef();
    if (!orderRef) {
      return wrapError(new NotFoundError('Order not found'));
    }
    return safeGetModelFromRef(OrderModel, this.ctx, orderRef);
  }

  public async getNewspaper(): Promise<
    ResponseOrColumnError<OrganizationModel>
  > {
    if (this.newspaper) {
      return wrapSuccess(this.newspaper);
    }
    const { response: newspaper, error } = await safeGetModelFromRef(
      OrganizationModel,
      this.ctx,
      this.modelData.newspaper
    );
    if (error) {
      return wrapError(error);
    }
    this.newspaper = newspaper;
    return wrapSuccess(newspaper);
  }

  private getSortedPublishingDates() {
    return [...this.modelData.publishingDates].sort();
  }

  public async getDeadline(): Promise<ResponseOrError<moment.Moment>> {
    try {
      if (this.modelData.publishingDates.length === 0) {
        return wrapError(new Error('Newspaper order has no publishing dates'));
      }
      // TODO: Pass this in
      const newspaperResp = await this.getNewspaper();
      if (newspaperResp.error) {
        return wrapError(newspaperResp.error);
      }
      const newspaper = newspaperResp.response;
      const firstPublishingDateStr = this.getSortedPublishingDates()[0];
      const { response: order, error: getOrderError } = await this.getOrder();
      if (getOrderError) {
        return wrapError(getOrderError);
      }
      const { product } = order.modelData;
      const {
        response: deadlineResponse,
        error: deadlineError
      } = await getProductDeadlineTimeForPaper(
        newspaper,
        product,
        this.modelData.publishingMedium,
        firstPublishingDateStr
      );
      if (deadlineError) {
        return wrapError(deadlineError);
      }
      if (deadlineResponse === null) {
        const err = new Error('Deadline result response is null');
        getErrorReporter().logAndCaptureCriticalError(
          ColumnService.OBITS,
          err,
          'Failed to get deadline for newspaper order',
          {
            newspaperOrderId: this.id,
            orderId: order.id,
            product
          }
        );
        return wrapError(err);
      }
      return wrapSuccess(deadlineResponse.deadlineMoment);
    } catch (err) {
      return wrapError(err as Error);
    }
  }

  public async getFilingType(): Promise<
    ResponseOrColumnError<FilingTypeModel>
  > {
    return safeGetModelFromRef(
      FilingTypeModel,
      this.ctx,
      this.modelData.filingType
    );
  }

  public async getRate(): Promise<
    ResponseOrColumnError<ESnapshotExists<AdRate>>
  > {
    const {
      response: filingType,
      error: filingTypeError
    } = await this.getFilingType();
    if (filingTypeError) {
      return wrapError(filingTypeError);
    }
    const { response: rate, error: rateError } = await filingType.getRate();
    if (rateError) {
      return wrapError(rateError);
    }
    return wrapSuccess(rate);
  }

  public async updateStatus(
    status: NewspaperOrderStatus,
    confirmedBy?: ERef<EUser>
  ): Promise<ResponseOrError<void>> {
    if (status === NewspaperOrderStatus.COMPLETE) {
      const {
        response: canMarkAsComplete,
        error: canMarkAsCompleteError
      } = await this.areCompletionConditionsMet();
      if (canMarkAsCompleteError) {
        return wrapError(canMarkAsCompleteError);
      }
      /**
       * This is considered an error rather than just an early return because in
       * `markOrdersAsComplete`, we query orders from Elastic that match these criteria,
       * so if this returns false, then something may have gone wrong in that query or
       * in the sync to Elastic.
       */
      if (!canMarkAsComplete) {
        return wrapError(
          new Error(
            'Newspaper order does not meet conditions to be marked as complete'
          )
        );
      }
    }
    const updates: Partial<NewspaperOrder> = { status };
    const orderConfirmed = status === NewspaperOrderStatus.CONFIRMED;
    if (orderConfirmed) {
      updates.confirmedAt = this.ctx
        .fieldValue()
        .serverTimestamp() as FirebaseTimestamp;
    }
    const safeUpdate = safeAsync<void>(async () => await this.update(updates));
    const updateResult = await safeUpdate();
    if (updateResult.error) {
      return wrapError(updateResult.error);
    }

    // If the new status is not confirmed, then we are done.
    if (!orderConfirmed) {
      return updateResult;
    }

    if (!confirmedBy) {
      return wrapError(new Error('Newspaper order confirmed anonymously'));
    }

    const { response: order, error: orderError } = await this.getOrder();
    if (orderError) {
      return wrapError(orderError);
    }
    const {
      error: createEventError
    } = await order.createEvent<OrderNewspaperOrderConfirmedEvent>(
      ORDER_NEWSPAPER_ORDER_CONFIRMED,
      {
        newspaperOrder: this.ref,
        confirmedBy
      }
    );
    if (createEventError) {
      return wrapError(createEventError);
    }

    return wrapSuccess(undefined);
  }

  public async isAtLeastNHoursBeforeDeadline(
    hoursBeforeDeadline: number
  ): Promise<ResponseOrError<boolean>> {
    const {
      response: deadline,
      error: deadlineError
    } = await this.getDeadline();
    if (deadlineError) {
      return wrapError(deadlineError);
    }

    return wrapSuccess(
      moment().add(hoursBeforeDeadline, 'hours').isBefore(deadline)
    );
  }

  private async getEditableDataForPublisher(
    user: UserModel,
    isBeforeDeadline: boolean
  ): Promise<ResponseOrError<NewspaperOrderEditableData>> {
    const {
      response: activeOrganization,
      error: activeOrganizationError
    } = await user.getActiveOrganization();
    if (activeOrganizationError) {
      return wrapError(activeOrganizationError);
    }

    const newspaperId = this.modelData.newspaper.id;
    const userIsPartOfNewspaper = newspaperId === activeOrganization.id;
    const newspaperOrderIsAwaitingReview =
      this.modelData.status === NewspaperOrderStatus.AWAITING_REVIEW;
    const userCanEditOwnNewspaper =
      userIsPartOfNewspaper && !this.transferHasOccurred;
    const userCanEditForAnotherNewspaper =
      isBeforeDeadline && newspaperOrderIsAwaitingReview;

    const canEdit = userCanEditOwnNewspaper || userCanEditForAnotherNewspaper;
    const bannerMessage =
      isBeforeDeadline && canEdit
        ? undefined
        : userIsPartOfNewspaper && canEdit
        ? 'This order has passed ad deadline. If you edit this order, ensure that the updated proof is sent to pagination to prevent incorrect content from publishing.'
        : userIsPartOfNewspaper && !canEdit
        ? 'A transfer has occurred for this order. No edits can be processed.'
        : !isBeforeDeadline
        ? 'The ad deadline for this order has passed. No edits or cancellations can be processed.'
        : "This publication has updated this order's confirmation status. You cannot edit this portion of the order.";
    return wrapSuccess({
      canEdit,
      bannerMessage,
      isBeforeDeadline,
      newspaperId
    });
  }

  private async getEditableDataForCustomer(
    isBeforeDeadline: boolean
  ): Promise<ResponseOrError<NewspaperOrderEditableData>> {
    const {
      response: isAtLeastOneHourBeforeDeadline,
      error: checkDeadlineError
    } = await this.isAtLeastNHoursBeforeDeadline(1);
    if (checkDeadlineError) {
      return wrapError(checkDeadlineError);
    }

    const canEdit = isAtLeastOneHourBeforeDeadline;
    const bannerMessage = canEdit
      ? 'Edits can be made to your order until one hour before the ad deadline. You can cancel your order until the ad deadline.'
      : isBeforeDeadline
      ? 'Edits cannot be made when it is less than one hour before the ad deadline. You can still cancel your order until the ad deadline.'
      : 'The ad deadline for this order has passed. No edits or cancellations can be processed.';
    return wrapSuccess({
      canEdit,
      bannerMessage,
      isBeforeDeadline,
      newspaperId: this.modelData.newspaper.id
    });
  }

  public async getEditableDataForUser(
    user: UserModel | null
  ): Promise<ResponseOrError<NewspaperOrderEditableData>> {
    const {
      response: isBeforeDeadline,
      error: isBeforeDeadlineError
    } = await this.isAtLeastNHoursBeforeDeadline(0);
    if (isBeforeDeadlineError) {
      return wrapError(isBeforeDeadlineError);
    }

    // If the newspaper order is cancelled, it can't be edited
    if (this.modelData.status === NewspaperOrderStatus.CANCELLED) {
      return wrapSuccess({
        canEdit: false,
        bannerMessage: undefined,
        isBeforeDeadline,
        newspaperId: this.modelData.newspaper.id
      });
    }

    if (user && user.isPublisher) {
      return this.getEditableDataForPublisher(user, isBeforeDeadline);
    }

    return this.getEditableDataForCustomer(isBeforeDeadline);
  }

  public async getPublicationIssues(): Promise<
    ResponseOrError<ESnapshotExists<PublicationIssue>[]>
  > {
    const { response, error } = await safeAsync<
      ESnapshotExists<PublicationIssue>[]
    >(async () => {
      const publicationIssues = await this.ctx
        .publicationIssuesRef()
        .where('publisher', '==', this.modelData.newspaper)
        .where('publicationDate', 'in', this.modelData.publishingDates)
        .get();

      return publicationIssues.docs;
    })();

    if (error) {
      return wrapError(error);
    }

    return wrapSuccess(response);
  }

  private get isConfirmed() {
    return this.modelData.status === NewspaperOrderStatus.CONFIRMED;
  }

  public get isComplete() {
    return this.modelData.status === NewspaperOrderStatus.COMPLETE;
  }

  private async hasLastPublishingDateElapsed(): Promise<
    ResponseOrError<boolean>
  > {
    const lastPublishingDateStr = this.getSortedPublishingDates().pop();
    if (!lastPublishingDateStr) {
      return wrapError(
        new Error(
          'Unable to determine last publishing date for newspaper order'
        )
      );
    }

    const {
      response: newspaper,
      error: newspaperError
    } = await this.getNewspaper();
    if (newspaperError) {
      return wrapError(newspaperError);
    }

    const timezone = newspaper.modelData.iana_timezone;
    const lastPublishingDateMoment = moment(
      getDateForDateStringInTimezone({
        dayString: lastPublishingDateStr,
        timezone
      })
    );
    const today = moment().tz(timezone);
    return wrapSuccess(today.isSameOrAfter(lastPublishingDateMoment, 'day'));
  }

  private async areCompletionConditionsMet(): Promise<
    ResponseOrError<boolean>
  > {
    const {
      response: lastPublishingDateHasElapsed,
      error: lastPublishingDateError
    } = await this.hasLastPublishingDateElapsed();
    if (lastPublishingDateError) {
      return wrapError(lastPublishingDateError);
    }

    return wrapSuccess(lastPublishingDateHasElapsed && this.isConfirmed);
  }
}
