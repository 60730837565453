import { Badge } from 'lib/components/Badge';
import { Product } from 'lib/enums';
import { centsToDollarsString } from 'lib/helpers';
import { NewspaperOrderModel } from 'lib/model/objects/newspaperOrderModel';
import { OrderModel } from 'lib/model/objects/orderModel';
import { isAnonymousOrder } from 'lib/types/order';
import { selectIsPublisher, selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import AdDetailsCard from 'routes/notice/AdDetailsCard';
import LoadingState from 'components/LoadingState';
import { ColumnService } from 'lib/services/directory';
import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import { isObituaryModel } from 'lib/model/objects/adModel';
import { OrderDetailModel } from 'lib/model/objects/orderDetailModel';
import { FuneralHomeDetails } from './FuneralHomeDetails';
import { PublicationDetails } from './PublicationDetails';
import { DeceasedDetailsCard } from './deceasedDetails';
import { AdDetailHeader } from './AdDetailHeader';
import { useOrderEditableData } from '../place/hooks/useOrderEditableData';
import { AdDetailsCardRow } from './AdDetailsCardRow';

type ObituaryDetailProps = {
  order: OrderModel;
  orderDetail: OrderDetailModel;
  newspaperOrders: NewspaperOrderModel[];
  anonymousUserAuthInfo: { authEmail: string; accessCode: string } | undefined;
};

export function ObituaryDetail({
  order,
  orderDetail,
  newspaperOrders,
  anonymousUserAuthInfo
}: ObituaryDetailProps) {
  const userSnap = useAppSelector(selectUser);
  const isPublisher = useAppSelector(selectIsPublisher);

  const { value: obituary, isLoading: loadingObituary } = useSafeAsyncEffect({
    fetchData: async () => order.getAdByVersion(),
    dependencies: [order.id, order.modelData.activeVersion]
  });

  const editData = useOrderEditableData({
    userSnap,
    order,
    newspaperOrders: newspaperOrders.map(no => no.modelData)
  });

  if (loadingObituary || !obituary || !isObituaryModel(obituary)) {
    return <LoadingState context={{ service: ColumnService.OBITS }} />;
  }

  return (
    <div className="grid grid-cols-12 gap-3 pb-6">
      <div className="col-span-12 flex justify-between lg:py-4">
        <AdDetailHeader
          title={obituary.modelData.deceasedName}
          order={order}
          orderDetail={orderDetail}
          editData={editData}
          anonymousUserAuthInfo={anonymousUserAuthInfo}
        />
      </div>

      <div className="lg:col-span-8 col-span-12 space-y-3">
        <AdDetailsCard
          id="publication-details"
          header={{
            title: (
              <>
                <span className="mr-4">Publication Details</span>
                <Badge status={'info'} size="md">
                  {isAnonymousOrder(order.modelData)
                    ? 'Individual'
                    : 'Funeral Home'}
                </Badge>
              </>
            )
          }}
        >
          {newspaperOrders.map(newspaperOrder => (
            <PublicationDetails
              key={newspaperOrder.id}
              newspaperOrder={newspaperOrder}
              ad={obituary}
              editData={editData?.[newspaperOrder.id]}
            />
          ))}
        </AdDetailsCard>
        {isPublisher && (
          <FuneralHomeDetails obituary={obituary} order={order} />
        )}
      </div>

      <div className="lg:col-span-4 col-span-12 flex flex-col gap-3">
        <AdDetailsCard id="order-details" header={{ title: 'Order Details' }}>
          <AdDetailsCardRow label="Order no" value={order.id} />
          <AdDetailsCardRow
            label="Price"
            value={
              orderDetail.modelData.pricing?.totalInCents !== undefined
                ? `$${centsToDollarsString(
                    orderDetail.modelData.pricing?.totalInCents
                  )}`
                : ''
            }
          />
        </AdDetailsCard>
        {order.modelData.product === Product.Obituary && (
          <DeceasedDetailsCard
            obituary={obituary}
            newspaperOrders={newspaperOrders}
          />
        )}
        {isAnonymousOrder(order.modelData) && isPublisher && (
          <AdDetailsCard
            id="customer-details"
            header={{ title: 'Customer Details' }}
          >
            <AdDetailsCardRow
              label="Name"
              value={`${order.modelData.firstName} ${order.modelData.lastName}`}
            />
            <AdDetailsCardRow
              label="Email"
              value={order.modelData.contactEmail}
            />
            <AdDetailsCardRow label="Phone" value={order.modelData.phone} />
          </AdDetailsCard>
        )}
      </div>
    </div>
  );
}
