import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push, Push } from 'connected-react-router';
import { matchPath } from 'react-router';

import {
  ESnapshot,
  EUser,
  EOrganization,
  ERate,
  ESnapshotExists,
  exists
} from 'lib/types';

import EModal from 'components/modals/EModal';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { EReduxState } from 'redux/types';
import AuthActions from 'redux/auth';
import LoadingState from 'components/LoadingState';

import { logAndCaptureException } from 'utils';
import { useFirestoreDocumentListener } from 'lib/frontend/hooks/useFirestoreDocumentListener';
import { safeStringify } from 'lib/utils/stringify';
import { removeUndefinedFields } from 'lib/helpers';
import { ColumnService } from 'lib/services/directory';
import InvoiceForm from './InvoiceForm';
import {
  getActiveDiscountConfigForNotice,
  getUpdatedDiscountLineItemsForNotice,
  wereDiscountLineItemsChanged
} from '../../../../lib/notice/discounts';
import { getFirebaseContext } from '../../../../utils/firebase';

const mapStateToProps = (state: EReduxState) => ({
  organization: state.auth.organization,
  activeOrganization: state.auth.activeOrganization,
  availableOrganizations: state.auth.availableOrganizations,
  id: matchPath<{ id: string }>(state.router.location.pathname, {
    path: '/:path(notice|publish)/:id/invoice/create',
    exact: true,
    strict: false
  })?.params?.id,
  state
});

const mapDispatchToProps = (dispatch: any) => ({
  authActions: bindActionCreators(AuthActions, dispatch),
  push: (path: any) => dispatch(push(path))
});

export type OldCreateInvoiceProps = {
  id: string | undefined;
  user: ESnapshot<EUser>;
  push: Push;
  authActions: typeof AuthActions;
  organization: ESnapshot<EOrganization> | null;
  activeOrganization: ESnapshot<EOrganization> | null;
  availableOrganizations: ESnapshotExists<EOrganization>[];
  state: any;
};

function CreateInvoice({
  id,
  push,
  activeOrganization,
  availableOrganizations,
  authActions
}: OldCreateInvoiceProps) {
  const [advertiserSnap, setAdvertiserSnap] = useState<
    ESnapshotExists<EUser>
  >();
  const [rateSnap, setRateSnap] = useState<ESnapshotExists<ERate>>();
  const [newspaperSnap, setNewspaperSnap] = useState<
    ESnapshotExists<EOrganization>
  >();
  const [lastModifiedDate, setLastModifiedDate] = useState<number>();
  const [needRefresh, setNeedRefresh] = useState(false);
  const [openPostModal, setOpenPostModal] = useState<boolean>();

  const noticeSnap = useFirestoreDocumentListener(
    id ? getFirebaseContext().userNoticesRef().doc(id) : undefined
  );

  const getRelatedFields = async () => {
    if (!exists(noticeSnap)) {
      return;
    }

    const [rateSnap, advertiserSnap, newspaperSnap] = await Promise.all([
      noticeSnap.data().rate.get(),
      noticeSnap.data().filer.get(),
      noticeSnap.data().newspaper.get()
    ]);

    const newLastModifiedDate = (
      noticeSnap.data().editedAt || noticeSnap.data().confirmedAt
    ).toMillis();

    if (!lastModifiedDate || newLastModifiedDate === lastModifiedDate)
      setLastModifiedDate(newLastModifiedDate);
    else setNeedRefresh(true);

    if (exists(rateSnap)) {
      setRateSnap(rateSnap);
    }
    if (exists(advertiserSnap)) {
      setAdvertiserSnap(advertiserSnap);
    }
    if (exists(newspaperSnap)) {
      setNewspaperSnap(newspaperSnap);
    }
  };

  const updateDiscountLineItems = async () => {
    if (!exists(noticeSnap)) {
      return;
    }
    const { pricing } = noticeSnap.data();
    if (!pricing) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        new Error('No pricing on notice'),
        'Returning early in updateDiscountLineItems',
        { noticeId: noticeSnap.id }
      );
      return;
    }

    const ctx = getFirebaseContext();
    const discountConfig = await getActiveDiscountConfigForNotice(
      ctx,
      noticeSnap.data()
    );

    const updatedLineItems = getUpdatedDiscountLineItemsForNotice(
      discountConfig,
      pricing.lineItems
    );

    const changed = wereDiscountLineItemsChanged(
      pricing.lineItems,
      updatedLineItems
    );
    if (changed) {
      await noticeSnap.ref.update(
        removeUndefinedFields({
          pricing: {
            ...pricing,
            lineItems: updatedLineItems
          }
        })
      );
    }
  };

  useEffect(() => {
    void (async () => {
      await updateDiscountLineItems();
    })();
  }, [safeStringify(noticeSnap?.data()?.pricing)]);

  useEffect(() => {
    if (!exists(noticeSnap)) {
      return;
    }
    void getRelatedFields();
    if (noticeSnap.data().postWithoutFormatting)
      setOpenPostModal(noticeSnap.data().postWithoutFormatting);
  }, [safeStringify(noticeSnap?.data())]);

  useEffect(() => {
    if (
      exists(newspaperSnap) &&
      exists(activeOrganization) &&
      newspaperSnap.id !== activeOrganization.id
    ) {
      authActions.setActiveOrganization(
        availableOrganizations.find(o => o.id === newspaperSnap.id)
      );
    }
  }, [newspaperSnap?.id, activeOrganization?.id]);

  if (
    !exists(noticeSnap) ||
    !exists(advertiserSnap) ||
    !exists(rateSnap) ||
    !exists(newspaperSnap) ||
    newspaperSnap?.id !== activeOrganization?.id
  ) {
    return (
      <LoadingState
        context={{
          location: 'CreateInvoice',
          tags: {
            noticeId: noticeSnap?.id ?? ''
          }
        }}
      />
    );
  }

  return (
    <>
      <InvoiceForm
        forward={() => {
          if (!window.location.pathname.includes('/notices/')) {
            push(`/notice/${id}`);
          }
        }}
        back={() => {
          push(`/notice/${id}`);
        }}
        rateSnap={rateSnap}
        advertiserSnap={advertiserSnap}
        noticeSnap={noticeSnap}
        newspaperSnap={newspaperSnap}
        forwardLabel="Review &amp; Submit"
      />
      {needRefresh && (
        <EModal
          header="Notice Update"
          body={`The notice content has been updated by another user. 
        In order to submit your invoice, you must refresh this page to see an updated price.`}
          buttonText="Refresh Invoice"
          onConfirm={() => push(window.location.pathname)}
        />
      )}
      {openPostModal && exists(newspaperSnap) && (
        <FreeformCModal
          id="upload-proof"
          header="Upload Proof"
          body={`Before creating an invoice, please upload a final proof for this notice as it will appear in ${
            newspaperSnap.data().name
          }.`}
          setOpen={setOpenPostModal}
        >
          <section>
            <button
              id={`confirm-upload-proof`}
              onClick={() => push(`/place/${id}?step=confirm-ad`)}
              className="bg-blue-200 border border-transparent duration-150 ease-in-out focus:border-blue-500 focus:outline-none focus:shadow-outline-blue font-medium hover:bg-blue-600 leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base text-blue-700 hover:text-white transition w-32"
            >
              Continue
            </button>
          </section>
        </FreeformCModal>
      )}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoice);
