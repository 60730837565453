import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Push, push } from 'connected-react-router';

import { ESnapshot, EUser, exists } from 'lib/types';

import { AuthState } from 'redux/auth';
import LoadingState from 'components/LoadingState';
import { isPayOrderInvoiceData, PayInvoiceData } from 'lib/types/invoices';
import { getUserStripeId } from 'lib/utils/users';
import { logInfo } from 'utils/logger';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { Product } from 'lib/enums';
import { getFirebaseContext } from 'utils/firebase';
import { getOrThrow } from 'lib/utils/refs';
import PayInvoicePage from './PayInvoicePage';
import {
  getSavedPaymentsAccess,
  SavedPaymentsAccess
} from './helpers/getSavedPaymentsAccess';
import { getPayInvoicePaymentData } from './helpers/loadPayInvoiceData';

const mapDispatchToProps = (dispatch: any) => ({
  push: (path: any) => dispatch(push(path))
});

const mapStateToProps = (state: { auth: AuthState }) => ({
  user: state.auth.user
});

export type PayInvoicePaymentMethodsType =
  | 'card'
  | 'saved-card'
  | 'saved-bank'
  | 'saved-ach';

type PayInvoiceProps = {
  push: Push;
  user: ESnapshot<EUser> | null;
};

function PayInvoice({ user }: PayInvoiceProps) {
  const [payInvoiceData, setPayInvoiceData] = useState<PayInvoiceData>();
  const [
    savedPaymentsAccess,
    setSavedPaymentsAccess
  ] = useState<SavedPaymentsAccess>();
  const [userStripeId, setUserStripeId] = useState<string>();
  const enableAuthCapture = !!getLocationParams().get('enableAuthCapture');
  const invoicePricingData = payInvoiceData?.invoicePricingData;
  const [product, setProduct] = useState<Product>(Product.Notice);

  const isLoading =
    invoicePricingData === undefined ||
    payInvoiceData === undefined ||
    savedPaymentsAccess === undefined;

  const onLoadingTimeout = () => {
    logInfo('PayInvoice timed out', {
      isLoading: !!isLoading,
      invoicePricingData: !!invoicePricingData,
      payInvoiceData: !!payInvoiceData,
      savedPaymentsAccess: !!savedPaymentsAccess
    });
  };

  useEffect(() => {
    const loadPayInvoiceData = async () => {
      const invoiceId = window.location.href.split('/')[4];
      const payInvoiceData = await getPayInvoicePaymentData(invoiceId);
      if (!payInvoiceData) {
        throw Error('Failed to load PayInvoice data');
      }
      if (payInvoiceData.invoice.isOrderInvoice()) {
        const orderRef = getFirebaseContext()
          .ordersRef()
          .doc(payInvoiceData.invoice.modelData.order.id);
        const orderSnap = await getOrThrow(orderRef);
        setProduct(orderSnap.data().product);
        /*
         * Include the product in the payInvoiceData object.
         * This allows us to categorize and analyze completed invoice payment logs based on the product.
         */
        if (isPayOrderInvoiceData(payInvoiceData)) {
          payInvoiceData.product = orderSnap.data().product;
        }
      }
      setPayInvoiceData(payInvoiceData);
    };
    void loadPayInvoiceData();
  }, []);

  useEffect(() => {
    const loadUserStripeId = async () => {
      if (!exists(user)) {
        return setUserStripeId(undefined);
      }
      const userActiveOrganization = await user
        .data()
        .activeOrganization?.get();
      const userStripeId = await getUserStripeId(user, userActiveOrganization);
      setUserStripeId(userStripeId);
    };
    void loadUserStripeId();
  }, [user?.id]);

  useEffect(() => {
    if (!payInvoiceData) {
      setSavedPaymentsAccess(undefined);
    } else {
      setSavedPaymentsAccess(
        getSavedPaymentsAccess(payInvoiceData, user, userStripeId)
      );
    }
  }, [user?.id, payInvoiceData?.invoice.id, userStripeId]);

  if (isLoading) {
    return (
      <LoadingState
        context={{ location: 'PayInvoice' }}
        onTimeout={onLoadingTimeout}
      />
    );
  }

  return (
    <PayInvoicePage
      payInvoiceData={payInvoiceData}
      invoicePricingData={invoicePricingData}
      savedPaymentsAccess={savedPaymentsAccess}
      user={user}
      enableAuthCapture={enableAuthCapture}
      product={product}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PayInvoice);
