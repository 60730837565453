import { EQuery, ESnapshotExists } from '../types';

export const assertConfigValueExists = function (
  val: string | undefined
): val is string {
  if (!val) {
    return false;
  }

  return true;
};

export const throwConfigError = function (key: string): never {
  throw new Error(`Config value for ${key} is missing`);
};

/**
 * Helper function to get query results where a key is in an array of values that has
 * length >30. This is used to get around the 30-record limit of firebase's 'in' operator
 */
export const getQueryResultsWhereKeyInArray = async <T, V>(
  query: EQuery<T>,
  key: string,
  values: V[]
): Promise<ESnapshotExists<T>[]> => {
  const queryPromises = values.map(value =>
    query
      .where(key, '==', value)
      .get()
      .then(querySnapshot => querySnapshot.docs)
  );

  // If any promise fails, this will throw. Error handling should be implemented in the callers
  const results = await Promise.all(queryPromises);
  return results.flat();
};
