import CurrencyTextField from 'lib/components/CurrencyTextField';
import { Ad, isClassified } from 'lib/types/ad';
import {
  ClassifiedLocation,
  ForSaleClassified,
  HousingPropertyClassified,
  isForSaleClassified,
  isHousingPropertyClassified
} from 'lib/types/classified';
import { GridInput } from 'lib/components/Card/Grid';
import { TextField } from 'lib/components/TextField';
import { State } from 'lib/enums';
import { ColumnSelect } from 'lib/components/ColumnSelect';

type AdWithExtraFields = ForSaleClassified | HousingPropertyClassified;

export const adHasExtraFields = (
  ad: Partial<Ad>
): ad is Partial<AdWithExtraFields> => {
  const adIsClassified = isClassified(ad);
  return (
    adIsClassified &&
    (isForSaleClassified(ad) || isHousingPropertyClassified(ad))
  );
};

type ExtraFieldInputsProps<T extends Ad> = {
  adData: Partial<T>;
  onChangeAd: (ad: Partial<T>) => void;
};

function ExtraFieldInputs<T extends Ad>({
  adData,
  onChangeAd
}: ExtraFieldInputsProps<T>) {
  if (!adHasExtraFields(adData)) {
    return null;
  }

  const onChangeLocationField = <T extends keyof ClassifiedLocation>(
    key: T,
    value: ClassifiedLocation[T]
  ) => {
    const newLocation = { ...adData.location, [key]: value };
    onChangeAd({ ...adData, location: newLocation });
  };

  return (
    <div className="flex flex-col gap-3">
      <div>
        <h3 className="text-column-gray-500 font-semibold text-sm">
          Price and location
        </h3>
        <div className="text-column-gray-400 text-sm">
          This information can be used to search for your ad online.
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 w-full">
        <GridInput>
          <CurrencyTextField
            id="listing-price"
            initialValue={adData.listingPrice}
            step={isHousingPropertyClassified(adData) ? '1000' : '0.01'}
            size="small"
            onChange={listingPrice => onChangeAd({ ...adData, listingPrice })}
            labelText={
              isHousingPropertyClassified(adData)
                ? 'Price of your property?'
                : 'What is the price of your item?'
            }
            required
            validationMessages={{
              valueMissing: 'The listing price is required for this ad category'
            }}
          />
        </GridInput>
        <GridInput>
          <TextField
            id={`extra-info-city-input`}
            value={adData.location?.city}
            placeholder="City"
            labelText="City"
            type="text"
            size="small"
            onChange={newValue => onChangeLocationField('city', newValue)}
            required={!adData.location?.zipCode}
            validationMessages={{
              valueMissing: 'City or zip code required'
            }}
          />
        </GridInput>
        <GridInput>
          <ColumnSelect
            id={`extra-info-state-input`}
            value={adData.location?.state?.toString()}
            placeholder="State"
            labelText="State"
            allowUndefined
            size="small"
            options={State.items().map(s => ({
              label: s.label,
              value: s.value.toString()
            }))}
            onChange={newState =>
              onChangeLocationField('state', parseInt(newState, 10))
            }
            required={!adData.location?.zipCode}
            validationMessages={{
              valueMissing: 'State or zip code required'
            }}
          />
        </GridInput>
        <GridInput>
          <TextField
            id={`extra-info-zip-input-code`}
            value={adData.location?.zipCode}
            placeholder="Zip Code"
            labelText="Zip Code"
            type="text"
            size="small"
            pattern={'^[0-9]{5}(-[0-9]{4})?$'}
            validationMessages={{
              patternMismatch: 'Please enter a valid zip code',
              valueMissing: 'Zip code or city/state required'
            }}
            required={!adData.location?.city || !adData.location.state}
            onChange={newZip => onChangeLocationField('zipCode', newZip)}
          />
        </GridInput>
      </div>
    </div>
  );
}

export default ExtraFieldInputs;
