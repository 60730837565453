import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import { ExportFormatType, enumToSelectInput } from 'lib/enums';
import {
  BulkDownloadRequest,
  EOrganization,
  ESnapshotExists,
  exists
} from 'lib/types';
import { EnoticeError } from 'lib/errors';

import api from 'api';
import FreeformCModal from 'components/modals/FreeFormCModal';
import {
  SearchableNoticeRecord,
  SearchableNoticeRecordFacet
} from 'lib/types/searchable';
import { areSameDayUTC } from 'lib/helpers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ColumnButton } from 'lib/components/ColumnButton';
import ColumnDatePicker from 'components/ColumnDatePicker';
import { useAppSelector } from 'redux/hooks';
import { selectShowAllOrgsNotices } from 'redux/auth';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { InputAccessories } from 'lib/components/InputAccessories';
import { apiPost } from 'api/typed';
import {
  getDateStringFromElasticTimestamp,
  getPublicationTimestampForElasticQuery
} from 'lib/utils/dates';

type LabeledCheckboxProps = {
  id: string;
  text: string;
  checked: boolean;
  onChange: () => void;
};

function LabeledCheckbox({
  id,
  text,
  checked,
  onChange
}: LabeledCheckboxProps) {
  return (
    <label
      htmlFor={id}
      aria-controls={id}
      className="flex flex-row items-center gap-2"
    >
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="h-4 w-4"
      />
      <span className="text-sm">{text}</span>
    </label>
  );
}

type BulkDownloadModalProps = {
  setOpen: (value: boolean) => unknown;

  /**
   * Either the active organization when in the notice table or
   * the newspaper associated with a publicationIssue when in the pagination table
   */
  organization: ESnapshotExists<EOrganization> | null;

  /** Lock the modal to a specific publication date (YYYY-MM-DD) associated with a publicationIssue */
  publicationDate?: string;
};

const GENERIC_ERROR = 'Something went wrong. Please try again.';

function BulkDownloadModal({
  setOpen,
  organization,
  publicationDate
}: BulkDownloadModalProps) {
  const inAllOrganizationsView = useAppSelector(selectShowAllOrgsNotices);

  const defaultSettings = organization?.data().bulkDownload?.defaultSettings;

  // When the modal is locked to a given publication date,
  // we should download all notices for that publication issue,
  // which is linked to a single organization
  const showAllOrgsNotices = inAllOrganizationsView && !publicationDate;

  const [linerFormat, setLinerFormat] = useState(
    defaultSettings?.linerFormat ?? ExportFormatType.pdf.value
  );
  const [displayFormat, setDisplayFormat] = useState(
    defaultSettings?.displayFormat ?? ExportFormatType.jpg.value
  );
  // List of timestamps used to show enabled dates in the datepicker
  const [relevantTimestamps, setRelevantTimestamps] = useState<number[]>([]);

  const DATE_FORMAT = 'YYYY-MM-DD';
  // The selected date in the bulk download modal
  const [downloadDate, setDownloadDate] = useState(
    publicationDate ?? moment().format(DATE_FORMAT)
  );
  const [requiresConfirmation, setRequiresConfirmation] = useState<
    {
      noticeName?: string;
      advertiserName?: string;
    }[]
  >();

  const [fetching, setFetching] = useState(false);
  const [fetchingPaidNotices, setFetchingPaidNotices] = useState(true);
  const [error, setError] = useState('');
  const [loadingTimestamps, setLoadingTimestamps] = useState(true);
  const [downloadURL, setDownloadURL] = useState('');

  // Future TODO: what should the behavior be in the All Orgs view?
  const allowGroupByNoticeType = !!organization?.data()?.allowedNotices?.length;

  const [groupByNoticeType, setGroupByNoticeType] = useState(
    defaultSettings?.grouping === 'liners_by_notice_type'
  );

  const [
    downloadOnlyConfirmedAndPaidDisplays,
    setDownloadOnlyConfirmedAndPaidDisplays
  ] = useState(defaultSettings?.downloadOnlyConfirmedAndPaidDisplays);

  // Future TODO: ditto above comment- might get inconsistent views depending on the last selected organization
  // when in the All Orgs view
  const hasBulkTemplate =
    !!organization?.data()?.fullPageTemplate ||
    !!organization?.data()?.twoColumnFullPageTemplate;

  const defaultBulkFormats = defaultSettings?.bulkFormats;
  const [exportIdml, setExportIdml] = useState(
    hasBulkTemplate && defaultBulkFormats?.idml !== false
  );
  const [exportRtf, setExportRtf] = useState(!!defaultBulkFormats?.rtf);
  const [exportDocx, setExportDocx] = useState(!!defaultBulkFormats?.docx);
  const [exportTxt, setExportTxt] = useState(!!defaultBulkFormats?.txt);

  useEffect(() => {
    if (exportDocx) {
      setExportRtf(true);
    }
  }, [exportDocx]);

  useEffect(() => {
    setError('');
  }, [linerFormat, displayFormat, downloadDate]);

  const getAllUniquePublishingDays = async () => {
    const facets: SearchableNoticeRecordFacet = {
      publicationtimestamps: {
        type: 'value',
        name: 'dates',
        size: 250,
        sort: { value: 'desc' }
      }
    };
    const filters = [
      { iscancelled: Number(false) },
      { isdraft: Number(false) }
    ];

    const {
      facets: { publicationtimestamps }
    } = await api.post('search/usernotices/facets', {
      search: '',
      showAllOrgsNotices,
      facets,
      filters
    });
    const relevantTimestamps = publicationtimestamps[0].data
      .map((v: { value: string }) => Number(v.value))
      .sort();

    const currentTimestamp = Date.now();
    const timestamp =
      relevantTimestamps.find(
        (timestamp: number) => timestamp > currentTimestamp
      ) || relevantTimestamps[relevantTimestamps.length - 1];

    setDownloadDate(getDateStringFromElasticTimestamp({ timestamp }));
    setRelevantTimestamps(relevantTimestamps);
    setLoadingTimestamps(false);
  };

  useEffect(() => {
    if (publicationDate) {
      // If the timestamp is set, no need to get publishing days
      setLoadingTimestamps(false);
      setRelevantTimestamps([
        getPublicationTimestampForElasticQuery({ dayString: publicationDate })
      ]);
      return;
    }

    void getAllUniquePublishingDays();
  }, []);

  const disableDate = (dateIn: MaterialUiPickersDate) => {
    if (!dateIn) {
      return false;
    }

    return relevantTimestamps.every(
      relevantTimestamp => !areSameDayUTC(dateIn, relevantTimestamp)
    );
  };

  const handleDownload = async (downloadOnlyPaidNotices?: boolean) => {
    if (!exists(organization)) {
      return;
    }

    const filters = [
      {
        publicationtimestamps: [
          getPublicationTimestampForElasticQuery({ dayString: downloadDate })
        ]
      },
      { isdraft: [Number(false)] },
      { iscancelled: Number(false) }
    ];

    const PAGE_SIZE = 1000;
    const MAX_PAGES = 10;
    let noticeIds: string[] = [];
    for (let page = 1; page <= MAX_PAGES; page++) {
      // eslint-disable-next-line no-await-in-loop
      const resp = await apiPost('search/usernotices', {
        search: '',
        filters,
        size: PAGE_SIZE,
        current: page,
        showAllOrgsNotices,
        isPublisher: true,
        activeOrganizationId: organization.id
      });

      const { results } = resp;
      const noticeIdsForPage = results.map(
        (hit: SearchableNoticeRecord) => hit.id
      );
      noticeIds = noticeIds.concat(noticeIdsForPage);
      if (noticeIdsForPage.length < PAGE_SIZE) {
        break;
      }
    }

    const timer = setTimeout(() => {
      setFetching(false);
      setFetchingPaidNotices(false);
      setError(GENERIC_ERROR);
    }, 95000);

    try {
      const requestData: BulkDownloadRequest = {
        activeOrganizationId: organization.id,
        noticeIds,
        displayFormat,
        linerFormat,
        date: downloadDate,
        downloadOnlyPaidNotices,
        downloadOnlyConfirmedAndPaidDisplays,
        grouping: groupByNoticeType ? 'liners_by_notice_type' : 'all_liners',
        showAllOrgsNotices,
        bulkFormats: {
          idml: exportIdml,
          docx: exportDocx,
          rtf: exportRtf,
          txt: exportTxt
        }
      };

      const { url, errors, noticesRequireConfirmation } = await apiPost(
        'documents/zip',
        requestData
      );

      setDownloadURL(url);

      if (errors && errors.length) {
        throw EnoticeError.withMessage(
          `Unable to retrieve requested filetypes for notices with the following id(s): ${errors
            .map(err => err.noticeId)
            .join(', ')}. Contact help@column.us.`
        );
      }

      if (noticesRequireConfirmation && noticesRequireConfirmation.length) {
        setRequiresConfirmation(noticesRequireConfirmation);
      } else {
        window.open(url);
      }

      setFetching(false);
      setFetchingPaidNotices(false);
    } catch (err) {
      setFetching(false);
      setFetchingPaidNotices(false);
      setError(err instanceof EnoticeError ? err.message : GENERIC_ERROR);
    }
    clearTimeout(timer);
  };

  const noticesAvailable = requiresConfirmation && downloadDate;

  return (
    <FreeformCModal
      header="Bulk Download"
      body={
        requiresConfirmation && requiresConfirmation.length
          ? 'Warning: You’ve marked notice(s) included in this download as requiring upfront payment, but some have not yet been paid.'
          : 'Select a date and file types to download all public notices to be published on a certain day.'
      }
      setOpen={setOpen}
    >
      {requiresConfirmation ? (
        <div className="mt-4">
          <ul className="mt-4 mb-8 list-disc pl-3">
            {requiresConfirmation.map((notice, index) => (
              <li key={index} className="ml-2 text-sm">
                "{notice.noticeName}" filed by {notice.advertiserName}
              </li>
            ))}
          </ul>
          <div className="grid grid-cols-2 gap-4">
            <ColumnButton
              id="download-only-paid-notices"
              type="button"
              primary
              loading={fetchingPaidNotices}
              buttonText={
                noticesAvailable ? 'Download Only Paid Notices' : 'Cancel'
              }
              onClick={() => {
                if (fetching || fetchingPaidNotices) return;
                if (noticesAvailable) {
                  setError('');
                  setFetchingPaidNotices(true);
                  void handleDownload(true);
                } else {
                  setOpen(false);
                }
              }}
            />
            <ColumnButton
              id="download-all-notices"
              type={'button'}
              primary
              onClick={() => {
                if (fetching || fetchingPaidNotices) return;
                setError('');
                setFetching(true);
                void handleDownload(false);
              }}
              loading={fetching}
              buttonText="Download All Notices"
            />
          </div>
          {downloadURL && (
            <p className="mt-4 text-sm text-column-gray-600">
              Having trouble seeing your download?{' '}
              <a
                className="text-blue-600"
                target="_blank"
                href={downloadURL}
                rel="noreferrer"
              >
                Click here
              </a>
            </p>
          )}
          <p
            id="bulk-download-error"
            data-testid="error"
            className="mt-4 text-sm text-column-gray-600"
          >
            {error}
          </p>
        </div>
      ) : (
        <div className="mt-4">
          <div className="grid grid-cols-3 gap-4 mb-6">
            <InputAccessories
              id="publication-date"
              labelText="Publication Date"
            >
              <ColumnDatePicker
                format="yyyy-mm-dd"
                momentFormat="MMM DD"
                placeholderText={'Date'}
                value={downloadDate}
                disabled={!!publicationDate || loadingTimestamps}
                shouldDisableDate={disableDate}
                onChange={(newDate: Date | undefined) => {
                  if (!newDate) {
                    return;
                  }
                  setDownloadDate(moment(newDate).format(DATE_FORMAT));
                }}
              />
            </InputAccessories>

            <ColumnSelect
              options={enumToSelectInput(ExportFormatType)}
              value={`${linerFormat}`}
              onChange={value => setLinerFormat(parseInt(value, 10))}
              id={'linerFormat'}
              labelText={'Liner Ads'}
              disabled={loadingTimestamps}
            />

            <ColumnSelect
              options={enumToSelectInput(ExportFormatType)}
              value={`${displayFormat}`}
              onChange={value => setDisplayFormat(parseInt(value, 10))}
              id={'displayFormat'}
              labelText={'Display Ads'}
              disabled={loadingTimestamps}
            />
          </div>

          {allowGroupByNoticeType && (
            <div className="mt-3 mb-6">
              <p className="text-xs uppercase text-column-gray-700 mb-1">
                Individual File Options
              </p>

              <LabeledCheckbox
                id="group_by_notice_type"
                text="Group by notice type"
                checked={groupByNoticeType}
                onChange={() => setGroupByNoticeType(!groupByNoticeType)}
              />
            </div>
          )}

          <div className="mt-3 mb-6">
            <p className="text-xs uppercase text-column-column-gray-700 mb-1">
              Download Options
            </p>

            <LabeledCheckbox
              id="download_only_confirmed_and_paid_notices"
              text="Download only confirmed and paid display ads"
              checked={!!downloadOnlyConfirmedAndPaidDisplays}
              onChange={() =>
                setDownloadOnlyConfirmedAndPaidDisplays(
                  !downloadOnlyConfirmedAndPaidDisplays
                )
              }
            />
          </div>

          <div className="mt-3 mb-6">
            <p className="text-xs uppercase text-column-gray-700 mb-1">
              Bulk File Formats
            </p>

            <div className="flex flex-row gap-3">
              {/* TODO: We should separate IDML and PDF generation on the backend */}
              {hasBulkTemplate && (
                <LabeledCheckbox
                  id="export_idml"
                  text="IDML & PDF"
                  checked={exportIdml}
                  onChange={() => setExportIdml(!exportIdml)}
                />
              )}

              <LabeledCheckbox
                id="export_rtf"
                text="RTF"
                checked={exportRtf}
                onChange={() => setExportRtf(!exportRtf)}
              />

              <LabeledCheckbox
                id="export_docx"
                text="DOCX"
                checked={exportDocx}
                onChange={() => setExportDocx(!exportDocx)}
              />

              <LabeledCheckbox
                id="export_txt"
                text="TXT"
                checked={exportTxt}
                onChange={() => setExportTxt(!exportTxt)}
              />
            </div>
          </div>

          <ColumnButton
            id="download"
            primary
            buttonText={fetching ? 'Preparing Files' : 'Download'}
            disabled={fetching || loadingTimestamps}
            loading={fetching}
            onClick={() => {
              if (fetching) return;
              setFetching(true);
              setError('');
              void handleDownload();
            }}
            type="button"
          />
          {downloadURL && (
            <p className="mt-4 text-sm text-column-gray-600">
              Having trouble seeing your download?{' '}
              <a
                className="text-blue-600"
                target="_blank"
                href={downloadURL}
                rel="noreferrer"
              >
                Click here
              </a>
            </p>
          )}
          <p
            id="bulk-download-error"
            data-testid="error"
            className="mt-4 text-sm text-column-gray-600"
          >
            {error}
          </p>
        </div>
      )}
    </FreeformCModal>
  );
}

export default BulkDownloadModal;
