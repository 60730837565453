import { EDisplayParams } from '../../types';
import { AdRate, isNoticeRate } from '../../types/rates';
import { getLinesPerInch, getOffset, valueIsNumber } from '../math';
import { floatToP2Float } from '../ui';
import { getSumImageHeightsFromDisplayParams } from './helpers';
import { PricingFunction } from './types';

const getImageLinesForLinerPricing = (
  displayParams: EDisplayParams,
  rate: AdRate
) => {
  const totalImageHeight = getSumImageHeightsFromDisplayParams(displayParams);

  const isNoticeRateRecord = isNoticeRate(rate);

  const rateIdentifier = isNoticeRateRecord
    ? `code ${rate.code}`
    : `${rate.organization.id} ${rate.description}`;

  const linesPerInch = getLinesPerInch(rate, `liner rate (${rateIdentifier})`);

  return Math.ceil(totalImageHeight * linesPerInch);
};

export const priceLine: PricingFunction = options => {
  const { displayParameters, columns, rateRecord, applicableRate } = options;
  const offset = getOffset(rateRecord);

  const { lines } = displayParameters;

  if (!valueIsNumber(lines)) {
    console.warn(
      `rateType=${rateRecord.rateType} but displayParameters.lines=${displayParameters.lines}`
    );
    return 0;
  }

  const numIncludableImageLines = getImageLinesForLinerPricing(
    displayParameters,
    rateRecord
  );

  const netLines = lines - offset + numIncludableImageLines;

  return floatToP2Float(Math.max(netLines, 0) * columns * applicableRate);
};
