import React from 'react';
import { shouldShowPublisherRegistrationOption } from 'layouts/registrationLayout/registrationLayoutHelpers';
import { OccupationType } from 'lib/enums';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import { exists } from 'lib/types';
import AdvertiserRegistrationFooter from '../AdvertiserRegistrationFooter';
import OccupationSelectItem from './OccupationSelectItem';
import OccupationsHeader from './OccupationsHeader';
import otherOrganization from './occupationPreviewImages/otherOrganization.svg';
import government from './occupationPreviewImages/government.svg';
import individual from './occupationPreviewImages/individual.svg';
import lawyer from './occupationPreviewImages/lawyer.svg';

type OccupationProps = {
  occupationType?: number;
  onOccupationTypeChange: (occupationType: number) => void;
  onOccupationSelect: () => void;
  loading?: boolean;
  isDisplayingOnlyOrganizationOccupations?: boolean;
};

export default function Occupations({
  onOccupationTypeChange,
  onOccupationSelect,
  occupationType,
  loading,
  isDisplayingOnlyOrganizationOccupations
}: OccupationProps) {
  const user = useAppSelector(selectUser);
  return (
    <div>
      <OccupationsHeader />
      <div className="flex gap-2 mt-4">
        {exists(user) && shouldShowPublisherRegistrationOption(user) && (
          <OccupationSelectItem
            occupationItemEnum={OccupationType.publishing}
            setOccupationType={onOccupationTypeChange}
            occupationType={occupationType}
            img={otherOrganization}
          />
        )}

        <OccupationSelectItem
          occupationItemEnum={OccupationType.government_official}
          setOccupationType={onOccupationTypeChange}
          occupationType={occupationType}
          img={government}
        />

        <OccupationSelectItem
          occupationItemEnum={OccupationType.lawyer}
          setOccupationType={onOccupationTypeChange}
          occupationType={occupationType}
          img={lawyer}
        />

        <OccupationSelectItem
          occupationItemEnum={OccupationType.other_organization}
          setOccupationType={onOccupationTypeChange}
          occupationType={occupationType}
          img={otherOrganization}
        />

        {!isDisplayingOnlyOrganizationOccupations && (
          <OccupationSelectItem
            occupationItemEnum={OccupationType.individual}
            setOccupationType={onOccupationTypeChange}
            occupationType={occupationType}
            img={individual}
          />
        )}
      </div>
      <AdvertiserRegistrationFooter
        id="occupation-submit"
        backButtonText="Go back"
        nextButtonText="Continue"
        onNextButtonClick={onOccupationSelect}
        loading={loading}
        disableNextButton={!occupationType}
        hideBackButton
      />
    </div>
  );
}
