import { AdjudicationArea } from 'lib/types/adjudicationArea';
import { ColumnButton } from 'lib/components/ColumnButton';
import { useState } from 'react';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { ERef, exists } from 'lib/types';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import ToastActions from 'redux/toast';
import { Alert } from 'lib/components/Alert';
import { getFirebaseContext } from 'utils/firebase';
import { removeUndefinedFields } from 'lib/helpers';
import {
  ADJUDICATION_AREA_SET,
  AdjudicationAreaUpdated
} from 'lib/types/events';
import { selectUser } from 'redux/auth';
import { ColumnService } from 'lib/services/directory';
import { getErrorReporter } from 'lib/utils/errors';
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import {
  fetchAdjudicationAreas,
  getAdjudicationAreasJoinedString,
  groupAdjudicationAreasByParent,
  shouldDisplayAddButton
} from './helper';
import AdjudicationSectionHeader from './AdjudicationSectionHeader';
import { AdjudicationInfoContent } from './AdjudicationInfoContent';

export type CountyMap = Record<string, { name: string; places: string[] }>;

export type StateMap = {
  name: string;
  counties: CountyMap;
};

export default function AdjudicationInfo() {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const user = useAppSelector(selectUser);
  const activeOrganization = useActiveOrganizationListener();
  const adjudicationAreasIdJoinedString = getAdjudicationAreasJoinedString(
    activeOrganization?.data()
  );

  const [selectedAreasGroupedByState, setAreasGroupedByState] = useState<
    Record<string, StateMap>
  >({});

  const {
    value: adjudicationAreasWithId,
    isLoading: loadingAreas,
    isError: areasError
  } = useAsyncEffect({
    fetchData: async () => {
      if (!exists(activeOrganization)) {
        return;
      }

      const adjudicationAreasWithId = await fetchAdjudicationAreas(
        activeOrganization
      );

      const groupedAreasByState = await groupAdjudicationAreasByParent(
        adjudicationAreasWithId
      );

      setAreasGroupedByState(groupedAreasByState);
      return adjudicationAreasWithId;
    },
    dependencies: [adjudicationAreasIdJoinedString], // using a concatened string to flag when adjudication areas change
    errorConfig: {
      message: 'Failed to load adjudication areas',
      service: ColumnService.SETTINGS_MANAGEMENT,
      tags: {
        activeOrganizationId: activeOrganization?.id || ''
      }
    }
  });

  const handleModalUpdate = async (
    adjudicationAreas: ERef<AdjudicationArea>[]
  ) => {
    try {
      if (!exists(activeOrganization)) {
        return;
      }
      if (!user) {
        getErrorReporter().logAndCaptureError(
          ColumnService.SETTINGS_MANAGEMENT,
          null,
          'Failed to save adjudication areas - missing user',
          {
            activeOrganizationId: activeOrganization?.id || ''
          }
        );
        return;
      }
      await getFirebaseContext()
        .eventsRef<AdjudicationAreaUpdated>()
        .add(
          removeUndefinedFields({
            type: ADJUDICATION_AREA_SET,
            publisher: activeOrganization.ref,
            createdAt: getFirebaseContext().timestamp(),
            data: {
              before: activeOrganization.data()?.adjudicationAreas,
              after: adjudicationAreas,
              changedBy: user?.ref
            }
          })
        );
      await activeOrganization.ref.update({
        adjudicationAreas
      });

      dispatch(
        ToastActions.toastSuccess({
          headerText: 'Success',
          bodyText: 'Adjudication areas updated successfully.'
        })
      );
    } catch (err) {
      getErrorReporter().logAndCaptureError(
        ColumnService.SETTINGS_MANAGEMENT,
        err,
        'Failed to save adjudication areas',
        {
          activeOrganizationId: activeOrganization?.id || ''
        }
      );
      dispatch(
        ToastActions.toastError({
          headerText: 'Error',
          bodyText:
            'Something wrong happened - adjudication areas were not updated.'
        })
      );
    }
  };

  if (!selectedAreasGroupedByState) return null;

  const displayAddButton = shouldDisplayAddButton(activeOrganization?.data());
  const displayEditButton = !displayAddButton;

  return (
    <div className="w-full p-4 mx-auto max-w-4xl">
      <div className="bg-white border border-column-gray-100 shadow-column-3 rounded-md p-8 flex flex-col gap-4">
        <AdjudicationSectionHeader header="Adjudication Area">
          {displayEditButton && (
            <ColumnButton
              onClick={() => setOpen(true)}
              buttonText="Edit"
              type="button"
              rounded="all"
            />
          )}
        </AdjudicationSectionHeader>

        <Alert
          id="eedition-drawer-missing-upload-alert"
          status="info"
          icon={<InformationCircleIcon className="w-5 h-5" />}
          title="Where do you publish public notices?"
          description="Let customers know how to find and submit notices to your
              publication."
        />
        <AdjudicationInfoContent
          loading={loadingAreas}
          error={areasError}
          open={open}
          selectedAreasGroupedByState={selectedAreasGroupedByState}
          adjudicationAreasData={adjudicationAreasWithId}
          onAdjudicationAreasSelectionSaved={handleModalUpdate}
          onModalStateChanged={(open: boolean) => setOpen(open)}
          displayAddButton={displayAddButton}
        />
      </div>
    </div>
  );
}
