import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { capitalize } from 'lodash';
import { State } from 'lib/enums';
import { ColumnButton } from 'lib/components/ColumnButton';
import CheckboxGroup from 'lib/components/Checkbox/CheckboxGroup';

type PublisherCardProps = {
  newspaper: ESnapshotExists<EOrganization>;
  publishingMediums: PublishingMedium[];
  validPublishingMediums: PublishingMedium[];
  onPublishingMediumsChange: (value: PublishingMedium) => void;
  onRemovePublisher: () => void;
  required?: boolean;
};

function PublisherCard({
  newspaper,
  validPublishingMediums,
  publishingMediums,
  onPublishingMediumsChange,
  onRemovePublisher,
  required
}: PublisherCardProps) {
  const publishingMediumDescriptions = {
    [PublishingMedium.Print]:
      'Delivered to doorsteps and mailboxes across the country',
    [PublishingMedium.Online]: `Published online${
      newspaper.data().website ? ` at ${newspaper.data().website}` : ''
    }`,
    [PublishingMedium.EEdition]: `Available online in ${
      newspaper.data().name
    }'s digital e-edition`
  };
  const publishingMediumVerbs = {
    [PublishingMedium.Print]: 'in print',
    [PublishingMedium.Online]: 'on their website',
    [PublishingMedium.EEdition]: 'in a digital e-edition'
  };
  const validPublishingMethods = validPublishingMediums.map(
    medium => publishingMediumVerbs[medium]
  );
  const publishingMethodDescription =
    validPublishingMethods.length > 1
      ? `${validPublishingMethods
          .slice(0, -1)
          .join(', ')} and ${validPublishingMethods.slice(-1)}`
      : validPublishingMethods[0];
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <h3 className="text-sm font-bold text-column-gray-500">
          {newspaper.data().name}
        </h3>
        {!required && (
          <ColumnButton
            onClick={onRemovePublisher}
            buttonText="Remove"
            destructive
            size="sm"
            type="button"
          />
        )}
      </div>
      <CheckboxGroup
        id="publishing-medium-selection"
        value={validPublishingMediums.map(medium => ({
          id: medium,
          labelText: capitalize(medium),
          labelDescription: publishingMediumDescriptions[medium],
          checked: publishingMediums.includes(medium)
        }))}
        onChange={(_newValues, { id: medium }) =>
          onPublishingMediumsChange(medium)
        }
        labelText={
          <>
            Based in {newspaper.data().city},{' '}
            {State.by_value(newspaper.data().state)?.label},{' '}
            {newspaper.data().website ? (
              <a
                className="underline text-column-primary-500"
                href={newspaper.data().website}
                target="_blank"
                rel="noreferrer"
              >
                {newspaper.data().name}
              </a>
            ) : (
              newspaper.data().name
            )}{' '}
            publishes {publishingMethodDescription}.
          </>
        }
      />
    </div>
  );
}

export default PublisherCard;
