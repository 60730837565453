import { UserOccupationEnum } from '../enums/OccupationType';
import {
  Customer,
  EAdjudicationArea,
  EInvoice,
  ENotice,
  ENoticeDraft,
  EOrganization,
  EPublicNotice,
  ESnapshot
} from '.';
import { ConfirmationStatus } from '../enums';
import { ClassifiedLocation } from './classified';
import { Order } from './order';
import { NormalizedEntity } from './publicNotice';

export const SORT_ASCENDING = 'asc';
export const SORT_DESCENDING = 'desc';
export type SORT_DIRECTIONS = typeof SORT_ASCENDING | typeof SORT_DESCENDING;

export const MAX_ELASTIC_FACETS_LENGTH = 250;

// Types of documents we sync to Elastic
export const ELASTIC_DOCUMENT_TYPES = [
  'notice',
  'draft',
  'customer',
  'organization',
  'publicNotice',
  'invoice',
  'order',
  'adjudicationArea'
] as const;
export type ElasticDocumentType = typeof ELASTIC_DOCUMENT_TYPES[number];

export type SearchableNoticeRecord = {
  // configuration fields
  id: string;
  filerid: string;
  publisherid: string;
  draftownerid?: string;
  ispublisherdraft?: number;
  filerorganizationid?: string;
  firstpublicationdeadline: number;
  firstpublicationtimestamp: number;
  lastpublicationtimestamp: number;
  publicationtimestamps: number[];
  orginalnoticeid?: string;
  lasteditdate: number;
  affidavitdisabled: number;
  confirmedat: number;
  createtime: number;
  noticetype: number;

  // status fields
  requireupfrontpayment: number;
  affidavitsubmitted: number;
  iscancelled: number;
  confirmationstatus: ConfirmationStatus;
  isconfirmed: number;
  isarchived: number;
  hastransfer: number;
  isdraft: number;
  hasinvoice: number;
  advertiserbillingstatusvalue: number;
  publisherbillingstatusvalue: number;
  showaffidavitoutsidecolumn: number;
  postwithoutformatting: number;

  // TODO: to be removed after BACKEND-298 is complete
  advertiserbillingstatus: string;
  publisherbillingstatus: string;
  billingstatus: number;

  // searchable fields
  publicationdates: string;
  invoicenumber?: string;
  publication: string;
  noticename: string;
  filerorganizationname?: string;
  filername: string;
  fileremail: string;
  customid: string;
  noticetext: string;

  // extra fields
  noticedetailpath: string;
  generatedaffidaviturl: string;
  requireemailaffidavit: number;
  mailaffidavitsoutsidecolumn: number;
  failedsync: number;

  /** Sync status at deadline (if available) */
  syncstatusatdeadline?: number;
  syncfailurestatus?: number;

  /** Sync precondition status (if available) */
  syncpreconditionsuccess?: number;
  syncpreconditionfailurestatus?: number;

  /**
   * Whether or not Column is responsible for affidavit processing for this notice.
   * If true, Column will create an affidavit for this notice.
   */
  affidavitmanagedbycolumn: number;
};

export type SearchableCustomerRecord = {
  // configuration fields
  id: string;
  organizationid: string;
  userid: string;
  verified: number;
  archived: number;

  /**
   * Determine the occupation of the user
   */
  useroccupation: UserOccupationEnum['value'];

  // searchable fields
  useremail: string;
  username: string;
  userorganizationname: string;
  internalid: string;

  // extra fields
  lastsignintime?: number;
};

export type SearchableOrganizationRecord = {
  // configuration fields
  id: string;
  organizationid: string;
  county: string;
  state: number;
  city: string;
  disabled: number;
  /**
   * The organization type (See OrganizationType enum for possible values)
   */
  type: number;
  /**
   * The organization status (See OrganizationStatus enum for possible values)
   */
  status: number;
  /**
   * Whether newspaper will appear for customers in the placement flow dropdown
   */
  isvalidpaper: number;
  /**
   * Whether the organization has active members or not
   */
  isactive: number;
  /**
   * Whether the advertiser organization appear in search when advertiser searches to join org
   */
  issearchable: number;
  isacceptingobituaries: number;
  isacceptingclassifieds: number;
  obituaryimplementationstatus?: string;
  classifiedimplementationstatus?: string;

  // searchable fields
  name: string;
  cities: string[];
  counties: string[];
  states: string[];

  // extra fields
  /**
   * The organization id of this organization's parent organization, if any
   */
  parentid?: string;

  stripeid?: string;
};

export type SearchableOrderRecord = {
  // configuration fields
  id: string;
  orderid: string;
  publisherid: string;
  publishername: string;
  publisherlogo?: string;
  authorizedorgid?: string;
  authorizeduserid?: string;
  advertiseruserid?: string;
  advertiserorgid?: string;
  hasadvertiserorg: number;
  ordertype: string;
  filingtype: string;
  publishingmedium: string;

  // status fields
  newspaperorderstatus: string;
  invoicestatus?: number;
  orderstatus: string;
  hastransfer: number;
  /** Whether the newspaper order matches the current activeVersion property on the order */
  isactive: number;
  isverified?: number;

  // searchable fields
  funeralhomename?: string;
  /** Timestamp of the ad deadline */
  addeadline: number;
  publishingdates: string;
  publishingtimestamps: number[];
  deceasedname?: string;
  contactemail: string;
  filerfirstname: string;
  filerlastname: string;
  content?: string;
  title?: string;

  // extra fields
  publishingdatesarray: string[];
  /** Midnight UTC timestamp of first pub date */
  firstpublishingdate: number;
  /** Midnight UTC timestamp of last pub date */
  lastpublishingdate: number;
  deceasedbirthdate?: number;
  deceaseddeathdate?: number;
  createdat: number;
  /** Midnight UTC timestamp of death verification date */
  verifiedat?: number;
  /** Midnight UTC timestamp of newspaper order status confirmation */
  confirmedat?: number;
  imagepaths: string[];
  proofstoragepath?: string;
  height?: number;
  width?: number;
  columns?: number;
  price?: number;
  locationzipcode?: ClassifiedLocation['zipCode'];
  locationcity?: ClassifiedLocation['city'];
  /** We convert the state enum value to the label when syncing to Elastic */
  locationstate?: string;
};

export type AdjucationAreaRecord = {
  // configuration fields
  id: string;
  name: string;
  fipsCode: string;
  type: string;
  parent: string;
};

export type ProductSpecificSearchableOrderData = Pick<
  SearchableOrderRecord,
  'content' | 'title' | 'createdat' | 'imagepaths' | 'proofstoragepath'
> & {
  isverified?: SearchableOrderRecord['isverified'];
  deceaseddeathdate?: SearchableOrderRecord['deceaseddeathdate'];
  deceasedbirthdate?: SearchableOrderRecord['deceasedbirthdate'];
  deceasedname?: SearchableOrderRecord['deceasedname'];
  funeralhomename?: SearchableOrderRecord['funeralhomename'];
  verifiedat?: SearchableOrderRecord['verifiedat'];
  price?: SearchableOrderRecord['price'];
  locationzipcode?: SearchableOrderRecord['locationzipcode'];
  locationcity?: SearchableOrderRecord['locationcity'];
  locationstate?: SearchableOrderRecord['locationstate'];
};

// Type of public notice record sent to Elastic
export type ElasticSearchablePublicNoticeRecord = {
  // configuration fields
  id: string;
  publishedtimestamp?: number;

  // searchable fields
  text: string;
  noticetype: string;
  newspapername: string;
  county: string;
  state: string;

  // extra fields
  normalizedentities?: NormalizedEntity | NormalizedEntity[];
  geoloc?: {
    lat: number;
    lng: number;
  };
  pdfurl: string;
  combo?: number;
  filer?: string;
};

// Type of public notice record received from Elastic
export type SearchablePublicNoticeRecord = ElasticSearchablePublicNoticeRecord & {
  highlighted_text?: string;
};

export type SearchableInvoiceRecord = {
  // configuration fields
  id: string;
  filerid: string;
  publisherid: string;
  isbulkinvoice: number;
  filerorganizationid?: string;

  // searchable fields
  filername: string;
  fileremail: string;
  invoicenumber: string;
  formattedduedate: string;
  formattednettotal: string;
  lineitemdescriptions: string[];
  filerorganizationname: string;
  publishername: string;
  noticename?: string;
  noticeid?: string;
  stripecustomerid: string; // cus_ stripe id
  stripeinvoiceid: string; // inv_ stripe id
  stripecheckids: string[]; // pchk_ ids, empty array if no checks

  // extra fields
  invoiceoutsidecolumn: number;
  voided: number;
  status: number;
  paid: number;
  created: number;
  duedate: number;
  nettotal: number;
};

export type SearchableAdjudicationAreaRecord = {
  // configuration fields
  id: string;
  name: string;
  fipscode: string;
  type: string;
  parentid: string;
  parentname: string;
  parentfipscode: string;
};

export type SearchableRecord =
  | SearchableNoticeRecord
  | SearchableCustomerRecord
  | SearchableOrganizationRecord
  | SearchablePublicNoticeRecord
  | SearchableInvoiceRecord
  | SearchableOrderRecord
  | SearchableAdjudicationAreaRecord;

export const isSearchableCustomerRecord = (
  record: SearchableRecord
): record is SearchableCustomerRecord => {
  const requiredFields: (keyof SearchableCustomerRecord)[] = [
    'userid',
    'organizationid',
    'verified'
  ];
  if (
    requiredFields.every(
      requiredField =>
        (record as SearchableCustomerRecord)[requiredField] !== undefined
    )
  ) {
    return true;
  }
  return false;
};

export const isSearchableOrderRecord = (
  record: SearchableRecord
): record is SearchableOrderRecord => {
  const requiredFields: (keyof SearchableOrderRecord)[] = [
    'orderid',
    'publisherid',
    'newspaperorderstatus'
  ];
  if (
    requiredFields.every(
      requiredField =>
        (record as SearchableOrderRecord)[requiredField] !== undefined
    )
  ) {
    return true;
  }
  return false;
};

// https://www.elastic.co/guide/en/app-search/8.8/api-reference.html
type ElasticSearchResultsBase = {
  results: SearchableRecord[];
  page: {
    current: number;
    total_pages: number;
    total_results: number;
    size: number;
  };
};

export type ElasticCustomerSearchResults = ElasticSearchResultsBase & {
  results: SearchableCustomerRecord[];
};

export type ElasticOrderSearchResults = ElasticSearchResultsBase & {
  results: SearchableOrderRecord[];
};

export type ElasticNoticeSearchResults = ElasticSearchResultsBase & {
  results: SearchableNoticeRecord[];
};

export type ElasticOrganizationSearchResults = ElasticSearchResultsBase & {
  results: SearchableOrganizationRecord[];
};

export type ElasticPublicNoticeSearchResults = ElasticSearchResultsBase & {
  results: SearchablePublicNoticeRecord[];
};

export type ElasticInvoiceSearchResults = ElasticSearchResultsBase & {
  results: SearchableInvoiceRecord[];
};

export type ElasticAdjudicationAreaSearchResults = ElasticSearchResultsBase & {
  results: SearchableAdjudicationAreaRecord[];
};

export type ElasticSearchResults =
  | ElasticCustomerSearchResults
  | ElasticNoticeSearchResults
  | ElasticOrganizationSearchResults
  | ElasticPublicNoticeSearchResults
  | ElasticInvoiceSearchResults
  | ElasticOrderSearchResults
  | ElasticAdjudicationAreaSearchResults;

type FacetResults = {
  type: 'value' | 'range';
  name: string;
  data: {
    value: string | number;
    count: number;
  }[];
};

export type ElasticPublicNoticeFacetsResults = {
  [K in keyof SearchablePublicNoticeRecord]: FacetResults[];
};

export type ElasticNoticeFacetsResults = {
  [K in keyof SearchableNoticeRecord]: FacetResults[];
};

export type ElasticOrganizationFacetsResults = {
  [K in keyof SearchableOrganizationRecord]: FacetResults[];
};

export type ElasticCustomerFacetsResults = {
  [K in keyof SearchableCustomerRecord]: FacetResults[];
};

export type ElasticOrderFacetsResults = {
  [K in keyof SearchableOrderRecord]: FacetResults[];
};

export type ElasticAdjudicationAreaFacetsResults = {
  [K in keyof AdjucationAreaRecord]: FacetResults[];
};

export type ElasticFacetsResults =
  | ElasticPublicNoticeFacetsResults
  | ElasticNoticeFacetsResults
  | ElasticOrganizationFacetsResults
  | ElasticCustomerFacetsResults
  | ElasticOrderFacetsResults
  | ElasticAdjudicationAreaFacetsResults;

type ValueOrArray<T> = T | T[] | [null];

/**
 * See: https://www.elastic.co/guide/en/app-search/current/filters.html#filters-value-filters
 */
export type SearchableNoticeRecordValueFilter = Partial<
  {
    [K in keyof SearchableNoticeRecord]: ValueOrArray<
      SearchableNoticeRecord[K]
    >;
  }
>;

type SearchableValue<
  K extends keyof SearchableNoticeRecord
> = SearchableNoticeRecord[K] extends Array<infer T>
  ? T
  : SearchableNoticeRecord[K];

type SearchableOrderValue<
  K extends keyof SearchableOrderRecord
> = SearchableOrderRecord[K] extends Array<infer T>
  ? T
  : SearchableOrderRecord[K];

/**
 * See:
 * https://www.elastic.co/guide/en/app-search/current/filters.html#filters-range-filters
 */
export type SearchableNoticeRecordRangeFilter = Partial<
  {
    [K in keyof SearchableNoticeRecord]: {
      from?: SearchableValue<K>;
      to?: SearchableValue<K>;
    };
  }
>;

export type SearchableNoticeRecordFilter =
  | SearchableNoticeRecordValueFilter
  | SearchableNoticeRecordRangeFilter;

export type SearchableOrganizationRecordFilter = Partial<
  {
    [K in keyof SearchableOrganizationRecord]: ValueOrArray<
      SearchableOrganizationRecord[K]
    >;
  }
>;

export type SearchableCustomerRecordFilter = Partial<
  {
    [K in keyof SearchableCustomerRecord]: ValueOrArray<
      SearchableCustomerRecord[K]
    >;
  }
>;

type SearchableOrderRecordValueFilter = Partial<
  {
    [K in keyof SearchableOrderRecord]: ValueOrArray<SearchableOrderRecord[K]>;
  }
>;

type SearchableOrderRecordRangeFilter = Partial<
  {
    [K in keyof SearchableOrderRecord]: {
      from?: SearchableOrderValue<K>;
      to?: SearchableOrderValue<K>;
    };
  }
>;

export type SearchableOrderRecordFilter =
  | SearchableOrderRecordValueFilter
  | SearchableOrderRecordRangeFilter;

export type SearchableInvoiceRecordValueFilter = Partial<
  {
    [K in keyof SearchableInvoiceRecord]: ValueOrArray<
      SearchableInvoiceRecord[K]
    >;
  }
>;

export type SearchableInvoiceRecordRangeFilter = Partial<
  {
    [K in keyof SearchableInvoiceRecord]: {
      from?: SearchableInvoiceRecord[K];
      to?: SearchableInvoiceRecord[K];
    };
  }
>;

export type SearchableInvoiceRecordFilter =
  | SearchableInvoiceRecordValueFilter
  | SearchableInvoiceRecordRangeFilter;

export type SearchablePublicNoticeRecordValueFilter = Partial<
  {
    [K in keyof SearchablePublicNoticeRecord]: ValueOrArray<
      SearchablePublicNoticeRecord[K]
    >;
  }
>;

export type SearchablePublicNoticeRecordRangeFilter = Partial<
  {
    [K in keyof SearchablePublicNoticeRecord]: {
      from?: SearchablePublicNoticeRecord[K];
      to?: SearchablePublicNoticeRecord[K];
    };
  }
>;

export type SearchablePublicNoticeRecordValueOrRangeFilter =
  | SearchablePublicNoticeRecordValueFilter
  | SearchablePublicNoticeRecordRangeFilter;

export type SearchablePublicNoticeRecordFilter =
  | SearchablePublicNoticeRecordValueOrRangeFilter
  | {
      all?: SearchablePublicNoticeRecordValueOrRangeFilter[];
      any?: SearchablePublicNoticeRecordValueOrRangeFilter[];
      none?: SearchablePublicNoticeRecordValueOrRangeFilter[];
    };

export type SearchableAdjudicationAreaRecordFilter = Partial<
  {
    [K in keyof SearchableAdjudicationAreaRecord]: ValueOrArray<
      SearchableAdjudicationAreaRecord[K]
    >;
  }
>;

export type SearchableRecordFilter =
  | SearchableCustomerRecordFilter
  | SearchableOrganizationRecordFilter
  | SearchableNoticeRecordFilter
  | SearchablePublicNoticeRecordFilter
  | SearchableOrderRecordFilter
  | SearchableAdjudicationAreaRecordFilter;

type Facet = {
  type: 'value' | 'range';
  name: string;
  size?: number; // Maximum is 250
  sort?: { value: 'desc' | 'asc' };
  ranges?: { from: number; to: number }[];
};

export type SearchablePublicNoticeRecordFacet = Partial<
  {
    [K in keyof SearchablePublicNoticeRecord]: Facet;
  }
>;

export type SearchableNoticeRecordFacet = Partial<
  {
    [K in keyof SearchableNoticeRecord]: Facet;
  }
>;

export type SearchableOrganizationRecordFacet = Partial<
  {
    [K in keyof SearchableOrganizationRecord]: Facet;
  }
>;

export type SearchableCustomerRecordFacet = Partial<
  {
    [K in keyof SearchableCustomerRecord]: Facet;
  }
>;

export type SearchableOrderRecordFacet = Partial<
  {
    [K in keyof SearchableOrderRecord]: Facet;
  }
>;

export type SearchableRecordFacet =
  | SearchableCustomerRecordFacet
  | SearchableOrganizationRecordFacet
  | SearchableNoticeRecordFacet
  | SearchablePublicNoticeRecordFacet
  | SearchableOrderRecordFacet;

export type ElasticDocumentSnapshot = ESnapshot<
  | ENotice
  | ENoticeDraft
  | Customer
  | EOrganization
  | EPublicNotice
  | EInvoice
  | Order
  | EAdjudicationArea
>;

export type ElasticParams<
  T extends SearchableRecordFilter = SearchableRecordFilter
> = {
  filters: {
    all: T[];
    any?: T[];
    none?: T[];
  };
  page: {
    size: number;
    current?: number;
  };
  sort?: Record<string, SORT_DIRECTIONS>[];
  result_fields?: Record<
    keyof T,
    { snippet?: { size: number }; raw: Record<string, unknown> }
  >;
  search_fields?: Record<string, { weight: number }>;
};
