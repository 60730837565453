import api from 'api';

import { EResponseTypes } from 'lib/types';
import { logAndCaptureException } from 'utils';
import {
  InvoiceType,
  OrderInvoice,
  PayInvoiceData,
  PublicNoticeInvoice
} from 'lib/types/invoices';
import { UserModel } from 'lib/model/objects/userModel';
import { getFirebaseContext } from 'utils/firebase';
import { InvoiceModel } from 'lib/model/objects/invoiceModel';
import { ColumnService } from 'lib/services/directory';

export async function getPayInvoicePaymentData(
  invoiceId: string
): Promise<PayInvoiceData | undefined> {
  try {
    const data: EResponseTypes['invoices/pay-invoice-data'] = await api.get(
      `invoices/${invoiceId}/pay-invoice-data`,
      undefined,
      { Authorization: 'Aloh0mor4' }
    );
    const { serializedInvoice } = data;
    if (data.type === InvoiceType.PUBLIC_NOTICE) {
      const invoice = new InvoiceModel<PublicNoticeInvoice>(
        getFirebaseContext(),
        {
          serialized: serializedInvoice
        }
      );
      const { serializedAdvertiser } = data;
      const advertiser = new UserModel(getFirebaseContext(), {
        serialized: serializedAdvertiser
      });
      return {
        ...data,
        advertiser,
        invoice
      };
    }
    if (data.type === InvoiceType.ORDER) {
      const invoice = new InvoiceModel<OrderInvoice>(getFirebaseContext(), {
        serialized: serializedInvoice
      });
      const { serializedAdvertiser } = data;

      const advertiser = serializedAdvertiser
        ? new UserModel(getFirebaseContext(), {
            serialized: serializedAdvertiser
          })
        : undefined;
      return {
        ...data,
        invoice,
        advertiser,
        orderVersion: invoice.modelData.orderVersion
      };
    }
  } catch (err) {
    logAndCaptureException(
      ColumnService.PAYMENTS,
      err,
      'Failed to load pay invoice data',
      {
        invoiceId
      }
    );
  }
}
