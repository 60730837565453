import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ColumnButton } from '../ColumnButton';
import { Tooltip } from '../Tooltip';
import { columnObjectsAreEqual, safeStringify } from '../../utils/stringify';
import CardWithHeader, { CardWithHeaderProps } from './CardWithHeader';

export type EditableCardMode = 'view' | 'edit';
export type EditableCardProps = {
  mode: EditableCardMode;
  onEdit: () => void;
  onDiscard: () => void;
  disableEdit?: boolean;
  disableSave?: boolean;

  /**
   * Optional property to show the save button with a loading spinner and to disable the discard button
   * while the submit action is running.
   */
  submissionLoading?: boolean;
};

function EditableCardActions({
  disableEdit,
  disableSave,
  onDiscard,
  onEdit,
  mode,
  submissionLoading
}: EditableCardProps) {
  if (mode === 'edit') {
    return (
      <div className="flex gap-4">
        <ColumnButton
          tertiary
          buttonText="Discard"
          type="button"
          onClick={onDiscard}
          disabled={submissionLoading}
        />
        <ColumnButton
          primary
          buttonText="Save"
          type="submit"
          disabled={disableSave}
          loading={submissionLoading}
        />
      </div>
    );
  }

  return (
    <Tooltip
      helpText={
        disableEdit ? 'You do not have permission to edit this section' : ''
      }
      position="topLeft"
    >
      <ColumnButton
        secondary
        disabled={disableEdit}
        buttonText="Edit"
        type="button"
        onClick={onEdit}
      />
    </Tooltip>
  );
}

type CardGridLayoutProps = Omit<CardWithHeaderProps, 'size'> & {
  /**
   * Optional configuration that adds actions to the card, so that it can function as a form.
   * If used, this component should be wrapped in a `<Form>` component that handles submission.
   *
   * - When `mode: 'view'`, this component shows an "Edit" button that fires the `onEdit` method
   * when clicked. The "Edit" button can be disabled with the `disableEdit` flag, which shows a
   * permissions message via `<Tooltip>`. All nested inputs should be `disabled` when in this state.
   *
   * - When `mode: 'edit'`, this component shows "Discard" and "Save" buttons. The "Discard" button
   * fires the `onDiscard` method, which should typically change `mode` to `'view'` and reset the
   * form data among any other cleanup needed. Meanwhile, the "Save" button is `type: 'submit'`, so
   * will trigger any `onSumbit` handlers of a parent `<Form>`.
   *
   */
  editable?: EditableCardProps;
};

export function CardGridLayout({
  children,
  editable,
  header,
  ...cardProps
}: CardGridLayoutProps) {
  const cardHeader = header;

  if (cardHeader && editable) {
    cardHeader.actionable = <EditableCardActions {...editable} />;
  }

  return (
    <div className="w-full">
      <div className="mx-auto max-w-4xl p-4">
        <CardWithHeader header={cardHeader} {...cardProps}>
          <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2">
            {children}
          </div>
        </CardWithHeader>
      </div>
    </div>
  );
}

export function HorizontalDivider({ noPadding }: { noPadding?: boolean }) {
  return (
    <div className="w-full">
      <div className={classNames('mx-auto max-w-4xl', { 'p-4': !noPadding })}>
        <hr className="bg-column-gray-100" />
      </div>
    </div>
  );
}

type GridInputProps = {
  children: React.ReactNode;
  fullWidth?: boolean;
};

/**
 * Wrapper around form inputs to ensure they are the same width.
 */
export function GridInput({ fullWidth, children }: GridInputProps) {
  return (
    <div
      className={classNames('col-span-1', {
        'sm:col-span-2': fullWidth
      })}
    >
      {children}
    </div>
  );
}

export const useEditableCard = <T extends Record<string, unknown> | null>(
  currentSettings: T,
  {
    shouldDisableSave = columnObjectsAreEqual,
    shouldDisableEdit = () => false,
    defaultMode = 'view'
  }: {
    shouldDisableSave?: (formSettings: T, currentSettings: T) => boolean;
    shouldDisableEdit?: (formSettings: T, currentSettings: T) => boolean;
    defaultMode?: EditableCardMode;
  } = {}
) => {
  const [formSettings, setFormSettings] = useState(currentSettings);
  const [mode, setMode] = useState<EditableCardMode>(defaultMode);
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const disabled = mode === 'view';
  const disableSave = shouldDisableSave(formSettings, currentSettings);
  const disableEdit = shouldDisableEdit(formSettings, currentSettings);

  useEffect(() => {
    if (mode === 'view') {
      setFormSettings(currentSettings);
    }
  }, [safeStringify(currentSettings)]);

  const onEdit = () => {
    setMode('edit');
  };

  const onChange = <U extends Partial<T>>(updates: U) => {
    setFormSettings(
      updates == null ? updates : { ...formSettings, ...updates }
    );
  };

  const onDiscard = () => {
    setFormSettings(
      currentSettings == null ? currentSettings : { ...currentSettings }
    );
    setMode('view');
  };

  const onStartSubmission = () => {
    setSubmissionLoading(true);
  };

  // TODO: remove default value for `success` after we stop exporting `onFinishSubmission`
  const onFinishSubmission = (success: boolean) => {
    setSubmissionLoading(false);
    if (success) {
      setMode('view');
    }
  };

  /**
   * TODO: Change this to ResponseOrError return type
   */
  const onSubmitWrapper = async (
    onSubmit: () => { success: boolean } | Promise<{ success: boolean }>
  ) => {
    onStartSubmission();
    const { success } = await onSubmit();
    onFinishSubmission(success);
  };

  return {
    editable: {
      onEdit,
      onDiscard,
      disableSave,
      disableEdit,
      mode,
      submissionLoading
    },
    onChange,
    onSubmitWrapper,
    disabled,
    formSettings
  };
};
