import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { useAppDispatch } from 'redux/hooks';

import { ESnapshot, EUser, EOrganization, ESnapshotExists } from 'lib/types';
import { getBulkInvoiceServicesStatus } from 'layouts/appLayout/appLayoutHelpers';
import LoadingState from 'components/LoadingState';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import InvoiceTable from './InvoicesTable';

function Payments({
  user,
  organization
}: {
  user: ESnapshotExists<EUser>;
  organization: ESnapshot<EOrganization> | null;
}) {
  const [usesBulkInvoiceV2, setUsesBulkInvoiceV2] = useState<
    undefined | boolean
  >();

  useEffect(() => {
    const getBulkInvoicesV2Status = async () => {
      const usesBulkInvoicesV2 = await getBulkInvoiceServicesStatus(user);
      setUsesBulkInvoiceV2(usesBulkInvoicesV2);
    };

    void getBulkInvoicesV2Status();
  }, [user?.id]);

  const showInvoicesTabToAllUsers = getBooleanFlag(
    LaunchDarklyFlags.SHOW_ALL_INVOICES_IN_INVOICES_TABLE,
    true
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (usesBulkInvoiceV2 === false && !showInvoicesTabToAllUsers) {
      dispatch(push('/'));
    }
  }, [usesBulkInvoiceV2, showInvoicesTabToAllUsers]);

  if (typeof usesBulkInvoiceV2 === 'boolean') {
    return <InvoiceTable {...{ user, organization, usesBulkInvoiceV2 }} />;
  }

  return <LoadingState />;
}

export default Payments;
