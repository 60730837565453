import React, { useState } from 'react';
import { TransactionType } from 'lib/enums';
import { toLocaleString } from 'lib/helpers';
import { ESnapshotExists } from 'lib/types/firebase';
import { ENotice } from 'lib/types/notice';
import { Help, WarningOutlined } from '@material-ui/icons';
import {
  PayoutParentRowData,
  PayoutChildRowData,
  PayoutGroup
} from 'lib/types/payout';
import { getFirebaseContext } from 'utils/firebase';
import { Tooltip } from 'lib/components/Tooltip';
import {
  ArrowDownTrayIcon,
  ChevronDownIcon
} from '@heroicons/react/24/outline';

const TableCell: React.FC = ({ children }) => (
  <td className="py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
    {children}
  </td>
);

type PayoutParentRowProps = {
  rowData: PayoutParentRowData;
  expand: boolean;
  payoutSource: 'dwolla' | 'stripe';
  handleExpandClicked: (id: string) => void;
  handleDownloadCsvClicked: (id: string) => void;
};

function PayoutParentRow({
  rowData,
  expand,
  payoutSource,
  handleExpandClicked,
  handleDownloadCsvClicked
}: PayoutParentRowProps) {
  return (
    <tr>
      <TableCell>
        <div className="flex justify-center">
          <ChevronDownIcon
            className={`w-4 h-4 cursor-pointer ${
              !expand ? 'transform -rotate-90' : ''
            }`}
            onClick={() => handleExpandClicked(rowData.id)}
          />
        </div>
      </TableCell>
      {/* This cell is hiding for stripe papers to avoid duplicate minus (-) sign for stripe payouts.
      Stripe payout amount comes with minus (-) sign whereas Dwolla papers does not. And we have to
      manually add it. Same applies to child row. */}
      {payoutSource === 'dwolla' && (
        <TableCell>
          <p
            className={`text-right ${
              rowData.type === TransactionType.processing_fee_deduction.label ||
              rowData.type === TransactionType.refund.label ||
              (rowData.type === TransactionType.payment.label &&
                rowData.status === 'cancelled')
                ? 'text-red-600'
                : 'text-black'
            }`}
          >
            {(rowData.type === TransactionType.processing_fee_deduction.label ||
              rowData.type === TransactionType.refund.label ||
              (rowData.type === TransactionType.payment.label &&
                rowData.status === 'cancelled')) &&
              '-'}
          </p>
        </TableCell>
      )}
      <TableCell>
        <p
          className={`${
            rowData.type === TransactionType.processing_fee_deduction.label ||
            rowData.type === TransactionType.refund.label ||
            (rowData.type === TransactionType.payment.label &&
              rowData.status === 'cancelled')
              ? 'text-red-600'
              : 'text-black'
          }`}
        >
          {rowData.status === 'failed' && (
            <Tooltip
              classes="inline-block align-middle ml-2 font-normal"
              helpText="Payout failed. Please update account."
            >
              <WarningOutlined className="text-red-400 mr-2" />
            </Tooltip>
          )}
          {rowData.amount}
        </p>
      </TableCell>
      <TableCell>
        {/* Empty cell for child payment type */}
        <div></div>
      </TableCell>
      <TableCell>
        <p>
          {payoutSource === 'dwolla'
            ? `${toLocaleString(rowData.created)}`
            : `${rowData.created}`}
        </p>
      </TableCell>
      <TableCell>
        <p>
          {rowData.arrival_date.length > 0 && payoutSource === 'dwolla'
            ? `${toLocaleString(rowData.arrival_date)}`
            : `${rowData.arrival_date}`}
        </p>
      </TableCell>
      <TableCell>
        <div title="Download CSV">
          <ArrowDownTrayIcon
            id="payment-download"
            className="h-4 w-4 ml-4 cursor-pointer text-blue-800"
            onClick={() => handleDownloadCsvClicked(rowData.id)}
          />
        </div>
      </TableCell>
    </tr>
  );
}

type PayoutChildRowProps = {
  rowData: PayoutChildRowData;
  payoutSource: 'stripe' | 'dwolla';
};

function PayoutChildRow({ rowData, payoutSource }: PayoutChildRowProps) {
  const viewNotice = async () => {
    if (!rowData.noticeId) {
      return;
    }
    const noticeSnap = (await getFirebaseContext()
      .userNoticesRef()
      .doc(rowData.noticeId)
      .get()) as ESnapshotExists<ENotice>;
    const { newspaper } = noticeSnap.data();
    window.open(`/notice/${rowData.noticeId}?activeOrg=${newspaper.id}`);
  };

  return (
    <tr>
      <TableCell>
        {/* Empty cell for expand icon */}
        <div></div>
      </TableCell>
      {payoutSource === 'dwolla' && (
        <TableCell>
          <p
            className={`text-right ${
              rowData.type === TransactionType.processing_fee_deduction.label ||
              rowData.type === TransactionType.refund.label ||
              (rowData.type === TransactionType.payment.label &&
                rowData.status === 'cancelled')
                ? 'text-red-600'
                : 'text-black'
            }`}
          >
            {(rowData.type === TransactionType.processing_fee_deduction.label ||
              rowData.type === TransactionType.refund.label ||
              (rowData.type === TransactionType.payment.label &&
                rowData.status === 'cancelled')) &&
              '-'}
          </p>
        </TableCell>
      )}
      <TableCell>
        <p
          className={`${
            rowData.type === TransactionType.processing_fee_deduction.label ||
            rowData.type === TransactionType.refund.label ||
            rowData.type === TransactionType.paymentReversed.label ||
            (rowData.type === TransactionType.payment.label &&
              rowData.status === 'cancelled')
              ? 'text-red-600'
              : 'text-black'
          }`}
        >
          {rowData.amount}
        </p>
      </TableCell>
      <TableCell>
        <div>
          <span className="inline-flex items-center px-2 py-0.25 rounded-full text-xs font-semibold bg-indigo-200 text-indigo-800">
            {rowData.type === TransactionType.processing_fee_deduction.label
              ? 'Processing Fee'
              : rowData.type === TransactionType.payment.label &&
                rowData.status === 'cancelled'
              ? 'Refund'
              : rowData.type === TransactionType.payment.label
              ? 'Notice Payment'
              : rowData.type === TransactionType.manualPayment.label
              ? 'Manual Payment'
              : rowData.type === TransactionType.dividend.label
              ? 'Dividend'
              : rowData.type === TransactionType.paymentReversed.label
              ? 'Payout Reversed'
              : 'Refund'}
          </span>
          {rowData.type === TransactionType.processing_fee_deduction.label && (
            <Tooltip
              classes="inline-block align-middle ml-2 font-normal"
              helpText="This fee is due to Column for a notice paid outside the platform."
            >
              <Help fontSize="small" className="p-0.5 pt-0"></Help>
            </Tooltip>
          )}
          {rowData.type === TransactionType.manualPayment.label &&
            rowData.reason && (
              <Tooltip
                classes="inline-block align-middle ml-2 font-normal"
                helpText={rowData.reason}
                position={'right'}
              >
                <Help fontSize="small" className="p-0.5 pt-0"></Help>
              </Tooltip>
            )}
          {rowData.transactionTypeValue === TransactionType.feeRefund.key && (
            <Tooltip
              classes="inline-block align-middle ml-2 font-normal"
              helpText="This is a refund for a previously deducted processing fee, due to cancelling an invoice paid outside Column."
            >
              <Help fontSize="small" className="p-0.5 pt-0"></Help>
            </Tooltip>
          )}
        </div>
      </TableCell>
      <TableCell>
        {/* Empty cell for created date */}
        <div></div>
      </TableCell>
      <TableCell>
        {/* Empty cell for arrived date */}
        <div></div>
      </TableCell>
      <TableCell>
        {rowData.type === TransactionType.dividend.label ? (
          <button
            type="button"
            className="px-4 py-1 bg-white box-border rounded border border-blue-600 not-italic font-semibold text-xs flex text-blue-600"
            onClick={() => window.open(rowData.receipt)}
          >
            COLUMN PAYOUT
          </button>
        ) : ![
            TransactionType.manualPayment.label,
            TransactionType.paymentReversed.label
          ].includes(rowData.type) ? (
          <button
            type="button"
            className={`${
              !rowData.noticeId
                ? 'cursor-not-allowed pointer-events-none opacity-50'
                : 'hover:bg-gray-50'
            } px-4 py-1 bg-white box-border rounded border border-blue-600 not-italic font-semibold text-xs text-blue-600`}
            onClick={() => viewNotice()}
          >
            View Notice
          </button>
        ) : (
          <></>
        )}
      </TableCell>
    </tr>
  );
}

type PayoutGroupRowsProps = {
  group: PayoutGroup;
  payoutSource: 'stripe' | 'dwolla';
  handleDownloadCsvClicked: (id: string) => void;
};
function PayoutGroupRows({
  group,
  payoutSource,
  handleDownloadCsvClicked
}: PayoutGroupRowsProps) {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <PayoutParentRow
        rowData={group.parent}
        expand={expanded}
        payoutSource={payoutSource}
        handleExpandClicked={() => setExpanded(!expanded)}
        handleDownloadCsvClicked={handleDownloadCsvClicked}
      />
      {expanded &&
        group.children.map(child => (
          <PayoutChildRow
            key={child.id}
            rowData={child}
            payoutSource={payoutSource}
          />
        ))}
    </>
  );
}

export default PayoutGroupRows;
