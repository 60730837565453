import React, { useState } from 'react';

import { SmilingFaceWithSmilingEyes } from 'emojis';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import { PasswordField } from 'components/PasswordField';
import { loginUser } from 'utils/users';
import FormError from 'routes/errors/FormError';

type PlacementLoginModalProps = {
  onClose: () => void;
  email: string;
};

export default function PlacementLoginModal({
  onClose,
  email
}: PlacementLoginModalProps) {
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');

  const submitLogin = async () => {
    const [success, authError] = await loginUser(email, password);
    if (success) {
      onClose();
    } else {
      setLoginError(authError.message);
    }
  };

  return (
    <CancelOrSubmitModal
      onClose={onClose}
      overrideTertiaryClose={onClose}
      onSubmit={submitLogin}
      disablePrimaryButton={!password.length}
      primaryButtonText={'Login'}
      tertiaryButtonText={'Back'}
    >
      <div
        className="mx-10 mb-8 text-center z-20"
        id="placement-password-reset-modal-header"
      >
        <div className="font-semibold text-xl m-auto" id="pw-reset-heading">
          We found an account with this email
          <SmilingFaceWithSmilingEyes />
        </div>
        <div
          className="mt-4 font-medium text-sm text-column-grey-500"
          id="pw-reset-subheading"
        >
          Enter your Column password for{' '}
          <span className="text-column-primary-500">{email}</span> to login and
          complete the notice.
        </div>
      </div>
      <div className="mb-12" id="placement-password-reset-modal-body">
        <div className="flex justify-between font-medium text-sm leading-6 mb-2">
          <p className="text-column-grey-500">
            Password <span className="text-column-grey-400">*</span>
          </p>
          <a
            className={'text-column-primary-500'}
            href="/forgot-password"
            target="_blank"
            rel="noreferrer"
          >
            Forgot password?
          </a>
        </div>
        <div>
          <PasswordField
            id="placement-password-reset-new-password"
            value={password}
            onValueChange={(value: string) => {
              setPassword(value);
            }}
            placeHolderText="••••••"
          />
        </div>
        {loginError && <FormError error={loginError} />}
      </div>
    </CancelOrSubmitModal>
  );
}
