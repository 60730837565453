import { getOffset, roundUp } from '../math';
import { floatToP2Float } from '../ui';
import { PricingFunction } from './types';

export const priceInch: PricingFunction = options => {
  const { displayParameters, applicableRate, rateRecord } = options;
  const offset = getOffset(rateRecord);
  return floatToP2Float(
    roundUp(
      Math.max(displayParameters.height * displayParameters.width - offset, 0),
      rateRecord.roundOff
    ) * applicableRate
  );
};
