import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Listbox } from '@headlessui/react';
import { EnumInputItem } from 'lib/types/enums';
import {
  InputAccessories,
  InputAccessoriesInterface,
  getAccessibilityLinks,
  AccessibilityLinks
} from '../InputAccessories';
import { InputSize } from '../TextField';
import { ValidationMessageConfig } from '../helpers/inputValidation';
import { ColumnSelectItem } from './ColumnSelectItem';
import { useValidationChecks } from './hooks/useValidationChecks';

/**
 * Converts an enum into the format required by our ColumnSelect component
 * @param enumItem input enum item
 * @returns option for ColumnSelect
 */
export const enumKeyToSelectInput = (
  enumItem: EnumInputItem<{ description?: string }>
): ColumnSelectOption<string> => ({
  value: enumItem.value.toString(),
  label: enumItem.label,
  description: enumItem.description
});

export interface ColumnSelectOption<T extends string> {
  label: string;
  value: T;
  description?: string;
}

export type ColumnSelectProps<T extends string> = {
  /** A list of options to show in the listbox dropdown */
  options: ColumnSelectOption<T>[];
  /** Set the current value of the input */
  value?: string;
  /** Text to display before a value is entered */
  placeholder?: string;
  /** Style the dropdown to render within a line of text */
  inline?: boolean;
  /** Set the input spacing and sizing */
  size?: InputSize;
  /** Handler for a value change event */
  onChange: (value: T) => void;
  /** Optional text to show above the options list in the dropdown */
  itemsListDescription?: string;
  /** When true, the select element can be in a "no value" state. */
  allowUndefined?: boolean; // TODO: Consider if we can make this the default
  /** Set custom messages for a variety of validation errors */
  validationMessages?: ValidationMessageConfig;
  /** When true, Put focus on the Select element */
  allowAutoFocus?: boolean;
  /**
   * Used to check input validation against other fields with custom logic
   * @returns a string with the error message if the input is invalid, or null if it is valid
   */
  checkDependentValidations?: (value?: string) => string | null;
} & InputAccessoriesInterface;

export function ColumnSelect<T extends string>({
  id = '',
  options,
  labelText,
  noteText,
  errorText,
  required = false,
  value,
  placeholder = 'Select option',
  onChange,
  itemsListDescription,
  inline,
  size = 'standard',
  allowUndefined,
  disabled,
  validationMessages,
  allowAutoFocus,
  checkDependentValidations
}: ColumnSelectProps<T>) {
  const [showErrors, setShowErrors] = useState(!!errorText);
  const inputRef = React.createRef<HTMLButtonElement>();

  const selectedValue = options.find(o => o.value === value);
  const selectedOption = allowUndefined
    ? selectedValue
    : selectedValue || options[0];

  const { currentValidationMessage } = useValidationChecks({
    value: selectedOption?.value || '',
    required,
    inputRef,
    validationMessages,
    setShowErrors,
    checkDependentValidations
  });

  const errorMessage = errorText || currentValidationMessage;

  const wrapperClasses = classNames('relative', {
    'inline-block px-1': inline
  });

  const innerWrapperClasses = classNames('text-sm', {
    'text-column-gray-500': !inline && !disabled
  });

  const accessibilityLinks = getAccessibilityLinks({
    id,
    noteText,
    errorMessage
  });

  useEffect(() => {
    if (allowAutoFocus) {
      inputRef.current?.focus();
    }
  }, [allowAutoFocus]);

  return (
    <div id={`${id}-field`} className={wrapperClasses}>
      <Listbox
        value={selectedOption?.value}
        aria-invalid
        onChange={onChange}
        disabled={disabled}
      >
        {({ open }) => (
          <InputAccessories
            id={id}
            labelText={labelText}
            noteText={noteText}
            errorText={showErrors ? errorMessage : ''}
            disabled={disabled}
            required={required}
          >
            <div className={innerWrapperClasses}>
              <ColumnSelectButton
                inputRef={inputRef}
                inline={inline}
                size={size}
                accessibilityLinks={accessibilityLinks}
                buttonText={selectedOption?.label || placeholder}
                error={showErrors && !!errorMessage}
                disabled={disabled}
                valueSet={!!selectedOption}
                onBlur={() => setShowErrors(true)}
                allowAutoFocus={allowAutoFocus}
              />
              <ColumnSelectOptions
                open={open}
                options={options}
                inline={inline}
                itemsListDescription={itemsListDescription}
              />
            </div>
          </InputAccessories>
        )}
      </Listbox>
    </div>
  );
}

type ColumnSelectButtonProps = {
  inputRef: React.RefObject<HTMLButtonElement>;
  labelText?: string;
  accessibilityLinks: AccessibilityLinks | null;
  inline?: boolean;
  size?: InputSize;
  buttonText: string;
  disabled?: boolean;
  error: boolean;
  valueSet: boolean;
  onBlur: () => void;
  allowAutoFocus?: boolean;
};

function ColumnSelectButton({
  inputRef,
  accessibilityLinks,
  inline,
  size,
  buttonText,
  disabled,
  error,
  valueSet,
  onBlur
}: ColumnSelectButtonProps) {
  return (
    <Listbox.Button as={React.Fragment}>
      {({ open }) => (
        <button
          ref={inputRef}
          {...accessibilityLinks}
          onBlur={() => {
            if (!open) {
              onBlur();
            }
          }}
          className={classNames(
            'flex items-center bg-white w-full rounded-md font-medium focus:outline-none',
            {
              border: !inline,
              'p-3': !inline && size === 'standard',
              'p-2': !inline && size === 'small',
              'text-primary-500': inline,
              'border-column-gray-200 focus:border-column-primary-500 focus:shadow-outline-column-primary': !error,
              'border-column-red-600 shadow-outline-column-red': error,
              'bg-column-gray-25 text-column-gray-300 cursor-default': disabled
            }
          )}
        >
          <span
            className={classNames('truncate', {
              'text-column-gray-300': !valueSet
            })}
          >
            {buttonText}
          </span>
          <ChevronDownIcon
            aria-hidden="true"
            className={classNames('h-4 w-4', {
              'ml-auto': !inline,
              'ml-1': inline
            })}
          />
        </button>
      )}
    </Listbox.Button>
  );
}

type ColumnSelectOptionsProps<T extends string> = {
  open: boolean;
  inline?: boolean;
  options: ColumnSelectOption<T>[];
  itemsListDescription?: string;
};

function ColumnSelectOptions<T extends string>({
  open,
  inline,
  options,
  itemsListDescription
}: ColumnSelectOptionsProps<T>) {
  const optionsClasses = classNames(
    'absolute max-h-64 overflow-auto w-full bg-white p-1.5 mt-2 rounded border border-column-gray-100 focus:outline-none z-50 shadow-column-3',
    {
      'w-screen max-w-40': inline,
      hidden: !open
    }
  );

  return (
    <Listbox.Options aria-hidden={!open} className={optionsClasses} static>
      {options.length === 0 && (
        <div className="text-center py-2 text-column-gray-200">No options</div>
      )}
      {itemsListDescription && (
        <li
          role="presentation"
          className="uppercase font-semibold text-xs text-column-gray-300 p-3"
        >
          {itemsListDescription}
        </li>
      )}
      {options.map((option, index) => (
        <Listbox.Option key={`${option.value}-${index}`} value={option.value}>
          {({ active, selected }) => (
            <ColumnSelectItem
              active={active}
              selected={selected}
              option={option}
            />
          )}
        </Listbox.Option>
      ))}
    </Listbox.Options>
  );
}
