import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useRef,
  useState
} from 'react';
import classNames from 'classnames';
import { PopoverActivatorProps } from '../Popover';
import {
  SupportedRegions,
  ValidationMessageConfig,
  getInputTypeValidationConfig
} from '../helpers/inputValidation';
import {
  InputAccessories,
  InputAccessoriesInterface,
  getAccessibilityLinks,
  AccessibilityLinks
} from '../InputAccessories';
import { useValidationChecks } from './hooks/useValidationChecks';
import { ColumnButton, ColumnButtonProps } from '../ColumnButton';

export type InputType =
  | 'text'
  | 'email'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'url'
  | 'currency'
  | 'postal-code';

export type InputSize = 'standard' | 'small';

type InputProps = {
  /** A unique identifier */
  id: string;
  /** Define an interactive role for the input */
  role?: string;
  /** Set the current value of the input */
  value?: string;
  /** Text to display before a value is entered */
  placeholder?: string;
  /** Define the type of text input (Note: currency and postal-code are not officially supported types in the HTML spec, but setting them here will apply default validation) */
  type?: InputType;
  /** Disable editing the input value */
  disabled?: boolean;
  /** Indicate if the field is required */
  required?: boolean;
  /** Handler for a value change event */
  onChange?: (value: string) => void;
  /** Handler for input focus event */
  onFocus?: (event?: React.FocusEvent) => void;
  /** Handler for input blur event */
  onBlur?: (event?: React.FocusEvent) => void;
  /** Handler for click event */
  onClick?: (event?: React.MouseEvent) => void;
  /** Handler for key down events */
  onKeyDown?: (event: React.KeyboardEvent) => void;
  /** Handler to format the value on change */
  format?: (value: string) => string;
  /** Set the native maximum value validation */
  max?: number | string;
  /** Set the native minimum value validation */
  min?: number | string;
  /** Set the native increment validation */
  step?: number | string;
  /** Set the native max length validation */
  maxLength?: number;
  /** A regular expression in string format to validate the input value */
  pattern?: string;
  /** Specify whether the browser native autocomplete options should be provided */
  autoComplete?: 'on' | 'off';
  /** Element to display before input */
  prefix?: React.ReactNode;
  /** Element to display after input */
  suffix?: React.ReactNode;
  /** Set the input spacing and sizing */
  size?: InputSize;
  /** Set the region for formatting values with internationalization */
  region?: SupportedRegions;
  /** Render a button inside the input */
  integratedButton?: Omit<
    ColumnButtonProps,
    'border' | 'rounded' | 'primary' | 'secondary' | 'tertiary'
  >;
} & PopoverActivatorProps;

export type TextFieldProps = InputProps &
  InputAccessoriesInterface & {
    /** Set custom messages for a variety of validation errors */
    validationMessages?: ValidationMessageConfig;
    /** Callback called with `true` if input is valid and `false` if invalid */
    setValidityTracker?: Dispatch<SetStateAction<boolean>>;
  };

const paddingMap = {
  small: 'p-2',
  standard: 'p-3'
};

export function TextField({
  id,
  role,
  type,
  labelText,
  errorText,
  noteText,
  required,
  disabled,
  value,
  placeholder,
  size = 'standard',
  onChange,
  onFocus,
  onBlur,
  onClick,
  onKeyDown,
  format,
  max,
  min,
  step,
  maxLength,
  pattern,
  autoComplete,
  region,
  validationMessages,
  prefix,
  suffix,
  setValidityTracker,
  integratedButton,
  'aria-expanded': ariaExpanded,
  'aria-haspopup': ariaHaspopup,
  'aria-controls': ariaControls
}: TextFieldProps) {
  const [showErrors, setShowErrors] = useState(!!errorText);
  const [focus, setFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputTypeConfig = getInputTypeValidationConfig(type, region);

  const { currentValidationMessage } = useValidationChecks({
    value,
    inputRef,
    errorText,
    validationMessages:
      validationMessages || inputTypeConfig.props?.validationMessages,
    setValidityTracker,
    setShowErrors
  });

  const errorMessage = errorText || currentValidationMessage;
  const inErrorState = showErrors && !!errorMessage;

  const wrapperClasses = classNames('flex text-sm rounded-md border', {
    'border-column-gray-200': !focus && !inErrorState,
    'border-column-primary-500 shadow-outline-column-primary':
      focus && !inErrorState,
    'border-column-red-600 shadow-outline-column-red': inErrorState,
    'bg-column-gray-25 text-column-gray-300': disabled,
    'bg-white': !disabled
  });

  function handleOnFocus(event: React.FocusEvent | undefined) {
    setFocus(true);

    if (onFocus) {
      onFocus(event);
    }
  }

  function handleOnBlur(event: React.FocusEvent | undefined) {
    onBlur && onBlur(event);
    setFocus(false);
    setShowErrors(true);
  }

  const validationProps = {
    max,
    min,
    step,
    pattern,
    maxLength,
    ...inputTypeConfig.props
  };

  const accessibilityLinks = getAccessibilityLinks({
    id,
    noteText,
    errorMessage
  });

  return (
    <InputAccessories
      id={id}
      labelText={labelText}
      errorText={showErrors ? errorMessage : ''}
      noteText={noteText}
      required={required}
      disabled={disabled}
    >
      <div className={wrapperClasses}>
        <TextInput
          id={id}
          role={role}
          type={type}
          inputRef={inputRef}
          required={required}
          disabled={disabled}
          size={size}
          value={value}
          placeholder={placeholder}
          prefix={prefix}
          suffix={suffix}
          {...validationProps}
          autoComplete={autoComplete}
          format={inputTypeConfig.format ?? format}
          accessibilityLinks={accessibilityLinks}
          onChange={onChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onClick={onClick}
          onKeyDown={onKeyDown}
          aria-expanded={ariaExpanded}
          aria-haspopup={ariaHaspopup}
          aria-controls={ariaControls}
        />
        {integratedButton && (
          <ColumnButton
            {...integratedButton}
            disabled={integratedButton.disabled || inErrorState || disabled}
            rounded="right"
            border="left"
            primary
          />
        )}
      </div>
    </InputAccessories>
  );
}

function TextInput({
  id,
  role,
  inputRef,
  type = 'text',
  placeholder,
  size = 'standard',
  value,
  disabled,
  required,
  prefix,
  suffix,
  onChange,
  onFocus,
  onBlur,
  onClick,
  onKeyDown,
  accessibilityLinks,
  format,
  min,
  max,
  step,
  maxLength,
  pattern,
  autoComplete,
  'aria-expanded': ariaExpanded,
  'aria-haspopup': ariaHaspopup,
  'aria-controls': ariaControls
}: InputProps & {
  accessibilityLinks: AccessibilityLinks;
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  format?: (value: string) => string;
}) {
  const inputType = getSimplifiedInputType(type);

  const inputClasses = classNames(
    `${paddingMap[size]} w-full rounded-md focus:outline-none font-medium text-column-gray-500 bg-transparent`,
    {
      'pl-0': prefix,
      'pr-0': suffix
    }
  );

  function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
    e.target.value = format ? format(e.target.value) : e.target.value;

    onChange && onChange(e.target.value);
  }

  function onWheel() {
    if (type === 'number') {
      inputRef.current?.blur();
    }
  }

  function focusMainInput() {
    inputRef.current?.focus();
  }

  function handlePrefixSuffixClick() {
    focusMainInput();
    onClick && onClick();
  }

  const inputProps = {
    id,
    role,
    ref: inputRef,
    name: id,
    type: inputType,
    value: format && value ? format(value) : value,
    disabled,
    required,
    min,
    max,
    step,
    maxLength,
    pattern,
    /**
     * For some reason, `autoComplete="off"` does not work in Chrome, but this hack
     * works. See ONCALL-2070 for why we do this (to prevent autofill of the create new
     * organization form from populating the search bar).
     */
    autoComplete: autoComplete === 'off' ? 'nope' : autoComplete,
    ...accessibilityLinks,
    placeholder,
    onChange: handleOnChange,
    onFocus,
    onBlur,
    onClick,
    onKeyDown,
    onWheel,
    className: inputClasses,
    'aria-expanded': ariaExpanded,
    'aria-haspopup': ariaHaspopup,
    'aria-controls': ariaControls
  };

  const prefixSuffixClickHandler = inputProps.disabled
    ? undefined
    : handlePrefixSuffixClick;

  return (
    <>
      <InputSection onClick={prefixSuffixClickHandler} size={size}>
        {prefix}
      </InputSection>
      <input {...inputProps} />
      <InputSection onClick={prefixSuffixClickHandler} size={size}>
        {suffix}
      </InputSection>
    </>
  );
}

function getSimplifiedInputType(type: InputType) {
  switch (type) {
    case 'currency':
      return 'number';

    case 'postal-code':
      return 'text';

    default:
      return type;
  }
}

function InputSection({
  children,
  size,
  onClick
}: {
  children?: React.ReactNode;
  size: InputSize;
  onClick?: () => void;
}) {
  if (!children) {
    return null;
  }

  const classes = [paddingMap[size], 'flex', 'items-center', 'rounded'];

  if (onClick) {
    classes.push('cursor-pointer');
  }

  return (
    <div onClick={onClick} className={classes.join(' ')}>
      {children}
    </div>
  );
}
