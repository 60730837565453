import { Ad } from './ad';

export type ClassifiedBase = Ad & {};

export type ClassifiedLocation =
  | {
      zipCode: string;
      city?: string;
      state?: number;
    }
  | {
      zipCode?: string;
      city: string;
      state: number;
    };

export enum ClassifiedFilingTypeNames {
  AnnouncementsEvents = 'Announcements & Events',
  CommunityEngagementLetters = 'Community Engagement Letters',
  ForSale = 'For Sale',
  HousingProperty = 'Housing & Property',
  Milestones = 'Milestones',
  Pets = 'Pets',
  Services = 'Services'
}

type AnnouncementsEventsFields = {};

type CommunityEngagementLettersFields = {};

type ForSaleFields = {
  filingTypeName: ClassifiedFilingTypeNames.ForSale;
  listingPrice: number;
  location: ClassifiedLocation;
};

type HousingPropertyFields = {
  filingTypeName: ClassifiedFilingTypeNames.HousingProperty;
  listingPrice: number;
  location: ClassifiedLocation;
};

type MilestonesFields = {};

type PetsFields = {};

type ServicesFields = {};

type AnnouncementsEventsClassified = ClassifiedBase & AnnouncementsEventsFields;

type CommunityEngagementLettersClassified = ClassifiedBase &
  CommunityEngagementLettersFields;

export type ForSaleClassified = ClassifiedBase & ForSaleFields;

export type HousingPropertyClassified = ClassifiedBase & HousingPropertyFields;

type MilestonesClassified = ClassifiedBase & MilestonesFields;

type PetsClassified = ClassifiedBase & PetsFields;

type ServicesClassified = ClassifiedBase & ServicesFields;

export type Classified =
  | AnnouncementsEventsClassified
  | CommunityEngagementLettersClassified
  | ForSaleClassified
  | HousingPropertyClassified
  | MilestonesClassified
  | PetsClassified
  | ServicesClassified;

export const isForSaleClassified = (
  classified: Partial<Classified>
): classified is Partial<ForSaleClassified> =>
  classified.filingTypeName === ClassifiedFilingTypeNames.ForSale;

export const isHousingPropertyClassified = (
  classified: Partial<Classified>
): classified is Partial<HousingPropertyClassified> =>
  classified.filingTypeName === ClassifiedFilingTypeNames.HousingProperty;
