import { Ad } from 'lib/types/ad';
import { Alert } from 'lib/components/Alert';
import { Layout } from 'lib/types/layout';
import { ERef } from 'lib/types';
import { OrderFilingType } from 'lib/types/filingType';
import { Product } from 'lib/enums';
import QuestionForm from 'routes/madlib/components/QuestionForm';
import LoadingState from 'components/LoadingState';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher } from 'redux/auth';
import { useMadlibs } from '../../hooks/useMadlibs';
import { NewspaperOrdersFormData } from '../../PlacementFlowStepSelector';
import FormattedEditor from './FormattedEditor';
import MadlibInfo from './MadlibInfo';

export type AdEditorData = Pick<
  Ad,
  'content' | 'title' | 'orderImages' | 'madlibData'
>;

type FormattedEditorWrapperProps<T extends Ad> = {
  adData: Partial<T>;
  onAdChange: (o: Partial<T>) => void;
  layout: Layout;
  filingType: ERef<OrderFilingType>;
  newspaperOrdersFormData: NewspaperOrdersFormData;
  disableEditing?: boolean;
  product: Product;
};

export default function FormattedEditorWrapper<T extends Ad>({
  layout,
  filingType,
  adData,
  onAdChange,
  newspaperOrdersFormData,
  disableEditing,
  product
}: FormattedEditorWrapperProps<T>) {
  const isPublisher = useAppSelector(selectIsPublisher);

  const onChange = (update: AdEditorData) => {
    onAdChange({ ...adData, ...update });
  };

  const {
    madlibProps,
    madlibPropsError,
    madlibPropsLoading,
    allQuestionsAnswered,
    madlibData,
    /**
     * TODO: add some validation to ensure madlib is complete
     * before user moves on from draft content step
     */
    // madlibIsComplete,
    setAllQuestionsAnswered,
    setValidationResults,
    setRenderedHtml,
    setMadlibData
  } = useMadlibs({ filingType, adData, onChange });

  if (madlibPropsLoading) {
    return <LoadingState />;
  }

  const forceFreeEdit = Boolean(madlibData.editorOverride);

  const { madlibConfigPath, madlibTemplate, questions } = madlibProps ?? {};

  const showMadlibEditor =
    !!madlibConfigPath && !!madlibTemplate && !!questions && !forceFreeEdit;

  if (madlibPropsError) {
    return (
      <Alert
        id="madlib-error"
        description="Failed to fetch template data for publishing category."
      />
    );
  }

  const showFormattedEditor =
    (showMadlibEditor && allQuestionsAnswered) || !showMadlibEditor;

  return (
    <div className="flex flex-col items-center gap-2">
      {showMadlibEditor && (
        <QuestionForm
          questions={questions}
          questionTemplateData={madlibData.questionTemplateData}
          onChange={e => {
            // Validations for questions that are not tied to undefined as empty value
            const allNonEmptyAnswers =
              e.complete &&
              Object.entries(e.values).every(key => key[1] !== null);

            setMadlibData({ ...madlibData, questionTemplateData: e.values });
            setAllQuestionsAnswered(allNonEmptyAnswers);
          }}
        />
      )}
      {showFormattedEditor && (
        <>
          {madlibProps?.madlibConfigPath && (
            <MadlibInfo
              isOverrideActive={forceFreeEdit}
              product={product}
              isPublisher={isPublisher}
              onDisableMadlib={() => {
                setMadlibData({
                  templateData: {},
                  questionTemplateData: {},
                  editorOverride: true
                });
              }}
            />
          )}
          <FormattedEditor
            adData={adData}
            onChange={onChange}
            layout={layout}
            newspaperOrdersFormData={newspaperOrdersFormData}
            disableEditing={!!disableEditing}
            product={product}
            madlibProps={{
              madlibData,
              madlibTemplate,
              setValidationResults,
              setRenderedHtml,
              setMadlibData
            }}
            forceFreeEdit={forceFreeEdit}
          />
        </>
      )}
    </div>
  );
}
