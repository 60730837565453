import { BriefcaseIcon, UserIcon } from '@heroicons/react/24/outline';
import { CustomerTypeString } from 'routes/ads/filters/helpers';
import FlowChoiceButton from './FlowChoiceButton';

const choices = [
  {
    title: CustomerTypeString.INDIVIDUAL,
    description: 'Place an obituary on behalf of an individual',
    icon: <UserIcon className="w-14 h-14 stroke-1" />
  },
  {
    title: CustomerTypeString.FUNERAL_HOME,
    description: 'Place an obituary on behalf of a funeral home',
    icon: <BriefcaseIcon className="w-14 h-14 stroke-1" />
  }
];

type FlowChoiceFormProps = {
  onFlowChoiceChange: (flowChoice: CustomerTypeString) => void;
  flowChoice: CustomerTypeString;
};

function FlowChoiceForm({
  onFlowChoiceChange,
  flowChoice
}: FlowChoiceFormProps) {
  return (
    <>
      {choices.map(({ title, description, icon }) => (
        <FlowChoiceButton
          key={title}
          title={title}
          description={description}
          icon={icon}
          onChosenFlowChange={onFlowChoiceChange}
          checked={title === flowChoice}
        />
      ))}
    </>
  );
}

export default FlowChoiceForm;
