import { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { push } from 'connected-react-router';

import TemporaryPasswordResetContaner from 'layouts/signInLayout/TemporaryPasswordResetContainer';
import { logAndCaptureException } from 'utils';
import { loginUser } from 'utils/users';
import { getRedirect } from 'utils/urls';
import ToastActions from 'redux/toast';

import AuthActions from 'redux/auth';
import { apiPost } from 'api/typed';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { ColumnService } from 'lib/services/directory';
import LoginUserForm, { DEFAULT_LOGIN_DATA } from './LoginUserForm';
import LoginUserFooter from './LoginUserFooter';
import LoginUserHeader from './LoginUserHeader';
import SocialLoginsForm from './SocialLoginsForm';

export default function Login() {
  const dispatch = useAppDispatch();
  const [loginData, setLoginData] = useState(DEFAULT_LOGIN_DATA);
  const [showTemporaryPasswordAlert, setShowTemporaryPasswordAlert] = useState(
    false
  );
  const [loggingIn, setLoggingIn] = useState(false);

  const isAdvertiserOnboardingSelfServeEnabled = useBooleanFlag(
    LaunchDarklyFlags.ADVERTISER_ONBOARDING_SELFSERVE
  );

  const login = async () => {
    setLoggingIn(true);
    const redirect = getRedirect();
    dispatch(AuthActions.logout());
    const [success, authError] = await loginUser(
      loginData.email,
      loginData.password
    );

    if (success) {
      dispatch(push(redirect));
    } else {
      if (authError.code === 'auth/wrong-password') {
        try {
          const res = await apiPost('users/get-user-by-email', {
            email: loginData.email
          });

          if (res.success && res.data.hasTemporaryPassword) {
            setShowTemporaryPasswordAlert(true);
            setLoggingIn(false);
            return;
          }
        } catch (err) {
          logAndCaptureException(
            ColumnService.AUTH_AND_USER_MANAGEMENT,
            err,
            'Error getting user by email',
            {
              email: loginData.email
            }
          );
        }
      }
      dispatch(
        ToastActions.toastError({
          headerText: 'Error logging in',
          bodyText: authError.message
        })
      );
    }
    setLoggingIn(false);
  };

  return (
    <div className="w-full">
      <LoginUserHeader />
      <LoginUserForm
        onChangeLoginData={setLoginData}
        loginData={loginData}
        loggingIn={loggingIn}
        onSubmit={login}
      />
      {isAdvertiserOnboardingSelfServeEnabled && <SocialLoginsForm />}
      {showTemporaryPasswordAlert && (
        <TemporaryPasswordResetContaner email={loginData.email} />
      )}
      <LoginUserFooter />
    </div>
  );
}
