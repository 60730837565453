/* eslint-disable no-use-before-define */
import { z } from 'zod';
import firebase from 'firebase/app';
import { Collections } from '../constants';
import {
  Customer,
  CustomerOrganization,
  Event,
  EInvite,
  ERate,
  EUser,
  EOrganization,
  ESubscription,
  EPublicNotice,
  ENotice,
  ENoticeDraft,
  EInvoice,
  EInvoiceItem,
  EPayout,
  ENotification,
  ETemplate,
  ETransfer,
  ECard,
  ECardInvoice,
  ECardTransaction,
  EPreviewNotice,
  EDisplaySite,
  Notarization,
  EmailConfirmation,
  EDeadline,
  EJoinRequest,
  EUploadID,
  ENoticeFile,
  ECache,
  ECacheValue,
  ECacheEntry,
  EMigration,
  FirebaseTimestamp,
  ECacheKey,
  ModularSize,
  AffidavitTemplate,
  Note,
  EFtpFile,
  EUpdateSettingRequest,
  AdRate,
  EAdjudicationArea
} from '.';
import { InvoiceTransaction } from './invoiceTransaction';
import { PublicationIssue } from './publicationIssue';
import { PublicationIssueAttachment } from './publicationIssueAttachment';
import { Run } from './runs';
import { EEdition } from './eedition';
import { Obituary } from './obituary';
import { Order } from './order';
import { NewspaperOrder } from './newspaperOrder';
import { Invoice, PublicNoticeInvoice } from './invoices';
import { PublicationIssueSection } from './publicationIssueSection';
import {
  ProductPublishingSetting,
  PublishingSetting
} from './publishingSetting';
import { OrderFilingType } from './filingType';
import { Classified } from './classified';
import { LedgerItem } from './ledger';
import { ProductSiteSetting } from './productSiteSetting';
import { OrderDetail } from './orderDetail';
import { Coupon } from './coupon';
import { PaperCheck } from './paperCheck';

// Firebase Auth signInProvider types currently supported by column:
export const ANONYMOUS = 'anonymous' as const;
export const EMAIL_AND_PASSWORD = 'password' as const;
export const CUSTOM = 'custom' as const;

export type ESignInProvider =
  | typeof ANONYMOUS
  | typeof EMAIL_AND_PASSWORD
  | typeof CUSTOM;

export type OrderByDirection = 'desc' | 'asc';

export type WhereFilterOp =
  | '<'
  | '<='
  | '=='
  | '!='
  | '>='
  | '>'
  | 'array-contains'
  | 'in'
  | 'array-contains-any'
  | 'not-in';

/**
 * Note: this is the only non-static publicly visible part of
 * the interface. It's far from specific enough, but it also
 * is unliekly to overlap with any value we want to put in our DB.
 */
export interface EFieldValue<T> {
  isEqual(other: EFieldValue<T>): boolean;
}

export interface EFieldValues {
  serverTimestamp(): EFieldValue<FirebaseTimestamp>;
  delete(): EFieldValue<any>;
  arrayUnion<T>(...elements: T[]): EFieldValue<T>;
  arrayRemove<T>(...elements: T[]): EFieldValue<T>;
  increment(n: number): EFieldValue<number>;
}

export type EPartialDocumentData<T> = {
  [P in keyof T]?: T[P] | EFieldValue<T[P]>;
};

export type EDocumentData<T> = {
  [P in keyof T]: T[P] | EFieldValue<T[P]>;
};

/**
 * See firestore "DocumentReference"
 */
export interface ERef<T> {
  id: string;
  parent: ECollectionRef<T>;
  path: string;
  collection(collectionPath: string): ECollectionRef<any>;
  update(update: EPartialDocumentData<T>): Promise<any>;
  create?(update: EPartialDocumentData<T>): Promise<any>;
  // This should really be EDocumentData<T> but we have too much nad old code
  set(
    update: EPartialDocumentData<T>,
    options?: { merge: boolean }
  ): Promise<unknown>;
  get(): Promise<ESnapshot<T>>;
  delete(): Promise<any>;
  onSnapshot(
    onNext: (snapshot: ESnapshot<T>) => void,
    onError?: (err: any) => void
  ): EUnsubscribe;
}

/**
 * See firestore "Query"
 */
export interface EQuery<T> {
  where(fieldPath: string, opStr: WhereFilterOp, value: any): EQuery<T>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  orderBy(fieldPath: Object, directionStr?: OrderByDirection): EQuery<T>;
  limit(limit: number): EQuery<T>;
  limitToLast(limit: number): EQuery<T>;
  startAt(...fieldValues: any[]): EQuery<T>;
  startAfter(...fieldValues: any[]): EQuery<T>;
  endBefore(...fieldValues: any[]): EQuery<T>;
  endAt(...fieldValues: any[]): EQuery<T>;
  get(): Promise<EQuerySnapshot<T>>;

  onSnapshot(
    onNext: (snapshot: EQuerySnapshot<T>) => void,
    onError?: (error: any) => void
  ): EUnsubscribe;
}

/**
 * See firestore "QuerySnapshot"
 */
export interface EQuerySnapshot<T> {
  docs: Array<ESnapshotExists<T>>;
  size: number;
  empty: boolean;
}

/**
 * See firestore "CollectionReference"
 */
export interface ECollectionRef<T> extends EQuery<T> {
  id: string;
  parent: ERef<any> | null;
  path: string;

  doc(documentPath: string): ERef<T>;
  doc(): ERef<T>;
  add(data: EDocumentData<T>): Promise<ERef<T>>;
}

interface ESnapshotBase<T> {
  id: string;
  ref: ERef<T>;

  proto?: {
    createTime: string;
    updateTime: string;
  };
}

export interface ESnapshotNotExists<T> extends ESnapshotBase<T> {
  exists: boolean;
  data(): T | undefined;
}

export interface ESnapshotExists<T> extends ESnapshotBase<T> {
  exists: boolean;
  data(): T;
}

/**
 * See firestore "DocumentSnapshot"
 */
export type ESnapshot<T> = ESnapshotExists<T> | ESnapshotNotExists<T>;

/**
 * Unsubscribe handler returned from onSnapshot calls.
 */
export type EUnsubscribe = () => void;

/**
 * See firestore "BulkWriter"
 * https://googleapis.dev/nodejs/firestore/latest/BulkWriter.html
 */
export type EBulkWriter<T> = {
  create(ref: ERef<T>): Promise<void>;
  set(ref: ERef<T>, data: EPartialDocumentData<T>): Promise<void>;
  update(ref: ERef<T>, data: EPartialDocumentData<T>): Promise<void>;
  delete(ref: ERef<T>): Promise<void>;
  close(): Promise<void>;
  onWriteResult(fn: (ref: ERef<T>) => void): void;
};

export interface ETransaction {
  add: <T>(
    collectionRef: ECollectionRef<T>,
    data: EDocumentData<T>
  ) => Promise<ERef<T>>;
  get: <T>(ref: ERef<T>) => Promise<ESnapshot<T>>;
  set: <T>(ref: ERef<T>, data: EDocumentData<T>) => ETransaction;
  update: <T>(ref: ERef<T>, data: EPartialDocumentData<T>) => ETransaction;
  delete: <T>(ref: ERef<T>) => ETransaction;
}

export interface ERefFactory {
  getDocRef<T>(path: string): ERef<T>;
  getCollectionRef<T>(path: string): ECollectionRef<T>;
  getSubcollectionGroupRef<T>(path: string): EQuery<T>;
}

/**
 * Entry point to get access to Firebase collections. Implemented separately
 * in the frontend and the backend.
 */
export interface EFirebaseContextRefs {
  adTemplatesRef(): ECollectionRef<ETemplate>;
  affidavitTemplatesRef(): ECollectionRef<AffidavitTemplate>;
  cachesRef<K extends ECacheKey, V extends ECacheValue>(
    parent: ERef<EOrganization>
  ): ECollectionRef<ECache<K, V>>;
  cacheEntriesRef<K extends ECacheKey, V extends ECacheValue>(
    parent: ERef<ECache<K, V>>
  ): ECollectionRef<ECacheEntry<K, V>>;

  /** @deprecated */
  cardsRef(): ECollectionRef<ECard>;

  /** @deprecated */
  cardInvoicesRef(): ECollectionRef<ECardInvoice>;

  /** @deprecated */
  cardTransactionsRef(): ECollectionRef<ECardTransaction>;

  classifiedsRef(): ECollectionRef<Classified>;
  customerOrganizationsRef(): ECollectionRef<CustomerOrganization>;
  customersRef(): ECollectionRef<Customer>;

  /**
   * `deadlinesRef()` uses `collectionGroup` to access the `deadlines` subcollection across parent
   * `organizations`, so it returns an `EQuery` rather than an `ECollection`
   */
  deadlinesCollectionGroupRef(): EQuery<EDeadline>;

  displaySitesRef(): ECollectionRef<EDisplaySite>;
  displaySiteUploadIDsRef(
    parent: ERef<EDisplaySite>
  ): ECollectionRef<EUploadID>;
  eeditionsRef<T extends EEdition = EEdition>(): ECollectionRef<T>;
  emailConfirmationsRef(): ECollectionRef<EmailConfirmation>;
  eventsRef<T extends Event = Event>(): ECollectionRef<T>;
  filingTypesRef(): ECollectionRef<OrderFilingType>;
  ftpFilesRef(): ECollectionRef<EFtpFile>;
  invitesRef(): ECollectionRef<EInvite>;
  invoicesRef<T extends Invoice = PublicNoticeInvoice>(): ECollectionRef<T>;
  invoiceItemsRef(): ECollectionRef<EInvoiceItem>;
  invoiceTransactionsRef(
    parent: ERef<EInvoice>
  ): ECollectionRef<InvoiceTransaction>;
  invoiceTransactionsCollectionGroupRef(): EQuery<InvoiceTransaction>;
  joinRequestsRef(): ECollectionRef<EJoinRequest>;
  ledgerRef(): ECollectionRef<LedgerItem>;
  migrationsRef(): ECollectionRef<EMigration>;
  modularSizesRef(): ECollectionRef<ModularSize>;

  /**
   * `newspaperOrdersRef()` uses `collectionGroup` to access the `newspaperOrders` subcollection across parent
   * `organizations`, so it returns an `EQuery` rather than an `ECollection`
   */
  newspaperOrdersCollectionGroupRef(): EQuery<NewspaperOrder>;

  notarizationsRef<T extends Notarization = Notarization>(): ECollectionRef<T>;
  notesRef(): ECollectionRef<Note>;
  notificationsRef(): ECollectionRef<ENotification>;
  obituariesRef(): ECollectionRef<Obituary>;
  ordersRef(): ECollectionRef<Order>;
  orderNewspaperOrdersRef(parent: ERef<Order>): ECollectionRef<NewspaperOrder>;
  organizationsRef(): ECollectionRef<EOrganization>;
  organizationDeadlinesRef(
    parent: ERef<EOrganization>
  ): ECollectionRef<EDeadline>;
  organizationProductPublishingSettingsRef(
    parent: ERef<EOrganization>
  ): ECollectionRef<ProductPublishingSetting>;
  payoutsRef<T extends EPayout = EPayout>(): ECollectionRef<T>;
  previewNoticesRef(): ECollectionRef<EPreviewNotice>;
  productPublishingSettingsCollectionGroupRef(): EQuery<ProductPublishingSetting>;
  publicNoticesRef(): ECollectionRef<EPublicNotice>;
  publicationIssuesRef(): ECollectionRef<PublicationIssue>;
  publicationIssueAttachmentsRef(
    parent: ERef<PublicationIssue>
  ): ECollectionRef<PublicationIssueAttachment>;
  publicationIssueSectionsCollectionGroupRef(): EQuery<PublicationIssueSection>;
  publicationIssueSectionsRef(
    parent: ERef<PublicationIssue>
  ): ECollectionRef<PublicationIssueSection>;
  publishingSettingsRef(): ECollectionRef<PublishingSetting>;
  /** Careful! If you query the collection directly, you may end up with obit and classified rates. You probably want to use NoticeRateService! */
  ratesRef(): ECollectionRef<ERate>;
  adRatesRef(): ECollectionRef<AdRate>;
  runsRef(): ECollectionRef<Run>;
  // TODO: update this return type when we have a chance to clean up our Stripe event & object types
  stripeEventsRef(): ECollectionRef<any>;
  subscriptionsRef(): ECollectionRef<ESubscription>;
  transfersRef(): ECollectionRef<ETransfer>;
  userNoticesRef(): ECollectionRef<ENotice>;
  userNoticeFilesRef(
    parent: ERef<ENotice> | ERef<ENoticeDraft>
  ): ECollectionRef<ENoticeFile>;
  userDraftsRef(): ECollectionRef<ENoticeDraft>;
  usersRef(): ECollectionRef<EUser>;
  updateSettingRequestsRef(): ECollectionRef<EUpdateSettingRequest>;

  doc<T>(path: string): ERef<T>;
  adjudicationAreasRef(): ECollectionRef<EAdjudicationArea>;
  organizationProductSiteSettingsRef(
    parent: ERef<EOrganization>
  ): ECollectionRef<ProductSiteSetting>;
  productSiteSettingsCollectionGroupRef(): EQuery<ProductSiteSetting>;
  orderOrderDetailsRef(parent: ERef<Order>): ECollectionRef<OrderDetail>;
  orderDetailsCollectionGroupRef(): EQuery<OrderDetail>;
  couponsRef(): ECollectionRef<Coupon>;
  paperChecksRef(): ECollectionRef<PaperCheck>;

  // CODEGEN: EFIREBASE-CONTEXT-REFS - DO NOT DELETE OR MOVE
}

export const getFirebaseContextRefs = (
  factory: ERefFactory
): EFirebaseContextRefs => {
  const { getDocRef, getCollectionRef, getSubcollectionGroupRef } = factory;

  return {
    adTemplatesRef: () => getCollectionRef<ETemplate>(Collections.adTemplates),
    affidavitTemplatesRef: () =>
      getCollectionRef<AffidavitTemplate>(Collections.affidavitTemplates),
    cachesRef: <K extends ECacheKey, V extends ECacheValue>(
      parent: ERef<EOrganization>
    ) => getCollectionRef<ECache<K, V>>(`${parent.path}/${Collections.caches}`),
    cacheEntriesRef: <K extends ECacheKey, V extends ECacheValue>(
      parent: ERef<ECache<K, V>>
    ) =>
      getCollectionRef<ECacheEntry<K, V>>(
        `${parent.path}/${Collections.cacheEntries}`
      ),
    cardsRef: () => getCollectionRef<ECard>(Collections.cards),
    cardInvoicesRef: () =>
      getCollectionRef<ECardInvoice>(Collections.cardInvoices),
    cardTransactionsRef: () =>
      getCollectionRef<ECardTransaction>(Collections.cardTransactions),
    ledgerRef: () => getCollectionRef<LedgerItem>(Collections.ledger),
    classifiedsRef: () => getCollectionRef<Classified>(Collections.classifieds),
    customerOrganizationsRef: () =>
      getCollectionRef<CustomerOrganization>(Collections.customerOrganizations),
    customersRef: () => getCollectionRef<Customer>(Collections.customers),
    deadlinesCollectionGroupRef: () =>
      getSubcollectionGroupRef<EDeadline>(Collections.deadlines),
    displaySitesRef: () =>
      getCollectionRef<EDisplaySite>(Collections.displaySites),
    displaySiteUploadIDsRef: (parent: ERef<EDisplaySite>) =>
      getCollectionRef<EUploadID>(`${parent.path}/${Collections.uploadIDs}`),
    eeditionsRef: <T extends EEdition = EEdition>() =>
      getCollectionRef<T>(Collections.eeditions),
    emailConfirmationsRef: () =>
      getCollectionRef<EmailConfirmation>(Collections.emailConfirmations),
    eventsRef: <T extends Event = Event>() =>
      getCollectionRef<T>(Collections.events),
    filingTypesRef: () =>
      getCollectionRef<OrderFilingType>(Collections.filingTypes),
    ftpFilesRef: () => getCollectionRef<EFtpFile>(Collections.ftpFiles),
    invitesRef: () => getCollectionRef<EInvite>(Collections.invites),
    invoicesRef: <T extends Invoice = PublicNoticeInvoice>() =>
      getCollectionRef<T>(Collections.invoices),
    invoiceItemsRef: () =>
      getCollectionRef<EInvoiceItem>(Collections.invoiceItems),
    invoiceTransactionsRef: (parent: ERef<EInvoice>) =>
      getCollectionRef<InvoiceTransaction>(
        `${parent.path}/${Collections.invoiceTransactions}`
      ),
    invoiceTransactionsCollectionGroupRef: () =>
      getSubcollectionGroupRef<InvoiceTransaction>(
        Collections.invoiceTransactions
      ),
    joinRequestsRef: () =>
      getCollectionRef<EJoinRequest>(Collections.joinRequests),
    migrationsRef: () => getCollectionRef<EMigration>(Collections.migrations),
    modularSizesRef: () =>
      getCollectionRef<ModularSize>(Collections.modularSizes),
    newspaperOrdersCollectionGroupRef: () =>
      getSubcollectionGroupRef<NewspaperOrder>(Collections.newspaperOrders),
    notarizationsRef: <T extends Notarization = Notarization>() =>
      getCollectionRef<T>(Collections.notarizations),
    notesRef: () => getCollectionRef<Note>(Collections.notes),
    notificationsRef: () =>
      getCollectionRef<ENotification>(Collections.notifications),
    obituariesRef: () => getCollectionRef<Obituary>(Collections.obituaries),
    ordersRef: () => getCollectionRef<Order>(Collections.orders),
    orderNewspaperOrdersRef: (parent: ERef<Order>) =>
      getCollectionRef<NewspaperOrder>(
        `${parent.path}/${Collections.newspaperOrders}`
      ),
    organizationsRef: () =>
      getCollectionRef<EOrganization>(Collections.organizations),
    organizationDeadlinesRef: (parent: ERef<EOrganization>) =>
      getCollectionRef<EDeadline>(`${parent.path}/${Collections.deadlines}`),
    organizationProductPublishingSettingsRef: (parent: ERef<EOrganization>) =>
      getCollectionRef<ProductPublishingSetting>(
        `${parent.path}/${Collections.productPublishingSettings}`
      ),
    payoutsRef: <T extends EPayout = EPayout>() =>
      getCollectionRef<T>(Collections.payouts),
    previewNoticesRef: () =>
      getCollectionRef<EPreviewNotice>(Collections.previewNotices),
    productPublishingSettingsCollectionGroupRef: () =>
      getSubcollectionGroupRef<ProductPublishingSetting>(
        Collections.productPublishingSettings
      ),
    publicNoticesRef: () =>
      getCollectionRef<EPublicNotice>(Collections.publicNotices),
    publicationIssuesRef: () =>
      getCollectionRef<PublicationIssue>(Collections.publicationIssues),
    publicationIssueAttachmentsRef: (parent: ERef<PublicationIssue>) =>
      getCollectionRef<PublicationIssueAttachment>(
        `${parent.path}/${Collections.publicationIssueAttachments}`
      ),
    publicationIssueSectionsCollectionGroupRef: () =>
      getSubcollectionGroupRef<PublicationIssueSection>(
        Collections.publicationIssueSections
      ),
    publicationIssueSectionsRef: (parent: ERef<PublicationIssue>) =>
      getCollectionRef<PublicationIssueSection>(
        `${parent.path}/${Collections.publicationIssueSections}`
      ),
    publishingSettingsRef: () =>
      getCollectionRef<PublishingSetting>(Collections.publishingSettings),
    adRatesRef: () => getCollectionRef<AdRate>(Collections.rates),
    ratesRef: () => getCollectionRef<ERate>(Collections.rates),
    runsRef: () => getCollectionRef<Run>(Collections.runs),
    stripeEventsRef: () => getCollectionRef<any>(Collections.stripeevents),
    subscriptionsRef: () =>
      getCollectionRef<ESubscription>(Collections.subscriptions),
    transfersRef: () => getCollectionRef<ETransfer>(Collections.transfers),
    userNoticesRef: () => getCollectionRef<ENotice>(Collections.userNotices),
    userNoticeFilesRef: (parent: ERef<ENotice> | ERef<ENoticeDraft>) =>
      getCollectionRef<ENoticeFile>(
        `${parent.path}/${Collections.noticeFiles}`
      ),
    userDraftsRef: () => getCollectionRef<ENoticeDraft>(Collections.userDrafts),
    usersRef: () => getCollectionRef<EUser>(Collections.users),
    updateSettingRequestsRef: () =>
      getCollectionRef<EUpdateSettingRequest>(
        Collections.updateSettingRequests
      ),

    doc: (docPath: string) => {
      return getDocRef(docPath);
    },

    adjudicationAreasRef() {
      return getCollectionRef<EAdjudicationArea>(Collections.adjudicationAreas);
    },
    organizationProductSiteSettingsRef: (parent: ERef<EOrganization>) =>
      getCollectionRef<ProductSiteSetting>(
        `${parent.path}/productSiteSettings`
      ),
    productSiteSettingsCollectionGroupRef: () =>
      getSubcollectionGroupRef<ProductSiteSetting>(
        Collections.productSiteSettings
      ),
    orderOrderDetailsRef: (parent: ERef<Order>) =>
      getCollectionRef<OrderDetail>(`${parent.path}/orderDetails`),
    orderDetailsCollectionGroupRef: () =>
      getSubcollectionGroupRef<OrderDetail>(Collections.orderDetails),
    couponsRef: () => getCollectionRef<Coupon>(Collections.coupons),
    paperChecksRef: () => getCollectionRef<PaperCheck>(Collections.paperChecks)

    // CODEGEN: GET-FIREBASE-CONTEXT-REFS - DO NOT DELETE OR MOVE
  };
};

export interface EFirebaseContextTimestamps {
  timestamp(options?: {
    seconds: number;
    nanoseconds?: number;
  }): FirebaseTimestamp;

  timestampFromDate(date: Date): FirebaseTimestamp;
}

export interface EFirebaseContextFieldValues {
  fieldValue(): EFieldValues;
}

export interface EFirebaseContextTransaction {
  runTransaction<T>(
    updateFunction: (transaction: ETransaction) => T | Promise<T>
  ): Promise<T>;
}

/**
 * Entry point to get access to Firestore.
 * Implemented separately in the frontend and the backend.
 */
export interface EFirebaseContext
  extends EFirebaseContextRefs,
    EFirebaseContextTimestamps,
    EFirebaseContextFieldValues,
    EFirebaseContextTransaction {}

/**
 * Inherits properies from parent.
 */
export type EInherits<T> = {
  parent?: ERef<T> | null;
};

/**
 * A zod schema for the props of a Firebase timestamp
 * https://firebase.google.com/docs/reference/js/v8/firebase.firestore.Timestamp#properties_1
 * https://googleapis.dev/nodejs/firestore/latest/Timestamp.html
 */
export const FirebaseTimestampSchema = z.custom<firebase.firestore.Timestamp>(
  data => {
    return (
      data &&
      typeof data === 'object' &&
      typeof (data as firebase.firestore.Timestamp).seconds === 'number' &&
      typeof (data as firebase.firestore.Timestamp).toDate === 'function' &&
      typeof (data as firebase.firestore.Timestamp).toMillis === 'function'
    );
  },
  { message: 'Invalid Firebase Timestamp' }
);

export type StorageReference = {
  downloadUrl: string;
  storagePath: string;
};
