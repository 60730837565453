export enum Product {
  Notice = 'notice',
  Obituary = 'obituary',
  Classified = 'classified'
}

export const isValidProduct = (product: string): product is Product =>
  Object.values(Product).includes(product as Product);

export const PRODUCT_TO_NAME: Record<
  Product,
  { singular: string; plural: string }
> = {
  [Product.Notice]: { singular: 'Public Notice', plural: 'Public Notices' },
  [Product.Classified]: { singular: 'Classified', plural: 'Classifieds' },
  [Product.Obituary]: { singular: 'Obituary', plural: 'Obituaries' }
};
