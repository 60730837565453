import TabGroup, { TabOption } from 'lib/components/Tabs';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
import { ESnapshotExists, EUser, exists } from 'lib/types';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { useMemo, useState } from 'react';
import { Product } from 'lib/enums';
import SettingsPage from 'routes/settings/SettingsPage';
import { userHasPermission } from 'utils/permissions';
import { Permissions } from 'lib/permissions/roles';
import Header from 'components/Header';
import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { Alert } from 'lib/components/Alert';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { ProductPublishingSettingsService } from 'lib/services/productPublishingSettingsService';
import { getFirebaseContext } from 'utils/firebase';
import { ColumnService } from 'lib/services/directory';
import RegularProductDeadlines from '../deadlines/RegularProductDeadlines';
import ProductScheduleChanges from '../deadlines/ProductScheduleChanges';
import RatesSettings from './RatesSettings';
import ProductLineFilingTypeSettings from './FilingTypeSettings';
import SiteSettings from './SiteSettings';

export const PRODUCT_SETTINGS_REGULAR_DEADLINES: TabOption = {
  label: 'Regular Deadlines',
  enabled: true,
  id: 'regular-deadlines-tab'
};

export const PRODUCT_SETTINGS_SCHEDULE_CHANGES: TabOption = {
  label: 'Schedule Changes',
  enabled: true,
  id: 'schedule-changes-tab'
};

export const PRODUCT_SETTINGS_RATES: TabOption = {
  label: 'Rates',
  enabled: true,
  id: 'rates-tab'
};

export const PRODUCT_SETTINGS_CATEGORIES: TabOption = {
  label: 'Categories',
  enabled: true,
  id: 'categories-tab'
};

export const PRODUCT_SETTINGS_DISPLAY_SITE: TabOption = {
  label: 'Display Site',
  enabled: true,
  id: 'display-site-tab'
};

export const PRODUCT_SETTINGS_TABS: ProductSettingsTabs[] = [
  PRODUCT_SETTINGS_REGULAR_DEADLINES,
  PRODUCT_SETTINGS_SCHEDULE_CHANGES,
  PRODUCT_SETTINGS_RATES,
  PRODUCT_SETTINGS_CATEGORIES,
  PRODUCT_SETTINGS_DISPLAY_SITE
];

export type ProductSettingsTabs =
  | typeof PRODUCT_SETTINGS_REGULAR_DEADLINES
  | typeof PRODUCT_SETTINGS_SCHEDULE_CHANGES
  | typeof PRODUCT_SETTINGS_RATES
  | typeof PRODUCT_SETTINGS_CATEGORIES
  | typeof PRODUCT_SETTINGS_DISPLAY_SITE;

type ProductSettingsProps = {
  productLine: Product;
  user: ESnapshotExists<EUser>;
};

export default function ProductSettings({
  productLine,
  user
}: ProductSettingsProps) {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const [publishingMedium, setPublishingMedium] = useState(
    PublishingMedium.Print
  );
  const showPublishingMediumsSelect = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_PUBLISHING_MEDIUMS_SETTINGS
  );

  // Map of user permissions to product settings tabs
  const userPermissionsMap = useMemo(() => {
    return {
      [PRODUCT_SETTINGS_REGULAR_DEADLINES.id]: userHasPermission(
        user,
        Permissions.SETTINGS_DEADLINES
      ),
      [PRODUCT_SETTINGS_SCHEDULE_CHANGES.id]: userHasPermission(
        user,
        Permissions.SETTINGS_DEADLINES
      ),
      [PRODUCT_SETTINGS_RATES.id]: userHasPermission(
        user,
        Permissions.SETTINGS_RATES
      ),
      [PRODUCT_SETTINGS_CATEGORIES.id]: userHasPermission(
        user,
        Permissions.SETTINGS_ORDER_TYPES
      ),
      [PRODUCT_SETTINGS_DISPLAY_SITE.id]: userHasPermission(
        user,
        Permissions.SETTINGS_PRODUCT_SITE
      )
    };
  }, [user, productLine]);
  // Filter out tabs that the user does not have permission to view
  const permissionedTabs = PRODUCT_SETTINGS_TABS.filter(tab => {
    return userPermissionsMap[tab.id];
  });

  const [activeSettingsTab, setActiveSettingsTab] = useState(
    permissionedTabs[0]
  );

  const productPublishingSettingService = new ProductPublishingSettingsService(
    getFirebaseContext()
  );
  const {
    value: detailedPublishingSetting,
    isLoading: detailedPublishingSettingLoading,
    isError: detailedPublishingSettingError
  } = useSafeAsyncEffect({
    fetchData: async () =>
      await productPublishingSettingService.fetchOrCreateDetailedProductPublishingSetting(
        activeOrganization?.ref,
        productLine,
        publishingMedium,
        {
          shouldCreate: true
        }
      ),
    initialData: null,
    dependencies: [activeOrganization?.id, productLine, publishingMedium],
    errorConfig: {
      service: ColumnService.OBITS,
      message: 'Error loading product settings',
      tags: {
        organizationId: activeOrganization?.id || 'unknown',
        productLine
      }
    }
  });

  if (!exists(activeOrganization) || detailedPublishingSettingLoading) {
    return <LoadingSpinner />;
  }

  if (!detailedPublishingSetting || detailedPublishingSettingError) {
    return (
      <Alert status="error" id="product-setting-load-error">
        Failed to load product settings
      </Alert>
    );
  }

  const { publishingSetting, filingTypes } = detailedPublishingSetting;
  const componentMap: Record<string, React.ReactNode> = {
    [PRODUCT_SETTINGS_REGULAR_DEADLINES.id]: (
      <RegularProductDeadlines
        productLine={productLine}
        publishingSetting={publishingSetting}
      />
    ),
    [PRODUCT_SETTINGS_SCHEDULE_CHANGES.id]: (
      <ProductScheduleChanges
        activeOrganization={activeOrganization}
        productLine={productLine}
        publishingSetting={publishingSetting}
      />
    ),
    [PRODUCT_SETTINGS_RATES.id]: (
      <RatesSettings
        activeOrganization={activeOrganization}
        productLine={productLine}
      />
    ),
    [PRODUCT_SETTINGS_CATEGORIES.id]: (
      <ProductLineFilingTypeSettings
        productLine={productLine}
        activeOrganization={activeOrganization}
        filingTypes={filingTypes}
      />
    ),
    [PRODUCT_SETTINGS_DISPLAY_SITE.id]: (
      <SiteSettings
        activeOrganization={activeOrganization}
        productLine={productLine}
      />
    )
  };

  const productName = PRODUCT_TO_NAME[productLine];

  return (
    <SettingsPage>
      <Header productLine={productLine} />
      <div className="p-6 border-b">
        <h2 className="font-medium text-xl text-column-gray-500">
          {productName.plural} Settings
        </h2>
        {showPublishingMediumsSelect && (
          <div className="mt-4">
            <ColumnSelect
              id={'publishing-medium-select'}
              labelText={'Publishing Medium'}
              value={publishingMedium}
              options={[
                { value: PublishingMedium.Print, label: 'Print' },
                { value: PublishingMedium.Online, label: 'Online' },
                { value: PublishingMedium.EEdition, label: 'E-Edition' }
              ]}
              onChange={setPublishingMedium}
            />
          </div>
        )}
      </div>
      <TabGroup
        onClickTab={setActiveSettingsTab}
        activeTab={activeSettingsTab}
        tabs={permissionedTabs}
        id={'product-settings-tab-group'}
      />
      {userPermissionsMap[activeSettingsTab.id]
        ? componentMap[activeSettingsTab.id]
        : null}
    </SettingsPage>
  );
}
