import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { Tooltip } from 'lib/components/Tooltip';
import { Obituary } from 'lib/types/obituary';
import FormSwitch from 'routes/settings/publisher/FormSwitch';

type ShowLogoSwitchProps<T extends Obituary> = {
  adData: Partial<T>;
  onAdChange: (ad: Partial<T>) => void;
  advertiserLogo: string;
};

function ShowLogoSwitch<T extends Obituary>({
  adData,
  onAdChange,
  advertiserLogo
}: ShowLogoSwitchProps<T>) {
  const handleSwitchChange = async () => {
    const updatedAdData = {
      ...adData,
      funeralHomeLogo: adData.funeralHomeLogo ? '' : advertiserLogo
    };
    onAdChange(updatedAdData);
  };

  return (
    <div
      className={classNames(
        'flex items-center justify-between h-12 p-4 mt-4 rounded-md bg-white w-full lg:w-1/2 mx-auto',
        {
          'border border-primary-500': !!adData.funeralHomeLogo,
          'border border-column-gray-200': !adData.funeralHomeLogo
        }
      )}
    >
      <div
        className={classNames('text-sm font-medium flex items-center', {
          'text-primary-500': !!adData.funeralHomeLogo,
          'text-column-gray-300': !adData.funeralHomeLogo
        })}
      >
        <Tooltip helpText="If enabled, the funeral home logo will appear in the obituary. Click view proof to see logo placement.">
          <QuestionMarkCircleIcon className="h-5 w-5" />
        </Tooltip>
        <p className="pl-2">Show funeral home logo</p>
      </div>
      <FormSwitch
        checked={!!adData.funeralHomeLogo}
        onChange={handleSwitchChange}
      />
    </div>
  );
}

export default ShowLogoSwitch;
