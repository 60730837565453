import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getOrThrow } from 'lib/utils/refs';
import { EOrganization, ESnapshotExists } from 'lib/types';
import LoadingState from 'components/LoadingState';
import { NewspapersContext } from './NewspapersContext';
import { NewspaperOrdersFormData } from '../PlacementFlowStepSelector';

type NewspapersContextProviderProps = {
  children: React.ReactNode;
  newspaperOrdersFormData: NewspaperOrdersFormData;
};

const emptyPublishersArray: ESnapshotExists<EOrganization>[] = [];

export const getDependenciesForNewspaperContext = (
  newspaperOrdersFormData: NewspaperOrdersFormData
) => [
  [
    ...new Set(newspaperOrdersFormData.map(o => o.newspaper?.id)).entries()
  ].join(',')
];

export function NewspapersContextProvider({
  children,
  newspaperOrdersFormData
}: NewspapersContextProviderProps) {
  const { value, isLoading } = useAsyncEffect<ESnapshotExists<EOrganization>[]>(
    {
      fetchData: () =>
        Promise.all(newspaperOrdersFormData.map(o => getOrThrow(o.newspaper))),
      dependencies: [newspaperOrdersFormData?.length]
    }
  );

  const publishers = value ?? emptyPublishersArray;

  if (isLoading || value === null) {
    return <LoadingState />;
  }

  return (
    <NewspapersContext.Provider value={publishers}>
      {children}
    </NewspapersContext.Provider>
  );
}
