import { apiPost } from 'api/typed';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { Modal } from 'lib/components/Modal';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { cdnIfy } from 'lib/helpers';
import { ColumnService } from 'lib/services/directory';
import { logAndCaptureException } from 'utils';

export function BulkPaymentReceiptView({
  ledgerItemId,
  onClose
}: {
  ledgerItemId: string;
  onClose: () => void;
}) {
  const { value: receiptUrl, isLoading, isError } = useAsyncEffect({
    fetchData: async () => {
      if (!ledgerItemId) {
        return null;
      }

      const {
        response: storagePath,
        error: errorMessage
      } = await apiPost('ledger/receipt', { ledgerItemId });

      if (errorMessage !== null) {
        const error = new Error(errorMessage);
        logAndCaptureException(
          ColumnService.PAYMENTS,
          error,
          'Failed to get receipt after bulk payment',
          { ledgerItemId }
        );
        throw error;
      }

      return cdnIfy(storagePath);
    },
    dependencies: []
  });

  return (
    <Modal
      id="bulk-pay-invoices-modal"
      onClose={onClose}
      title={ledgerItemId ? 'Payment Receipt' : 'Pay Invoices'}
      primaryAction={{
        onClick: onClose,
        buttonText: 'Close',
        type: 'button'
      }}
      secondaryActions={[
        {
          onClick: () => receiptUrl && window.open(receiptUrl, '_blank'),
          buttonText: 'Download Receipt',
          type: 'button',
          loading: !receiptUrl
        }
      ]}
      size="3xl"
    >
      <div className="h-80">
        {isLoading && <LoadingSpinner />}
        {isError && <div>Error loading receipt</div>}
        {receiptUrl && (
          <embed
            className="w-full h-full"
            src={`${receiptUrl}#toolbar=0&navpanes=0&scrollbar=0`}
            type="application/pdf"
          />
        )}
      </div>
    </Modal>
  );
}
