import { useContext } from 'react';
import { NewspaperOrder } from 'lib/types/newspaperOrder';
import { NewspapersContext } from 'routes/ads/place/contexts/NewspapersContext';
import { Alert } from 'lib/components/Alert';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import classNames from 'classnames';
import NewspaperOrgImage from '../../../../components/PublisherImage';

type NewspaperOrderInfoRowProps = {
  filingTypeName: string;
  newspaperOrder: Partial<NewspaperOrder>;
  inPopover?: boolean;
};

function NewspaperOrderInfoRow({
  filingTypeName,
  newspaperOrder,
  inPopover
}: NewspaperOrderInfoRowProps) {
  const newspapers = useContext(NewspapersContext);

  const newspaper = newspapers.find(o => o.id === newspaperOrder.newspaper?.id);

  if (!newspaper) {
    const error = new Error(
      'Newspaper order contains newspaper not in context'
    );

    logAndCaptureException(ColumnService.OBITS, error, error.message, {
      newspaperId: `${newspaperOrder.newspaper?.id}`
    });

    return (
      <Alert id="newspaper-context-error-alert" description={error.message} />
    );
  }

  return (
    <div className="flex flex-row items-center gap-3">
      <NewspaperOrgImage
        newspaper={newspaper}
        className="max-w-12 max-h-12 rounded-sm object-cover"
      />
      <span
        className={classNames('text-sm', {
          'w-80': inPopover
        })}
      >
        {filingTypeName} in {newspaperOrder.publishingMedium} for{' '}
        {newspaper.data().name}
      </span>
    </div>
  );
}

export default NewspaperOrderInfoRow;
