import { ColumnSelect } from 'lib/components/ColumnSelect';
import { Alert } from 'lib/components/Alert';
import { ExclamationCircle } from 'icons';
import {
  Discount,
  EOrganization,
  ERate,
  ESnapshot,
  ESnapshotExists
} from 'lib/types';
import { useBulkPaymentV2EnabledOnOrganization } from 'components/hooks/useBulkPaymentV2EnabledOnOrganization';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { BillingTermType, enumToSelectInput } from 'lib/enums';
import { supportsDisplay } from 'lib/notice/rates';
import {
  REQUIRE_UPFRONT_PAYMENT_TYPES,
  REQUIRE_UPFRONT_PAYMENT_OPTIONS
} from './CreateOrEditCustomerModalSettings';
import { CustomerObjDataFields } from './CreateOrEditModalDetails';
import { DiscountSettings, FLAT_DISCOUNT_CONFIG } from './DiscountSettings';

export type EditCustomerOrganizationModalSettingsProps = {
  value: CustomerObjDataFields;
  onChange: (value: CustomerObjDataFields) => unknown;
  activeOrganization: ESnapshot<EOrganization>;
  rates?: ESnapshotExists<ERate>[];
};

export function EditCustomerOrganizationModalSettings({
  value,
  onChange,
  activeOrganization,
  rates
}: EditCustomerOrganizationModalSettingsProps) {
  const activeOrganizationHasBulkPaymentEnabled = useBulkPaymentV2EnabledOnOrganization(
    activeOrganization
  );

  const linerRateIsArchived = !!value.linerRateSnap?.data()?.archived;
  const displayRateIsArchived = !!value.displayRateSnap?.data()?.archived;

  const linerRateOptions = rates
    ?.filter(rate => {
      if (value.linerRateSnap?.id === rate.id) return true;
      return !rate.data().archived;
    })
    .map(rate => ({
      value: rate.id,
      label: rate.data()?.description
    }));

  const displayRateOptions = rates
    ?.filter(rate => {
      if (value.displayRateSnap?.id === rate.id) return true;
      const rateSupportsDisplay = supportsDisplay(rate.data());
      const isOrganizationDefaultDisplayRate =
        rate.id === activeOrganization.data()?.defaultDisplayRate?.id;
      return (
        isOrganizationDefaultDisplayRate ||
        (rateSupportsDisplay && !rate.data().archived)
      );
    })
    .map(rate => ({
      value: rate.id,
      label: rate.data()?.description
    }));

  const heading = "Set up this organization's preferences.";
  const description =
    'Configure how you want this organization to interact with your publication.';

  return (
    <CardGridLayout header={{ title: heading, description }}>
      <GridInput fullWidth>
        <Alert
          id="customization-warning"
          status="warning"
          icon={<ExclamationCircle className="w-5 h-5" />}
          description="Customer organization liner and display rates are set to your organization's default settings. You should only make changes if you want a different rate for this organization."
        />
      </GridInput>
      <GridInput>
        <ColumnSelect
          labelText="Liner rate"
          id="liner_rate"
          value={value.linerRateSnap?.id}
          options={linerRateOptions || []}
          onChange={newValue => {
            const selectedRate = rates?.find(r => r.id === newValue);
            if (selectedRate) {
              onChange({
                ...value,
                linerRateSnap: selectedRate
              });
            }
          }}
          errorText={
            linerRateIsArchived
              ? 'The selected rate is archived, please select another rate'
              : undefined
          }
        />
      </GridInput>
      <GridInput>
        <ColumnSelect
          labelText="Display rate"
          id="display_rate"
          value={value.displayRateSnap?.id}
          options={displayRateOptions || []}
          onChange={newValue => {
            const selectedRate = rates?.find(r => r.id === newValue);
            if (selectedRate) {
              onChange({
                ...value,
                displayRateSnap: selectedRate
              });
            }
          }}
          errorText={
            displayRateIsArchived
              ? 'The selected rate is archived, please select another rate'
              : undefined
          }
        />
      </GridInput>
      <GridInput fullWidth>
        <ColumnSelect
          labelText="Do you require upfront payment for this customer?"
          id="upfront_payment"
          placeholder="Require upfront payment?"
          value={value.upfrontPaymentSetting.id}
          disabled={!!value.bulkPaymentEnabled_v2}
          onChange={newValue => {
            if (newValue === REQUIRE_UPFRONT_PAYMENT_TYPES.DEFAULT.id) {
              onChange({
                ...value,
                upfrontPaymentSetting: REQUIRE_UPFRONT_PAYMENT_TYPES.DEFAULT
              });
            } else if (
              newValue === REQUIRE_UPFRONT_PAYMENT_TYPES.ALWAYS_REQUIRE.id
            ) {
              onChange({
                ...value,
                upfrontPaymentSetting:
                  REQUIRE_UPFRONT_PAYMENT_TYPES.ALWAYS_REQUIRE
              });
            } else {
              onChange({
                ...value,
                upfrontPaymentSetting:
                  REQUIRE_UPFRONT_PAYMENT_TYPES.NEVER_REQUIRE
              });
            }
          }}
          options={REQUIRE_UPFRONT_PAYMENT_OPTIONS.map(option => ({
            value: option.id,
            label: option.description
          }))}
        />
      </GridInput>
      {!!activeOrganization.data()?.allowInvoiceOutsideColumn && (
        <GridInput fullWidth>
          <LabeledSwitch
            label="Invoice outside Column?"
            description="By default, invoices created for this organization will be billed outside of Column."
            value={!!value.invoicedOutsideColumn}
            onChange={invoicedOutsideColumn => {
              onChange({
                ...value,
                invoicedOutsideColumn
              });
            }}
          />
        </GridInput>
      )}
      {activeOrganizationHasBulkPaymentEnabled && (
        <GridInput fullWidth>
          <LabeledSwitch
            label="Allow bulk invoicing?"
            description="Toggle this on if you want to create one monthly invoice for
                this organization."
            value={!!value.bulkPaymentEnabled_v2}
            onChange={enabled => {
              onChange({
                ...value,
                bulkPaymentEnabled_v2: enabled
              });
            }}
            disabled={!value.bulkPaymentEnabled_v2}
          />
        </GridInput>
      )}
      <GridInput fullWidth>
        <LabeledSwitch
          label="Allow affidavits before payment?"
          description=" Customers will be allowed to download affidavits before paying
              invoices."
          value={!!value.enableAffidavitsBeforePayment}
          onChange={enableAffidavitsBeforePayment => {
            onChange({
              ...value,
              enableAffidavitsBeforePayment
            });
          }}
        />
      </GridInput>
      <SwitchControlledCard
        labelProps={{
          label: 'Add a discount to this account?',
          description:
            'You can specify a flat discount or a percentage discount along with an expiration date.',
          value: !!value.discountConfig?.subtotal,
          onChange: enabled => {
            if (!enabled) {
              onChange({
                ...value,
                discountConfig: {}
              });
            } else {
              onChange({
                ...value,
                discountConfig: { subtotal: FLAT_DISCOUNT_CONFIG }
              });
            }
          }
        }}
        header=""
      >
        <DiscountSettings
          value={value.discountConfig?.subtotal ?? FLAT_DISCOUNT_CONFIG}
          onChange={(discount: Discount) => {
            onChange({
              ...value,
              discountConfig: { subtotal: discount }
            });
          }}
        />
      </SwitchControlledCard>
      {
        <GridInput fullWidth>
          <ColumnSelect
            id="billing-term-select"
            labelText="Default invoice due date for billable notices"
            value={value.billingTerm?.toString()}
            options={enumToSelectInput(BillingTermType)}
            onChange={stringValue =>
              onChange({ ...value, billingTerm: Number(stringValue) })
            }
          />
        </GridInput>
      }
    </CardGridLayout>
  );
}
