import { OrderModel } from 'lib/model/objects/orderModel';
import { NewspaperOrderModel } from 'lib/model/objects/newspaperOrderModel';
import { OrderDetailModel } from 'lib/model/objects/orderDetailModel';
import { useAppSelector } from 'redux/hooks';
import { selectAvailableOrganizations } from 'redux/auth';
import DetailLayout from 'layouts/DetailLayout';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { ObituaryDetail } from './ObituaryDetail';
import { ClassifiedDetail } from './ClassifiedDetail';
import OrderActivityLog from './OrderActivityLog';

type AdDetailProps = {
  order: OrderModel;
  orderDetail: OrderDetailModel;
  newspaperOrders: NewspaperOrderModel[];
  anonymousUserAuthInfo: { authEmail: string; accessCode: string } | undefined;
};

export default function AdDetailContents({
  order,
  orderDetail,
  newspaperOrders,
  anonymousUserAuthInfo
}: AdDetailProps) {
  const availablePublishers = useAppSelector(selectAvailableOrganizations);
  const filteredOrders = newspaperOrders.filter(newspaperOrder =>
    availablePublishers.find(
      availablePublisher =>
        availablePublisher.id === newspaperOrder.modelData.newspaper.id
    )
  );
  return (
    <DetailLayout
      drawer={
        getBooleanFlag(LaunchDarklyFlags.ENABLE_ORDER_DETAIL_ACTIVITY_LOG) ? (
          <OrderActivityLog order={order} />
        ) : null
      }
    >
      {order.isObituaryOrder && (
        <ObituaryDetail
          order={order}
          orderDetail={orderDetail}
          newspaperOrders={filteredOrders}
          anonymousUserAuthInfo={anonymousUserAuthInfo}
        />
      )}
      {order.isClassifiedOrder && (
        <ClassifiedDetail
          order={order}
          orderDetail={orderDetail}
          newspaperOrders={filteredOrders}
          anonymousUserAuthInfo={anonymousUserAuthInfo}
        />
      )}
    </DetailLayout>
  );
}
