import { apiPost } from 'api/typed';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { logAndCaptureException } from 'utils';
import { ERequestTypes } from 'lib/types';
import { OrderModel } from 'lib/model/objects/orderModel';
import { ColumnService } from 'lib/services/directory';
import { logInfo } from 'utils/logger';

export const sendCancelOrderRequest = async (
  cancellationReason: string,
  order: OrderModel,
  anonymousUserAuthInfo: { authEmail: string; accessCode: string } | undefined
): Promise<ResponseOrError<void>> => {
  const { product } = order.modelData;
  try {
    const reqBody: ERequestTypes['orders/cancel'] = {
      orderId: order.id,
      cancellationReason,
      anonymousUserAuthInfo: anonymousUserAuthInfo ?? null
    };
    const logData = {
      orderId: order.id,
      cancellationReason,
      product
    };
    const result = await apiPost('orders/cancel', reqBody);

    if (!result.success) {
      logInfo('Failed to cancel order', {
        error: result.error,
        ...logData
      });
      throw result.error;
    }

    logInfo('Successfully canceled order', logData);

    return wrapSuccess(undefined);
  } catch (err) {
    logAndCaptureException(ColumnService.OBITS, err, 'Error cancelling order', {
      orderId: order.id
    });
    return wrapError(err as Error);
  }
};
