import { Tooltip } from '@material-ui/core';

import { AdRate, EOrganization, ERate, ESnapshotExists } from 'lib/types';
import { RateType } from 'lib/enums';
import { Badge } from 'lib/components/Badge';

import { isRateShared, centsToExtendedCurrency } from 'lib/utils/rates';
import CurrencyBadge from 'components/CurrencyBadge';
import {
  shouldShowThirdRunRate,
  isDefaultRate,
  isLegacyRate
} from './ratesTableSettingsUtils';
import { hidePricingDetailsInRatesTable } from '../ratesUtils';

type RateCellProps = {
  rate: AdRate | ERate;
};
function RateTableRowCell({ rate }: RateCellProps) {
  const { additionalRates, rate_0, rate_1, rate_2 } = rate;

  let cellContent = (
    <span className="rate-row-cell">
      <CurrencyBadge value={rate_0} />
      {(rate.runBased || isLegacyRate(rate)) && (
        <>
          <CurrencyBadge value={rate_1} />
          {shouldShowThirdRunRate(rate) && <CurrencyBadge value={rate_2} />}
        </>
      )}
      {additionalRates && (
        <span>
          <Badge status="info">+{Object.keys(additionalRates).length}</Badge>
        </span>
      )}
    </span>
  );

  if (hidePricingDetailsInRatesTable(rate)) {
    cellContent = <span className="rate-row-cell">N/A</span>;
  }

  if (additionalRates) {
    const tooltipTitle = (
      <p>
        <span className="text-xs font-bold pb-1">Run Rates</span>
        <br />
        <span className="text-xs pb-.5">
          1st run rate → ${centsToExtendedCurrency(rate_0)}
        </span>
        <br />
        <span className="text-xs pb-.5">
          2nd run rate → ${centsToExtendedCurrency(rate_1)}
        </span>
        <br />
        <span className="text-xs pb-.5">
          3rd run rate → ${centsToExtendedCurrency(rate_2)}
        </span>
        {Object.values(additionalRates).map((rateValue, idx) => (
          <span className="text-xs pb-.5" key={idx}>
            <br />
            {idx + 4}th run rate → ${centsToExtendedCurrency(rateValue)}
          </span>
        ))}
      </p>
    );
    cellContent = <Tooltip title={tooltipTitle}>{cellContent}</Tooltip>;
  }

  return <td>{cellContent}</td>;
}

type RatesTableRowProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  rate: ERate | AdRate;
  rateId: string;
};

const getDefaultDescription = (
  rateId: string,
  activeOrganization: ESnapshotExists<EOrganization>
) => {
  const isDefaultLinerRate =
    activeOrganization.data().defaultLinerRate?.id === rateId;
  const isDefaultDisplayRate =
    activeOrganization.data().defaultDisplayRate?.id === rateId;
  if (isDefaultLinerRate && isDefaultDisplayRate) {
    return 'Default Liner & Display Rate';
  }
  if (isDefaultLinerRate) {
    return 'Default Liner Rate';
  }
  return 'Default Display Rate';
};

export default function RatesTableRow({
  activeOrganization,
  rate,
  rateId
}: RatesTableRowProps) {
  return (
    <>
      <td className="text-column-gray-500">
        {rate.description}
        {isDefaultRate(activeOrganization, rateId) && (
          <Tooltip title={getDefaultDescription(rateId, activeOrganization)}>
            <span className="ml-2">
              <Badge status="success">Default</Badge>
            </span>
          </Tooltip>
        )}
        {isRateShared(rate) && (
          <Tooltip title="Shared rate">
            <span className="ml-2">
              <Badge status="info">Shared</Badge>
            </span>
          </Tooltip>
        )}
        {isLegacyRate(rate) && (
          <Tooltip
            title={
              <p className="flex flex-col">
                <span className="text-sm mb-1">Legacy rate</span>
                <span className="text-xs">
                  This rate uses a legacy structure and is no longer
                  recommended. To update your rates to Column's suggested setup,
                  reach out to your Customer Success Manager.
                </span>
              </p>
            }
          >
            <span className="ml-2">
              <Badge>Legacy Rate</Badge>
            </span>
          </Tooltip>
        )}
      </td>
      <td>{RateType.by_value(rate.rateType)?.label}</td>
      <RateTableRowCell rate={rate} />
    </>
  );
}
