import { TextField } from 'lib/components/TextField';
import moment from 'moment';
import { CustomerTypeString } from 'routes/ads/filters/helpers';
import { Obituary } from 'lib/types/obituary';
import MultiStepHeader from '../../components/MultiStepHeader';
import ThreeFieldDateSelect, {
  ThreeFieldDateValue
} from '../../components/ThreeFieldDateSelect';
import FuneralHomeInformationForm from './FuneralHomeInformationForm';

const DEFAULT_DATE = 'NaN-NaN-NaN';

const translateDateStringToThreeFieldDate = (
  dateString: string
): ThreeFieldDateValue => {
  const [year, month, day] = dateString.split('-').map(Number);
  return { year, month, day };
};

const translateThreeDateFieldToDateString = (date: ThreeFieldDateValue) => {
  const year = isNaN(date.year)
    ? 'NaN'
    : moment({ year: date.year }).format('YYYY');
  const month = isNaN(date.month)
    ? 'NaN'
    : // Months are 0-indexed so we need to compensate
      moment({ month: date.month - 1 }).format('MM');
  const day = isNaN(date.day) ? 'NaN' : moment({ day: date.day }).format('DD');

  return `${year}-${month}-${day}`;
};

type ProvideVerificationProps = {
  inputData: Partial<Obituary>;
  setInputData: (inputData: Partial<Obituary>) => void;
  chosenFlow: CustomerTypeString;
};

function ProvideVerification({
  chosenFlow,
  inputData,
  setInputData
}: ProvideVerificationProps) {
  const handleBirthDateChange = (date: ThreeFieldDateValue) => {
    const dateString = translateThreeDateFieldToDateString(date);
    setInputData({ ...inputData, deceasedBirthDate: dateString });
  };

  const handleDeathDateChange = (date: ThreeFieldDateValue) => {
    const dateString = translateThreeDateFieldToDateString(date);
    setInputData({ ...inputData, deceasedDeathDate: dateString });
  };

  const getValidationMessagesForDates = (): {
    birthDateMessage: string | null;
    deathDateMessage: string | null;
  } => {
    const birthDateMoment = moment(inputData.deceasedBirthDate, 'YYYY-MM-DD');
    const birthDateIsValid =
      inputData.deceasedBirthDate &&
      birthDateMoment.isValid() &&
      !inputData.deceasedBirthDate.includes('NaN');
    const birthDateIsInFuture =
      birthDateIsValid && birthDateMoment.isAfter(moment(), 'day');

    const deathDateMoment = moment(inputData.deceasedDeathDate, 'YYYY-MM-DD');
    const deathDateIsValid =
      inputData.deceasedDeathDate &&
      deathDateMoment.isValid() &&
      !inputData.deceasedDeathDate.includes('NaN');
    const deathDateIsInFuture =
      deathDateIsValid && deathDateMoment.isAfter(moment(), 'day');

    const birthDateIsAfterDeathDate =
      birthDateIsValid &&
      deathDateIsValid &&
      birthDateMoment.isAfter(deathDateMoment, 'day');

    /**
     * If either date is not valid, the native validation messages will populate, so we don't need to return anything here
     */
    const birthDateMessage = !birthDateIsValid
      ? null
      : birthDateIsInFuture
      ? 'Date of birth cannot be in the future'
      : birthDateIsAfterDeathDate
      ? 'Date of birth cannot be after date of death'
      : null;
    const deathDateMessage = !deathDateIsValid
      ? null
      : deathDateIsInFuture
      ? 'Date of death cannot be in the future'
      : birthDateIsAfterDeathDate
      ? 'Date of death cannot be before date of birth'
      : null;
    return { birthDateMessage, deathDateMessage };
  };

  const deceasedBirthDate = inputData?.deceasedBirthDate ?? DEFAULT_DATE;
  const translatedBirthDate = translateDateStringToThreeFieldDate(
    deceasedBirthDate
  );

  const deceasedDeathDate = inputData?.deceasedDeathDate ?? DEFAULT_DATE;
  const translatedDeathDate = translateDateStringToThreeFieldDate(
    deceasedDeathDate
  );

  const title = 'Deceased Details';
  const description =
    chosenFlow === CustomerTypeString.FUNERAL_HOME
      ? undefined
      : 'To publish your listing, we need to verify the date of death with the funeral home, crematory services, or bequest program.';
  return (
    <>
      <MultiStepHeader title={title} description={description} />
      <div className="mb-8">
        <TextField
          id="deceased-name"
          value={inputData?.deceasedName ?? ''}
          type="text"
          onChange={value =>
            setInputData({ ...inputData, deceasedName: value })
          }
          labelText="What is the name of the deceased?"
          required
        />
      </div>
      <div className="mb-8">
        <fieldset id="deceased-death-date">
          <legend>What is the date of death of the deceased?</legend>
          <div className="grid md:grid-cols-3 gap-3">
            <ThreeFieldDateSelect
              id="deceased-death-date-select"
              onChange={handleDeathDateChange}
              value={translatedDeathDate}
              required
              checkAggregateDateValidation={() =>
                getValidationMessagesForDates().deathDateMessage
              }
              reverseSortYears={false}
            />
          </div>
        </fieldset>
      </div>
      <div className="mb-8">
        <fieldset id="deceased-birth-date">
          <legend>What is the date of birth of the deceased?</legend>
          <div className="grid md:grid-cols-3 gap-3">
            <ThreeFieldDateSelect
              id="deceased-birth-date-select"
              onChange={handleBirthDateChange}
              value={translatedBirthDate}
              required
              checkAggregateDateValidation={() =>
                getValidationMessagesForDates().birthDateMessage
              }
              reverseSortYears
            />
          </div>
        </fieldset>
      </div>
      {chosenFlow !== CustomerTypeString.FUNERAL_HOME && (
        <FuneralHomeInformationForm
          inputData={inputData}
          setInputData={setInputData}
        />
      )}
    </>
  );
}

export default ProvideVerification;
