import React, { useState, useEffect } from 'react';
import { useAppDispatch } from 'redux/hooks';
import deepEqual from 'deep-equal';

// note that we need to do this import from the enum directly to avoid overriding
// the child property
import { NotificationType, OccupationType, Product } from 'lib/enums';
import { affidavitsAreManagedByColumn } from 'lib/affidavits';
import { ColumnButton } from 'lib/components/ColumnButton';
import {
  EUserNotificationType,
  ESnapshotExists,
  EOrganization,
  exists,
  EUser
} from 'lib/types';

import { getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { getFirebaseContext } from 'utils/firebase';
import { createNotificationsObject } from 'lib/utils/users';
import SettingsHeader from '../SettingsHeader';
import FormSwitch from '../publisher/FormSwitch';
import { updateUserNotificationsSettings } from '../settingsActions';
import SettingsPage from '../SettingsPage';

/**
 * Removes irrelevant notifications for publishers in the UI. For example, don't show publishers
 * that aren't using automated affidavits the automated affidavit notification
 * @param notifications
 * @param activeOrg
 * @param childOrgs
 */
const filterNotifications = (
  notifications: EUser['notifications'],
  activeOrg: ESnapshotExists<EOrganization> | null,
  user: ESnapshotExists<EUser>
) => {
  const activeOrgModel = activeOrg
    ? getModelFromSnapshot(OrganizationModel, getFirebaseContext(), activeOrg)
    : undefined;
  const occupationKey = OccupationType.by_value(user.data().occupation)?.key;
  const defaultNotifications = createNotificationsObject(
    user.data().occupation
  );

  const filteredNotifications = { ...defaultNotifications, ...notifications };
  Object.keys(filteredNotifications).forEach(notif_key => {
    const relevantNotification = NotificationType.by_key(notif_key);
    if (!relevantNotification) {
      delete filteredNotifications[notif_key];
      return;
    }

    if (!relevantNotification.isConfigurable) {
      delete filteredNotifications[notif_key];
      return;
    }

    if (
      occupationKey &&
      !relevantNotification.recipient_occupation_type_keys.includes(
        occupationKey
      )
    ) {
      delete filteredNotifications[notif_key];
    }
  });
  delete filteredNotifications.survey_email;

  // delete affidavit notification if affidavits are not managed by column
  if (activeOrg && !affidavitsAreManagedByColumn(activeOrg)) {
    delete filteredNotifications.automated_affidavit_e_edition_upload_success;
  }

  // delete obituary notification if obituaries are not enabled
  if (!activeOrgModel || !activeOrgModel.hasAdTypeActive(Product.Obituary)) {
    delete filteredNotifications.new_obituary_submitted;
  }

  // delete classified notification if classifieds are not enabled
  if (!activeOrgModel || !activeOrgModel.hasAdTypeActive(Product.Classified)) {
    delete filteredNotifications.new_classified_submitted;
  }

  return filteredNotifications;
};

type NotificationSettingsRowNotification = {
  email: boolean;
  inApp: boolean;
  children?: string[];
};

type NotificationSettingsRowProps = {
  notification_key: string;
  notification: NotificationSettingsRowNotification;
  setNotification: (updates: NotificationSettingsRowNotification) => void;
};

function NotificationSettingsRow({
  notification_key,
  notification,
  setNotification
}: NotificationSettingsRowProps) {
  const matchingEnum =
    notification_key === 'organization_level'
      ? {
          key: 'organization_level',
          label: 'Organization Notices',
          description:
            'Receive notifications for all notices in your organisation, based on notification preferences below'
        }
      : NotificationType.by_key(notification_key);

  if (!matchingEnum) return null;
  const { label, key, description } = matchingEnum;

  return (
    <tr>
      <td className="pl-6 pr-2 py-4 whitespace-no-wrap text-sm leading-5">
        <div className="text-base text-column-gray-400 font-medium">
          {label}
        </div>
        <div
          className={`text-base text-column-gray-400 font-normal 
            ${key === 'invoice_notifications' && 'mb-6'}
          `}
        >
          {description}
        </div>
      </td>
      <td className="pl-6 pr-2 py-4 whitespace-no-wrap text-sm leading-5">
        <FormSwitch
          id={`${notification_key}_email`}
          checked={notification.email}
          onChange={() => {
            setNotification({
              ...notification,
              email: !notification.email
            });
          }}
        />
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
        <div className="flex justify-end">
          <FormSwitch
            checked={notification.inApp}
            id={`${notification_key}_inApp`}
            onChange={() => {
              setNotification({
                ...notification,
                inApp: !notification.inApp
              });
            }}
          />
        </div>
      </td>
    </tr>
  );
}

type NotificationSettingsProps = {
  user: ESnapshotExists<EUser>;
  activeOrganization: ESnapshotExists<EOrganization> | null;
  isPublisher: boolean;
};

export default function NotificationSettings({
  activeOrganization,
  user
}: NotificationSettingsProps) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState<
    Record<string, EUserNotificationType>
  >();
  const [
    organizationLevelNotifications,
    setOrganizationLevelNotifications
  ] = useState<{ email: boolean; inApp: boolean }>();

  useEffect(() => {
    if (!exists(user)) return;

    if (!deepEqual(user?.data().notifications, notifications)) {
      setNotifications(user.data().notifications);
    }
    if (
      user.data().occupation !== OccupationType.publishing.value &&
      user.data().organization
    )
      setOrganizationLevelNotifications(
        user.data().receiveOrganizationNotifications
      );
  }, [user]);

  const updateNotifications = async () => {
    setLoading(true);
    await dispatch(
      updateUserNotificationsSettings({
        user,
        newNotifications: notifications,
        organizationLevelNotifications
      })
    );
    setLoading(false);
  };

  if (!notifications) return null;

  return (
    <SettingsPage>
      <SettingsHeader
        header="Notification Settings"
        description="Control how often you hear from us."
      >
        <ColumnButton
          id="submit_notifications_settings"
          loading={loading}
          buttonText="Save Changes"
          onClick={() => updateNotifications()}
          primary
          type="button"
        />
      </SettingsHeader>
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th className="bg-column-gray-25 text-left text-xs leading-4 font-medium text-column-gray-300 uppercase tracking-wider">
              {' '}
            </th>
            <th className="pl-2 pr-6 py-3 bg-column-gray-25 text-left text-xs leading-4 font-medium text-column-gray-300 uppercase tracking-wider">
              email
            </th>
            <th className="px-6 py-3 bg-column-gray-25 text-left text-xs leading-4 font-medium text-column-gray-300 uppercase tracking-wider">
              <div className="flex justify-end">in app</div>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 rounded-b-lg">
          {organizationLevelNotifications && (
            <NotificationSettingsRow
              notification_key={'organization_level'}
              notification={{
                ...organizationLevelNotifications
              }}
              setNotification={newNotification => {
                setOrganizationLevelNotifications({
                  inApp: !!newNotification.inApp,
                  email: !!newNotification.email
                });
              }}
            />
          )}
          {Object.keys(
            filterNotifications(notifications, activeOrganization, user)
          )
            .filter(notification => {
              return NotificationType.by_key(notification);
            })
            .sort((a, b) => {
              const priorityA = NotificationType.by_key(a)?.priority ?? 0;
              const priorityB = NotificationType.by_key(b)?.priority ?? 0;
              return priorityB - priorityA;
            })
            // eslint-disable-next-line array-callback-return
            .map(notification_key => {
              if (notification_key !== 'new_bulk_invoice')
                return (
                  <NotificationSettingsRow
                    notification_key={notification_key}
                    notification={
                      notifications[notification_key] ?? {
                        ...createNotificationsObject(user.data().occupation)[
                          notification_key
                        ],
                        /**
                         * If the notification is not set on the user object, then we
                         * render it in the settings page as turned off. If the user toggles
                         * the notification on, it will be added to their user object.
                         */
                        email: false,
                        inApp: false
                      }
                    }
                    setNotification={newNotification => {
                      const newNotifications: Record<
                        string,
                        EUserNotificationType
                      > = {
                        ...notifications,
                        [notification_key]: newNotification
                      };

                      const notificationEnum = NotificationType.by_key(
                        notification_key
                      );
                      if (notificationEnum) {
                        for (const { key } of notificationEnum.children()) {
                          newNotifications[key] = {
                            ...newNotifications[key],
                            inApp: newNotification.inApp,
                            email: newNotification.email
                          };
                        }
                      }
                      setNotifications(newNotifications);
                    }}
                    key={notification_key}
                  />
                );
            })}
        </tbody>
      </table>
    </SettingsPage>
  );
}
