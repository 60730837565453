import React from 'react';

export default function RegisterUserHeader() {
  return (
    <div>
      <p className="mt-10 text-column-gray-500 text-center text-2xl leading-8 font-semibold">
        Create your account
      </p>
      <p className="mt-4 text-center text-sm font-medium leading-5 text-column-gray-400">
        Let’s future-proof public notice together.
      </p>
    </div>
  );
}
