import React from 'react';

export default function OccupationsHeader() {
  return (
    <div>
      <p className="mt-8 text-center text-2xl leading-8 text-column-gray-500 font-semibold">
        Please select your occupation.
      </p>
      <p className="mt-4 text-center text-sm font-medium text-column-gray-400">
        Choose a role that best describes your work in relation to placing a
        notice.
      </p>
    </div>
  );
}
